import styled, {keyframes} from 'styled-components';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 20%;
  }

  100% {
    background-position: 100% 20%;
  }
`;

export const MainDescriptionP = styled.p`
    width: 60vw;

    & span.gradient-text {
        background: linear-gradient(90deg, ${({theme}) => theme.colors.text}, #7fb6d5, ${({theme}) => theme.colors.text});
        background-size: 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${gradientAnimation} 3s linear infinite alternate;
    }
    
    & span.underline-text {
      text-decoration: underline;
    }

    @media screen and (max-width: 600px) { // mobile screen
        width: 100vw;
        font-size: 20px;
  }
`

export const NowrapH4 = styled.h4`
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`

export const H4 = styled.h4`
  font-size: ${({m_font}) => m_font && (m_font)};

 @media screen and (min-width: 600px) {
    font-size:  ${(props) => props.font || 32}px;
  }
`
export const H3 = styled.h3`
 @media screen and (min-width: 600px) {
    font-size:  ${(props) => props.font || 20}px;
  }
`

export const P = styled.p`
 @media screen and (min-width: 600px) {
    font-size:  ${(props) => props.font || 20}px;
  }
`