import React, {useState, useEffect} from "react";

//Utils
import { useData, useWebSocket, useLanguage } from "../../../Utils/UseContext.jsx";
import { Loading,} from "../../../Utils/Utils.jsx";
//API
//COmponents
import { GetPublicData } from "../../../Utils/ApiRequests.jsx";
import { PublicExerciseSection } from "./PublicExerciseSection.jsx";
import { WorkoutNav, SubWorkoutNav } from "../../../components/Nav.jsx";
//icon
//Style
import {WorkoutContainer} from '../StyledComponents.jsx'
//Translation
import WordTranslations from '../../Translations/WordTranslation.json'



const PublicWorkout = ({theme}) => {
    const {language} = useLanguage();
    const {publicData, setPublicData} = useData();

    const [activeData, setActiveData] = useState('all');
    

    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    const ws = useWebSocket();

    useEffect(() => {
      if (ws) {
        GetPublicData({setPublicData, ws});
      }
    }, [ws]);

    return (
        <WorkoutContainer>
          {/* <h2>{'Public workout'}</h2> */}
          <WorkoutNav lang={lang}/>
          <Loading data={publicData?.data} timeout={1000} theme={theme}>
            {/* <SubWorkoutNav setActiveData={setActiveData} activeData={activeData} lang={lang}/> */}
            <PublicExerciseSection data = {publicData?.data} theme={theme} activeData={activeData}/>
          </Loading>
        </WorkoutContainer>
    )
}

export default PublicWorkout;