import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { TimerButton } from './Buttons.jsx';
import { TimerContainer } from './style/Container.styled';
import { DateNow, SetToLocalStorage, getLast } from '../Utils/Utils.jsx';
import { InsertCompletedIntoDb } from '../Utils/ApiRequests.jsx';


export const CountDownTimer = (countTime) => {
  const timeNow = moment().format( 'mm:ss' )
  const ms = moment( timeNow, 'mm:ss').diff( moment( countTime, 'mm:ss' ));
  const duration = moment.duration( ms );
  // const hours = Math.floor( duration.asHours() );
  const seconds = Math.floor(duration.asSeconds())

  return seconds;
}

const Timer = ({setTimer, setStop, stop, name, shouldContinue, onContinueChange, reset, ConfirmationPopUp, onTimerReset, partId}) => {
  const [time, setTime] = useState(0);
  const [currentlyRun, setCurrentlyRun] = useState(null);
  const [pausedTime, setPausedTime] = useState(null)
  const [nameOfPerformace, setNameOfPerformance] = useState({name: "", partname: ""});

  useEffect(() => {
    // Lue aloitusaika paikallisesta tietokannasta
    const storedStartTime = localStorage.getItem('currentlyRun');
    if (storedStartTime) {
      const parsed = JSON.parse(storedStartTime);
      setCurrentlyRun(parsed);
      nameOfPerformace.name = parsed?.name.toString();
      nameOfPerformace.partname = parsed?.partname.toString();
      
    }
  }, []);

  useEffect(() => {
    if (shouldContinue) {
      handleTimer();
      onContinueChange(); // Kutsuu vanhemman komponentin funktiota resetoidakseen shouldContinue-tilan
      setPausedTime(null);
    }
  }, [shouldContinue, onContinueChange]);

  useEffect(() => {
    if (reset) {
      setTime(0); // Nollaa ajan
      onTimerReset(); // Ilmoittaa vanhemmalle komponentille, että aika on nollattu ja resetTimer-tila voidaan nollata
      handleClose();
    }
    if(stop === true){
      setStop(false);
        try {
          if(navigator.onLine){
            getPerformanceData();
          }else{
            console.info("you are offline mode, waiting for internet connection.")
          }
        } catch (error) {
          alert("Oops! is your server disconnected?! ", error)
        }
    }
  }, [reset, onTimerReset, stop]);

  useEffect(() => {
    let intervalId;
    if (currentlyRun && !pausedTime) {
      intervalId = setInterval(() => {
        const currentTime = Date.now();
        const elapsed = currentTime - currentlyRun.startTime;
        setTime(elapsed);
      }, 1000);
    }

    // Poista ajastin, kun komponentti puretaan (unmounted)
     return () => {
      clearInterval(intervalId);
     };
  }, [currentlyRun, pausedTime]);

  const getPerformanceData = async () => {
    const index = await getLast(Object.keys(localStorage), 'performance-');
    const data = localStorage.getItem("performance-" + index);
    const key = 'performance-' + index;

    await InsertCompletedIntoDb(key, data);
  }


  const startTimer = () => {
    setTimer(true);
    const startTime = Date.now();
    const obj = {date: DateNow(), name: name.name, partname: name.partname, startTime: startTime, id: partId}
    setCurrentlyRun(obj)
    nameOfPerformace.name = obj?.name.toString();
    nameOfPerformace.partname = obj?.partname.toString();
    SetToLocalStorage('currentlyRun', obj);

    if ("serviceWorker" in navigator && "periodicSync" in navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((registration) => {
          registration.periodicSync.register("check-timer", {
              minInterval: 60 * 1000 // 1 minuutti
          });
      });
  }
  }

  const handleTimePaused = (timerTime) => {
    setPausedTime(Date.now());
    ConfirmationPopUp()
  };

  const handleClose = () => {
    setPausedTime(null);
    localStorage.removeItem('currentlyRun');
    setCurrentlyRun(null);
    setNameOfPerformance({name: "", partname: ""})
  }
  

  const updateTimer = (startTime) => {
    const timeNow = moment().format( 'HH:mm:ss' )
    const ms = moment( timeNow, 'HH:mm:ss').diff( moment( startTime, 'HH:mm:ss' ));
    const duration = moment.duration( ms );
    const hours = Math.floor( duration.asHours() );
    const time = `${hours < 10 ? '0' : ''}${ hours }:${ moment.utc( ms ).format( 'mm:ss' ) }`;
    setTime(time);
  };

  const handleTimer = () => {
  
    if (!currentlyRun) {
      // Kun ajastin käynnistetään uudelleen, päivitä startTime, jotta aika jatkuu siitä mihin jäi.
      startTimer()
    }else{
      handleTimePaused(time)
    }
   }
   
  const formattedTime = () => {
    // return `${time}`
    const duration = moment.duration(time);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
      <TimerContainer>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <p>{nameOfPerformace.name} - {nameOfPerformace.partname}</p>
          <p>{formattedTime()}</p>
        </div>
        <TimerButton isTimer = {currentlyRun} handleTimer={handleTimer}/>
      </TimerContainer>
  );
};

export default Timer;
