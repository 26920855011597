import React, { useEffect, useState } from "react";
// API
import { GetCompletedWorkout } from "../../Utils/ApiRequests";
// style
import { Content20Round,Container} from "../../components/style/Container.styled";
import { Column, Row } from "../../components/style/Row.styled";
import { WorkoutContainer } from "../Workout/StyledComponents";

//Button
//components
import { TrainingGraph, WeightGraph } from "./Charts";
//Utils
import { FilterEventsByDateRange, GetWeekRange, GetPreviousWeekRange} from "../../Utils/Utils";
import { useLanguage } from "../../Utils/UseContext";


const countActivities = (list) => {
    const counts = {};
 
    list.forEach(item => {

      if (!counts[item.part_of_workout_id]) {
        counts[item.part_of_workout_id] = {};
      }

      if (counts[item.part_of_workout_id][item.part_of_workout_name]) {
        counts[item.part_of_workout_id][item.part_of_workout_name]++;
      } else {
        counts[item.part_of_workout_id][item.part_of_workout_name] = 1;
      }
    });

    return counts;
};

const countActivitiesDuration = (totalDurationInMillseconds) => {
  
    // Lasketaan tapahtumien kokonaiskesto sekunteina
    //const totalDurationInMillseconds = list.reduce((total, item) => total + item.duration, 0);

    // Muunnetaan sekunnit tunneiksi ja minuuteiksi
    const hours = Math.floor(totalDurationInMillseconds / (3600 * 1000));
    const minutes = Math.floor((totalDurationInMillseconds % (3600 * 1000)) / (60 * 1000));
    const seconds = Math.floor((totalDurationInMillseconds % (60 * 1000)) / 1000);
  
    return { hours, minutes, seconds };
};

const WeeklyActivieties = ({lastWeek, thisWeek}) => {
    const difference = thisWeek - lastWeek;

    const LessThisWeek = `You have done ${thisWeek} activities this week which is ${Math.abs(difference)} less than last week`;
    const MoreThisWeek = `You have done ${thisWeek} activities this week which is ${difference} more than last week`;

    return difference > 0 ? MoreThisWeek : LessThisWeek
}

const Activities = ({completed}) => {
    const counts = countActivities(completed)

    return(
        <div style={{display: 'flex', flexDirection: 'row', maxWidth: '70%', overflow: 'auto'}}>
        {Object.keys(counts).map((id, index) => {
          const name = Object.keys(counts[id])[0];
          const count = counts[id][name];
          
          return(
            <div key={index} style={{margin: '10px 5px 10px 0px', borderRight: '1px solid white', padding: '0px 5px 0px 0px' }}>
              <div>{name}</div>
              <div>{count}</div>
            </div>
          )
        })}
      </div>
    )
}

const WorkoutList = ({completedWorkouts}) => {

  return(
    <div>
      <p style={{paddingLeft: 13}}>Workouts: {completedWorkouts.length}</p>
    <div style={{maxHeight: '300px', overflow: 'auto', padding: '10px 13px'}}>
      {completedWorkouts
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((workout, index) => {
          const date = new Date(workout.date);

          return (
              <Row key={index} align='start' style={{borderBottom: '1px solid white', padding: '5px 0px'}}>
                <Row width={'170px'} padding={'0px'}>
                  <p style={{fontSize: 12}}>{date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}</p>
                </Row>
                <Row width={'170px'} padding={'0px'}>
                  <p>{workout.part_of_workout_name}</p>
                </Row>
                <Column>
                  <Row width={'170px'} padding={'0px'}>
                      <p>Completed:</p>
                      <p>{workout.completion_rate}%</p>
                  </Row>
                  <Row width={'170px'} padding={'0px'}>
                      <p>Duration:</p>
                      <p>{Math.round(workout.duration / (60 * 1000))} min</p>
                  </Row>
                </Column>
              </Row>
          );
      })}
    </div>
    </div>
  )

}


const Analytics = ({theme}) => {
  const {language} = useLanguage();
  const [trainingData, setTrainingData] = useState([]);
  const [weekActivity, setWeekActivity] = useState([]);
  const [total, setTotal] = useState({activities: 0, time: 0});
  const [range, setRange] = useState({ startDate: GetWeekRange().startOfWeek, endDate: GetWeekRange().endOfWeek }); // Oletusväli (nykyinen päivä)
  
  
  useEffect(() => {
    getTrainingData(range.startDate, range.endDate);
    WeekActivity(GetWeekRange().startOfWeek, GetWeekRange().endOfWeek);
  },[range])

const getTrainingData = async (start, end) => {
  // get training data selected date range
    const data = await GetCompletedWorkout(start.toISOString(), end.toISOString());

    setTrainingData(data.completed.completed);
    setTotal({activities: data.completed.count[0]?.completedcount, time: data.completed.count[0]?.durationsum});
}
const WeekActivity = async (start, end) => {
  // get week activity
  const activities = await GetCompletedWorkout(start.toISOString(), end.toISOString())
  setWeekActivity(activities);
  return activities;
}

const currentWeekEvents = FilterEventsByDateRange(trainingData, GetWeekRange());
const previousWeekEvents = FilterEventsByDateRange(trainingData, GetPreviousWeekRange());
const hours = countActivitiesDuration(total.time).hours;
const minutes = countActivitiesDuration(total.time).minutes;

    return(
        <div>
            <h3>
                Analytics
            </h3>
          {/* <ScrollAuto> */}
            <Content20Round>
              <h4 style={{textAlign:'center'}}>Total</h4>
              <Row>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p>Activities</p>
                    <p>{total.activities}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p>Time</p>
                    <p>{hours}h {minutes}min</p>
                </div>
              </Row>
            </Content20Round>
            <Content20Round>
                <h4>Weekly activities</h4>
                <WeeklyActivieties lastWeek={previousWeekEvents.length} thisWeek={currentWeekEvents.length}/>
                <Row padding={'0px'}>
                    <Activities completed={currentWeekEvents}/>
                </Row>
            </Content20Round>
            <Content20Round>
                <TrainingGraph data={trainingData} range={range} setRange={setRange} theme={theme}/>
            </Content20Round>
            <Content20Round>
                <WorkoutList completedWorkouts={trainingData}/>
                {/* <Row padding={'0px'}>
                    <WeightGraph data={trainingData}/>
                </Row> */}
            </Content20Round>
            {/* </ScrollAuto> */}
        </div>
    )
}

export default Analytics;