import styled from 'styled-components';


export const ExerciseDetailsInput = styled.input`
padding:0; 
font-size: 17; 
text-align: center;
color: ${({theme}) => theme.colors.text}; 
text-decoration-line: none;
background: none;
width: ${(props) => props.width}px;
max-width: 3em;
height: 2em;
border: none;
`;

export const StyledSearchInput = styled.input`
padding:0; 
font-size: 17; 
text-align: center;
color: ${({theme}) => theme.colors.text}; 
text-decoration-line: none;
background: none;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
height: 25px;
`

export const StyledWorkoutInput = styled.input`
    padding:4px; 
    font-size: 17; 
    text-align: start;
    color: ${({theme}) => theme.colors.text}; 
    text-decoration-line: none;
    background: none;
    border-radius: 5px;
    height: ${({height}) => height || '32px'};
    width: ${({width}) => width && (width)}
`;

export const StyledTextArea = styled.textarea`
    padding:4px; 
    font-size: 17; 
    color: ${({theme}) => theme.colors.text}; 
    text-decoration-line: none;
    border-radius: 5px;
    // border-color: ${({theme}) => theme.colors.body};
    height:12vh;
    width: ${({width}) => width && (width)};
    max-height: 30vh;
    max-width: 100%;
    background: ${({theme}) => theme.colors.body};
`

export const LABEL = styled.label`
    color:${({theme}) => theme.colors.text};
    margin-right: 8px;

`;

export const StyledCustomInput = styled.input`
    margin: ${({margin}) => margin ? margin : '0 0 10px 0'};
    padding:0;
    border-radius: 5px;
    background: transparent;
    color: ${({theme, disabled}) => disabled ? theme.colors.disabledText : theme.colors.text}; 
    text-decoration-line: ${({text_decoration}) => text_decoration || 'none'};
    width: ${({width}) => width ? width+'px' : '100%'};
    height: 32px;
    cursor: text;
    border: ${({border}) => border && (border)};
    text-align: ${({text_align}) => text_align && (text_align)};

    @media screen and (min-width: 481px) and (min-height: 500px) {

    }
`

export const StyledBorderlesInput = styled.input.attrs({
    type: "number", // Input ottaa vain numeroita
  })`
    border: none;
    outline: none;
    text-align: center;
    max-width: 52px;
    max-height: 40px;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};
    font-size: ${({fontSize}) => fontSize|| '20px'};
    font-weight: 700;
    color: ${({theme}) => theme.colors.text};
    background-color: ${({theme}) => theme.colors.body};
    padding: 0;
    appearance: textfield; /* Piilottaa nuolet */

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: none; /* Piilottaa nuolet Webkit-selaimissa */
      margin: 0;
    }

    &:focus {
      background-color: ${({theme}) => theme.colors.body};;
    }
`
