import styled from "styled-components";

export const Row = styled.div`
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height && (height)};
  max-height: ${({maxheight}) => maxheight && (maxheight)};
  max-width: ${({maxwidth}) => maxwidth && (maxwidth)};
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify || 'space-between'};
  align-items: ${({align}) => align || 'center'};
  margin: ${({margin}) => margin && (margin)};
  padding: ${(props) => props.padding || '0 1em 0 1em'};
  overflow-y: ${({scroll}) => scroll && (scroll)};
  border-bottom: ${({borderBottom}) => borderBottom && (borderBottom)};


  @media screen and (max-width: 450px) { // mobile screen
    justify-content: ${({justify_mobile}) => justify_mobile || 'space-between'};
    margin: ${({margin_mobile}) => margin_mobile || '0px'};
    min-width: ${({minWidth_mobile}) => minWidth_mobile || '80px'};


    & > h3 {
      color:${({color}) => color && (color)};
    }

    & > p {
      font-size: ${({fontSize}) => fontSize || '12px'};
    }
  }

`;

export const Column = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: ${({justify}) => justify && (justify)};
  align-items: ${({align}) => align && (align)};
  height: ${({height}) => height || '100%'};
  width: ${({width}) => width && (width)};
  margin: ${({margin}) => margin && (margin)};
  padding: ${({padding}) => padding && (padding)};
  background:${({bg}) => bg && (bg)};
  border-bottom: ${({borderBottom}) => borderBottom && (borderBottom)};
  border-radius:${({borderRadius}) => borderRadius && (borderRadius)};

    @media screen and (max-width: 600px) { // mobile
      width: ${({mobile_width}) => mobile_width};
      justify-content: ${({mobile_Justify}) => mobile_Justify};
    }
`

export const RowColumn = styled.div`
    display:flex;
    justify-content: ${(props) => props.justify || 'space-between'};
    align-items: ${({align}) => align || 'center'};
    max-height: ${({maxheight}) => maxheight && (maxheight)};
    max-width: ${({maxwidth}) => maxwidth && (maxwidth)};
    padding: ${({padding}) => padding && (padding)};
    overflow:auto;

    @media screen and (min-width: 600px) { // desktop
      flex-direction: row;
      width: ${({width}) => width || '100%'};
    }

    @media screen and (max-width: 600px) { // mobile
      flex-direction: column;
      height: ${({height}) => height || '100%'};
      width: ${({width}) => width && (width)};
    }
`