
const ConvertSvgToPng = (svgElement, setImage) => {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
 // Serialisoidaan SVG
 let svgData = new XMLSerializer().serializeToString(svgElement);
 // Vaihdetaan värit teeman perusteella
 svgData = svgData.replace(/fill="[^"]*"/g, isDarkMode ? 'fill="#ccc"' : 'fill="#000000"'); // Tekee tummasta vaalean ja toisinpäin
 svgData = svgData.replace(/stroke="[^"]*"/g, isDarkMode ? 'stroke="#ccc"' : 'stroke="#000000"');
 svgData = svgData.replace(/class="muscle active"/g, 'fill="#ff4d4d"'); // Tekee aktiivisista lihaksista punaisia
 const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
 const url = URL.createObjectURL(svgBlob);

 const img = new Image();
 img.onload = () => {
   // Luodaan canvas ja asetetaan sen koko
   const canvas = document.createElement("canvas");
   const width = svgElement.clientWidth || 500;  // Varmistetaan että leveys on asetettu
   const height = svgElement.clientHeight || 500; // Varmistetaan että korkeus on asetettu
   canvas.width = width;
   canvas.height = height;

   const ctx = canvas.getContext("2d");
   ctx.drawImage(img, 0, 0, width, height);  // Piirretään kuva canvasille

   // Käytetään toBlob metodia base64:n sijaan
   canvas.toBlob((blob) => {
     if (blob) {
       const pngData = URL.createObjectURL(blob); // Luodaan Blob URL
       setImage(pngData);  // Lähetetään Blob URL eteenpäin
     } else {
       console.error("Blob creation failed");
     }

     // Vapautetaan muistia
     URL.revokeObjectURL(url);
   }, "image/png");
 };


  img.src = url;
};
      


export default ConvertSvgToPng;