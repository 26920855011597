import React, {useState, useRef, useEffect, forwardRef} from "react";
import "./style/muscleStyle.css";

const MuscleMapFront = forwardRef(({width, height, onSelectionChange, activeMuscles}, ref) => {

    // const svgRef = useRef(null);

    useEffect(() => {

      if (ref?.current) {
        const musclePaths = ref.current.querySelectorAll('.muscle');
        musclePaths.forEach((path, index) => {
          path.setAttribute('data-index', index);  // Asetetaan jokaiselle pathille indeksi
          path.classList.toggle('active', activeMuscles.includes(index));  // Lisätään "active"-luokka, jos lihas on valittu
        });
      }
    }, [activeMuscles]);

    const handleMuscleClick = (e) => {

        e.target.classList.toggle('active');

            // Palauta lihaksen indeksi ylätasolle
            const index = parseInt(e.target.getAttribute('data-index'), 10);

            //const muscles = document.querySelectorAll('.muscle'); // Hakee kaikki lihaselementit
            //const index = Array.from(muscles).indexOf(e.target);  // Laskee indeksin listassa

            if (onSelectionChange && !isNaN(index)) {

              onSelectionChange(index);
            }
      };

      //default width = 586.000000pt height = 1171.000000pt
      if(!width || !height) return;

      return(
        <>
            <svg 
                ref={ref}
                version="1.0" 
                xmlns="http://www.w3.org/2000/svg"
                width={`${width}pt`} height={`${height}pt`} viewBox="0 0 586.000000 1171.000000"
                preserveAspectRatio="xMidYMid meet"
                onClick={handleMuscleClick}>
                    
                <g transform="translate(0.000000,1171.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path class="muscle" d="M2973 11643 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
                    <path class="muscle" d="M2803 11611 c-86 -41 -167 -104 -203 -159 -16 -25 -28 -48 -25 -50 3
                    -3 6 -3 8 -1 2 2 20 27 41 56 42 59 132 123 206 148 27 9 52 21 55 26 11 17
                    -19 9 -82 -20z"/>
                    <path class="muscle" d="M3206 11591 c94 -44 153 -94 201 -168 40 -64 52 -69 22 -11 -35 69
                    -113 143 -193 182 -40 20 -81 36 -92 36 -10 0 17 -18 62 -39z"/>
                    <path class="muscle" d="M2557 11363 c-4 -3 -7 -18 -6 -32 1 -26 1 -25 10 3 9 31 7 41 -4 29z"/>
                    <path class="muscle" d="M3452 11305 c0 -16 4 -43 8 -60 7 -29 8 -28 8 10 0 22 -3 49 -8 60
                    -7 17 -9 15 -8 -10z"/>
                    <path class="muscle" d="M2531 11140 c-2 -110 -7 -130 -37 -130 -24 0 -54 -34 -54 -60 1 -8 7
                    -3 16 13 11 21 22 27 48 27 30 0 34 4 40 31 8 42 6 148 -4 179 -5 17 -8 -2 -9
                    -60z"/>
                    <path class="muscle" d="M3473 11120 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
                    <path class="muscle" d="M3460 11023 c0 -17 22 -31 55 -35 24 -2 32 -10 42 -38 10 -31 12 -32
                    12 -10 1 38 -25 70 -58 70 -15 0 -32 5 -39 12 -8 8 -12 8 -12 1z"/>
                    <path class="muscle" d="M2451 10893 c-1 -17 4 -35 9 -38 11 -7 11 9 0 45 -8 24 -8 24 -9 -7z"/>
                    <path class="muscle" d="M3540 10866 c-15 -39 -23 -76 -17 -76 7 0 38 92 32 98 -2 3 -9 -7
                    -15 -22z"/>
                    <path class="muscle" d="M2471 10804 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                    <path class="muscle" d="M2481 10735 c-1 -49 20 -79 59 -85 26 -4 36 -11 42 -30 6 -23 7 -23
                    5 5 -2 25 -7 31 -40 38 -29 7 -38 15 -43 36 -3 14 -9 35 -14 46 -7 17 -9 16
                    -9 -10z"/>
                    <path class="muscle" d="M3503 10713 c-8 -34 -13 -39 -50 -47 l-41 -9 -6 -65 c-8 -73 -34
                    -148 -70 -201 -18 -25 -50 -127 -106 -336 -44 -165 -80 -311 -80 -324 0 -20 2
                    -22 16 -10 23 19 115 211 149 312 16 48 34 87 40 87 6 0 9 43 7 120 -3 113 -2
                    126 27 209 17 49 31 103 31 119 0 51 17 82 44 82 34 0 56 25 56 66 0 44 -6 43
                    -17 -3z"/>
                    <path class="muscle" d="M2600 10508 c0 -14 7 -31 15 -38 13 -11 13 -8 3 21 -15 44 -18 48
                    -18 17z"/>
                    <path class="muscle" d="M2648 10253 c-4 -119 -3 -138 12 -153 10 -10 28 -48 40 -86 12 -38
                    45 -115 72 -171 79 -163 106 -168 65 -10 -137 525 -142 540 -163 548 -21 7
                    -22 5 -26 -128z"/>
                    <path class="muscle" d="M2870 10293 c0 -28 43 -193 50 -193 5 0 12 17 15 38 3 20 8 47 11 60
                    3 16 -6 34 -36 65 -22 23 -40 37 -40 30z"/>
                    <path class="muscle" d="M3098 10273 c-31 -36 -40 -64 -33 -106 4 -23 11 -49 15 -57 11 -19
                    13 -12 35 80 17 74 19 100 7 100 -5 0 -16 -8 -24 -17z"/>
                    <path class="muscle" d="M3366 10033 c2 -38 5 -120 7 -184 5 -126 13 -151 58 -175 69 -35 74
                    0 23 175 -19 68 -33 125 -31 128 3 2 19 -39 37 -91 36 -105 66 -164 92 -180
                    12 -7 32 -7 70 1 79 17 228 73 228 86 0 7 -28 20 -61 30 -73 21 -178 72 -254
                    123 -51 33 -145 129 -145 146 0 5 -6 8 -14 8 -10 0 -13 -15 -10 -67z"/>
                    <path class="muscle" d="M2583 10041 c-75 -87 -269 -199 -389 -224 -30 -6 -54 -16 -54 -22 0
                    -14 250 -95 294 -95 33 0 38 4 59 48 14 26 39 91 57 145 17 54 34 96 37 93 3
                    -2 -11 -62 -31 -133 -36 -131 -39 -145 -37 -175 2 -28 38 -21 76 15 l36 32 -3
                    183 -3 182 -42 -49z"/>
                    <path class="muscle" d="M3316 9983 c-10 -32 -43 -111 -73 -175 -30 -65 -53 -125 -51 -133 2
                    -13 14 -9 57 17 87 53 95 70 99 203 5 140 -6 170 -32 88z"/>
                    <path class="muscle" d="M2660 9900 l1 -135 49 -40 c62 -51 110 -72 110 -49 0 8 -26 72 -58
                    142 -32 70 -64 143 -72 162 -25 66 -30 53 -30 -80z"/>
                    <path class="muscle" d="M1950 9785 c-124 -31 -232 -97 -338 -209 -79 -83 -165 -217 -122
                    -191 6 4 10 -31 10 -97 0 -97 3 -111 39 -204 21 -54 50 -125 65 -157 l26 -58
                    31 18 c17 10 57 44 90 76 75 75 132 186 160 317 24 114 54 169 117 215 62 45
                    171 91 252 104 99 17 111 21 105 31 -3 5 -56 35 -118 67 -62 31 -122 68 -135
                    80 -27 27 -92 30 -182 8z"/>
                    <path class="muscle" d="M3873 9793 c10 -16 -17 -34 -147 -103 -135 -72 -136 -68 9 -94 157
                    -28 283 -106 326 -202 10 -22 28 -81 39 -130 27 -118 43 -157 96 -231 46 -65
                    145 -153 171 -153 16 0 81 133 119 247 22 64 25 90 22 174 -2 75 0 96 8 84 27
                    -36 22 -10 -7 46 -40 75 -182 223 -262 274 -94 59 -179 87 -286 93 -62 3 -92
                    1 -88 -5z"/>
                    <path class="muscle" id="rinta-vasen" d="M2385 9599 c-90 -11 -239 -54 -285 -81 -51 -31 -109 -88 -130 -129
                    -11 -20 -27 -74 -36 -120 l-16 -84 27 -65 c14 -36 44 -114 66 -174 55 -152
                    103 -238 169 -305 119 -118 249 -156 459 -133 209 23 258 53 285 171 42 185
                    40 450 -5 659 -25 114 -49 173 -95 229 l-35 43 -167 -1 c-92 -1 -198 -5 -237
                    -10z"/>
                    <path class="muscle" id="rinta-oikea" d="M3220 9592 c-102 -51 -160 -260 -160 -583 0 -273 26 -405 90 -451 42
                    -30 163 -50 321 -51 l145 -2 70 34 c86 43 196 150 241 236 17 33 47 105 66
                    160 19 55 47 126 62 159 25 52 27 67 22 135 -10 154 -59 232 -180 290 -100 48
                    -178 67 -322 81 -174 16 -314 13 -355 -8z"/>
                    <path class="muscle" d="M1457 9330 c-35 -85 -42 -138 -38 -305 3 -93 5 -216 5 -273 1 -71 4
                    -102 12 -100 19 5 154 189 154 209 0 11 -20 67 -43 126 -50 123 -66 183 -76
                    286 -4 39 -10 65 -14 57z"/>
                    <path class="muscle" d="M4540 9293 c0 -69 -23 -155 -81 -307 l-51 -135 78 -100 c44 -55 81
                    -100 84 -100 3 -1 6 32 6 72 1 39 7 136 13 214 11 154 3 271 -25 355 -8 26
                    -17 48 -20 48 -2 0 -4 -21 -4 -47z"/>
                    <path class="muscle" d="M1793 8935 c-207 -320 -271 -509 -318 -942 -16 -154 -20 -289 -7
                    -297 20 -12 214 300 327 527 103 206 109 237 109 562 1 157 -3 266 -9 276 -8
                    14 -27 -10 -102 -126z"/>
                    <path class="muscle" d="M4106 9034 c-11 -86 -6 -436 8 -529 15 -101 32 -148 107 -297 70
                    -139 115 -218 222 -382 88 -134 91 -138 99 -110 17 60 -24 457 -67 646 -27
                    121 -77 256 -137 368 -34 65 -216 350 -223 350 -1 0 -5 -21 -9 -46z"/>
                    <path class="muscle" d="M4052 9003 c-18 -32 -64 -234 -113 -493 -57 -298 -57 -395 -2 -570 8
                    -27 18 6 67 210 26 107 53 220 60 250 19 77 8 637 -12 603z"/>
                    <path class="muscle" d="M1947 9003 c-3 -5 -8 -118 -12 -253 -4 -135 -11 -260 -15 -277 -6
                    -24 -5 -35 5 -40 7 -5 27 -71 45 -148 17 -77 39 -171 50 -210 10 -38 24 -89
                    30 -112 13 -53 26 -55 32 -5 3 20 13 79 22 131 21 111 16 156 -45 471 -36 184
                    -95 440 -104 448 -2 2 -5 0 -8 -5z"/>
                    <path class="muscle" d="M1665 8853 c-28 -22 -89 -81 -136 -131 -48 -51 -93 -92 -100 -92 -9
                    0 -25 -40 -46 -117 -49 -175 -54 -205 -63 -361 -8 -140 -7 -147 25 -290 54
                    -241 54 -241 81 0 28 265 59 460 90 566 28 95 117 299 169 386 20 32 34 63 33
                    68 -2 6 -26 -8 -53 -29z"/>
                    <path class="muscle" d="M4300 8870 c0 -6 31 -72 69 -148 38 -75 82 -176 99 -222 51 -145 92
                    -375 116 -656 7 -85 16 -157 19 -160 3 -3 10 3 15 13 13 25 52 187 59 246 3
                    26 9 50 13 52 15 9 -12 322 -35 410 -35 136 -67 228 -74 216 -5 -8 -22 6 -51
                    44 -45 58 -179 188 -211 206 -13 7 -19 6 -19 -1z"/>
                    <path class="muscle" d="M2076 8624 c-21 -56 16 -153 74 -192 30 -20 44 -23 95 -19 70 5 137
                    34 133 56 -2 9 -30 29 -63 44 -33 16 -97 51 -141 78 -45 27 -84 49 -87 49 -3
                    0 -8 -7 -11 -16z"/>
                    <path class="muscle" d="M3830 8587 c-41 -24 -106 -58 -145 -76 l-70 -32 28 -24 c50 -44 152
                    -58 207 -30 72 37 119 184 64 202 -5 1 -43 -16 -84 -40z"/>
                    <path class="muscle" d="M2691 8484 c-69 -13 -134 -30 -146 -38 -12 -8 -31 -32 -41 -53 -17
                    -33 -19 -61 -19 -218 1 -182 9 -235 38 -235 8 0 48 22 88 48 41 26 125 77 188
                    112 134 76 181 116 181 154 0 77 -45 230 -74 248 -18 12 -84 7 -215 -18z"/>
                    <path class="muscle" d="M3084 8486 c-20 -32 -42 -115 -50 -193 -9 -83 3 -99 113 -158 139
                    -75 265 -152 297 -179 22 -20 33 -23 47 -16 27 15 34 67 34 245 0 143 -2 165
                    -22 207 -11 25 -31 52 -43 58 -25 13 -277 59 -327 60 -24 0 -37 -7 -49 -24z"/>
                    <path class="muscle" d="M4104 8380 c3 -37 29 -150 61 -260 71 -245 92 -338 105 -475 10 -110
                    39 -226 57 -232 6 -2 18 26 28 62 10 37 35 102 56 146 22 44 39 88 39 97 0 9
                    -47 102 -105 206 -98 179 -202 392 -232 481 l-13 40 4 -65z"/>
                    <path class="muscle" d="M1895 8405 c-31 -83 -135 -308 -181 -390 -109 -196 -154 -282 -154
                    -297 0 -8 21 -61 46 -117 25 -57 49 -120 51 -142 12 -90 53 -15 73 134 26 200
                    45 291 100 480 62 216 85 317 77 339 -3 9 -7 7 -12 -7z"/>
                    <path class="muscle" d="M2110 8389 c0 -45 20 -106 42 -125 17 -16 21 -16 59 2 74 33 129 73
                    129 93 0 24 -18 31 -88 31 -31 1 -75 7 -97 15 -22 8 -41 15 -42 15 -2 0 -3
                    -14 -3 -31z"/>
                    <path class="muscle" d="M3850 8405 c-19 -7 -65 -14 -101 -14 -48 -1 -70 -5 -80 -17 -12 -14
                    -9 -20 16 -41 43 -34 105 -71 137 -78 32 -8 53 18 73 96 19 72 15 77 -45 54z"/>
                    <path class="muscle" d="M2305 8296 c-38 -25 -83 -56 -100 -69 l-30 -25 25 -27 c48 -53 153
                    -89 189 -66 28 18 26 177 -2 214 -11 15 -20 12 -82 -27z"/>
                    <path class="muscle" d="M3610 8304 c-18 -54 -16 -148 4 -178 22 -34 56 -34 123 0 61 31 98
                    67 88 85 -8 12 -185 129 -196 129 -4 0 -13 -16 -19 -36z"/>
                    <path class="muscle" d="M2157 8100 c-55 -65 -42 -123 40 -183 56 -40 146 -87 168 -87 25 0
                    37 49 32 129 -5 63 -9 78 -29 93 -19 14 -167 78 -182 78 -2 0 -15 -13 -29 -30z"/>
                    <path class="muscle" d="M3711 8091 c-87 -36 -101 -56 -101 -141 0 -87 12 -120 43 -120 28 0
                    202 112 217 140 17 32 12 95 -11 124 -26 33 -63 33 -148 -3z"/>
                    <path class="muscle" d="M2804 8065 c-186 -94 -306 -214 -319 -319 -7 -54 -1 -106 17 -138 11
                    -22 56 -23 193 -3 160 23 238 42 252 61 26 36 36 101 36 234 -1 227 -21 245
                    -179 165z"/>
                    <path class="muscle" d="M3049 8093 c-19 -22 -26 -353 -8 -395 19 -46 64 -62 259 -93 130 -20
                    183 -20 203 3 23 25 23 145 1 197 -33 81 -152 182 -309 265 -81 43 -124 50
                    -146 23z"/>
                    <path class="muscle" d="M1244 7888 c-28 -40 -62 -93 -76 -118 -60 -107 -118 -295 -138 -452
                    -20 -163 -38 -619 -31 -833 l6 -210 77 265 c197 677 268 963 268 1075 0 33 -9
                    123 -20 200 -11 77 -20 141 -20 142 0 17 -25 -10 -66 -69z"/>
                    <path class="muscle" d="M4676 7802 c-33 -243 -29 -271 160 -947 114 -408 161 -562 175 -571
                    7 -4 9 22 5 87 -4 52 -9 135 -12 184 -7 103 4 107 22 8 8 -49 12 -58 13 -33 0
                    19 -3 60 -8 90 -9 54 -20 200 -41 528 -13 207 -31 308 -81 457 -43 128 -66
                    174 -137 273 -68 93 -74 88 -96 -76z"/>
                    <path class="muscle" d="M2113 7890 c-9 -10 -21 -16 -26 -13 -6 4 -7 -4 -3 -20 3 -15 9 -54
                    13 -87 4 -41 10 -60 19 -60 8 0 51 -36 97 -80 90 -88 159 -130 186 -113 12 8
                    17 30 19 96 4 134 -14 162 -160 241 -94 52 -125 60 -145 36z"/>
                    <path class="muscle" d="M3850 7900 c-73 -21 -233 -121 -248 -155 -6 -14 -14 -60 -18 -102 -6
                    -84 5 -133 31 -133 33 0 96 43 179 121 52 49 86 75 86 65 0 -9 4 -16 9 -16 5
                    0 12 24 15 54 4 29 13 76 21 105 7 28 9 51 5 51 -5 0 -10 -6 -13 -12 -3 -9
                    -10 -6 -23 8 -14 17 -25 20 -44 14z"/>
                    <path class="muscle" d="M3880 7657 c0 -6 -12 -18 -27 -25 -16 -6 -71 -46 -123 -87 -104 -82
                    -118 -105 -119 -191 -1 -56 26 -104 57 -104 17 0 92 60 187 149 l50 47 -3 112
                    c-2 62 -8 112 -13 112 -5 0 -9 -6 -9 -13z"/>
                    <path class="muscle" d="M1459 7618 c-67 -69 -76 -95 -228 -651 -121 -439 -162 -609 -167
                    -682 l-5 -70 20 35 c10 19 93 234 184 476 91 243 197 512 236 598 81 177 94
                    239 64 296 -26 51 -53 50 -104 -2z"/>
                    <path class="muscle" d="M4454 7635 c-11 -17 -17 -48 -17 -93 -1 -63 4 -76 67 -212 77 -162
                    89 -193 258 -650 66 -179 134 -354 151 -390 l31 -65 4 41 c5 59 -199 841 -303
                    1164 -62 189 -144 277 -191 205z"/>
                    <path class="muscle" d="M2105 7551 l-7 -98 54 -50 c157 -147 177 -160 208 -143 40 21 53 110
                    26 174 -19 46 -232 216 -271 216 -1 0 -6 -44 -10 -99z"/>
                    <path class="muscle" d="M2745 7575 c-205 -32 -265 -72 -265 -178 0 -72 20 -160 42 -184 29
                    -31 111 -46 259 -47 111 -1 129 1 148 18 32 29 41 78 41 229 0 124 -2 139 -20
                    157 -24 24 -80 25 -205 5z"/>
                    <path class="muscle" d="M3060 7572 c-29 -23 -35 -88 -25 -239 7 -105 8 -108 42 -142 l34 -34
                    132 7 c168 9 217 18 239 46 27 33 48 116 48 186 0 59 -2 63 -40 97 -46 42
                    -134 72 -260 87 -118 14 -144 13 -170 -8z"/>
                    <path class="muscle" d="M1586 7428 c-40 -63 -188 -447 -291 -753 -111 -329 -147 -485 -112
                    -485 18 0 33 29 137 251 49 106 95 196 101 199 9 6 73 170 190 495 43 117 73
                    240 63 257 -4 6 -9 6 -14 -3 -6 -8 -13 -1 -24 24 -17 43 -30 46 -50 15z"/>
                    <path class="muscle" d="M4367 7413 c-8 -18 -18 -27 -29 -25 -15 3 -13 -10 13 -110 17 -62 53
                    -169 79 -238 27 -69 82 -223 122 -344 40 -120 74 -212 76 -204 1 8 -6 43 -17
                    78 -11 35 -19 66 -17 67 2 2 40 -77 85 -174 45 -98 96 -200 114 -228 l32 -50
                    3 50 c6 96 -92 408 -287 914 -107 278 -144 333 -174 264z"/>
                    <path class="muscle" d="M2080 7373 c-7 -27 -14 -144 -17 -263 -3 -131 -10 -240 -19 -279 -13
                    -60 -12 -94 2 -58 5 13 41 -18 149 -129 94 -94 150 -144 161 -142 39 7 56 52
                    65 164 21 279 7 384 -68 494 -35 52 -238 260 -253 260 -4 0 -13 -21 -20 -47z"/>
                    <path class="muscle" d="M3761 7280 l-130 -140 -32 -97 -31 -98 11 -155 c19 -249 32 -290 91
                    -290 20 0 31 6 35 20 4 12 62 77 130 147 110 112 124 123 130 103 15 -47 15
                    -9 -1 56 -10 46 -18 150 -23 314 -8 247 -12 280 -36 280 -7 0 -72 -63 -144
                    -140z"/>
                    <path class="muscle" d="M2513 7109 c-18 -18 -16 -115 3 -224 37 -215 143 -563 215 -711 34
                    -69 64 -112 185 -257 8 -10 20 -17 27 -14 31 10 35 56 41 559 l6 506 -40 48
                    c-22 27 -40 54 -40 62 0 24 -53 33 -220 39 -129 4 -168 2 -177 -8z"/>
                    <path class="muscle" d="M3151 7110 c-19 -5 -39 -16 -44 -25 -5 -10 -25 -35 -43 -55 l-34 -37
                    0 -521 c0 -314 4 -531 10 -546 5 -14 16 -26 25 -26 22 0 170 190 210 271 99
                    196 265 821 238 897 -17 49 -28 52 -182 51 -80 -1 -161 -5 -180 -9z"/>
                    <path class="muscle" d="M2018 6715 c-19 -33 -71 -310 -88 -475 -6 -58 -24 -181 -40 -275 -51
                    -302 -73 -535 -51 -535 5 0 12 15 15 33 3 17 44 158 91 312 164 537 172 578
                    150 758 -14 104 -19 125 -39 143 -13 11 -25 27 -28 34 -2 8 -7 10 -10 5z"/>
                    <path class="muscle" d="M3980 6709 c0 -9 -7 -20 -15 -23 -26 -10 -43 -66 -55 -178 -18 -166
                    -16 -173 157 -748 44 -146 83 -277 87 -292 3 -16 11 -28 16 -28 22 0 5 182
                    -45 475 -19 110 -39 250 -45 310 -14 147 -27 229 -57 360 -25 109 -42 158 -43
                    124z"/>
                    <path class="muscle" d="M1391 6546 c-23 -76 -25 -84 -12 -70 13 14 43 113 38 127 -2 6 -14
                    -19 -26 -57z"/>
                    <path class="muscle" d="M972 6580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M2110 6564 c0 -47 118 -616 176 -845 31 -123 110 -387 176 -588 66
                    -201 130 -411 143 -466 29 -121 90 -447 106 -567 7 -49 16 -88 21 -88 4 0 8 6
                    8 14 0 56 33 294 45 322 13 32 13 34 -3 32 -13 -2 -18 6 -20 37 -22 334 -102
                    689 -241 1078 -60 166 -69 195 -52 168 5 -7 14 -10 20 -7 34 21 131 272 131
                    338 0 37 -34 109 -66 139 -27 25 -26 27 -43 -75 -7 -38 -27 -107 -46 -155
                    l-34 -86 15 -64 c8 -35 13 -65 11 -67 -4 -4 -101 299 -186 582 -71 235 -91
                    286 -122 308 -32 22 -39 20 -39 -10z"/>
                    <path class="muscle" d="M3842 6555 c-27 -33 -49 -98 -143 -410 -74 -248 -145 -463 -263 -800
                    -79 -228 -149 -542 -181 -818 -9 -73 -18 -140 -21 -150 -3 -10 2 -73 11 -140
                    9 -67 18 -141 21 -165 7 -58 20 -53 28 11 14 107 88 496 117 612 17 66 81 275
                    144 465 159 484 251 861 340 1393 4 21 1 27 -15 27 -10 0 -27 -11 -38 -25z"/>
                    <path class="muscle" d="M962 6510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M2221 6488 c2 -32 105 -320 114 -319 14 1 18 153 5 200 -15 58 -122
                    163 -119 119z"/>
                    <path class="muscle" d="M3728 6463 c-53 -54 -68 -98 -68 -197 0 -47 4 -87 9 -90 14 -9 111
                    257 111 305 0 28 -11 24 -52 -18z"/>
                    <path class="muscle" d="M5052 6450 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M947 6440 c-4 -14 -6 -41 -6 -60 l1 -35 9 40 c12 48 8 96 -4 55z"/>
                    <path class="muscle" d="M4636 6435 c11 -47 75 -259 80 -264 9 -9 2 21 -31 145 -20 71 -40
                    133 -46 139 -7 7 -8 1 -3 -20z"/>
                    <path class="muscle" d="M1355 6428 c-16 -46 -46 -171 -42 -175 6 -5 39 95 52 155 6 31 -1 46
                    -10 20z"/>
                    <path class="muscle" d="M5062 6370 c0 -19 4 -46 8 -60 7 -21 8 -19 8 15 0 22 -3 49 -8 60 -7
                    16 -9 13 -8 -15z"/>
                    <path class="muscle" d="M2372 6143 c3 -196 4 -203 26 -225 l23 -23 24 29 c30 38 58 136 58
                    207 0 66 -18 101 -88 169 l-46 45 3 -202z"/>
                    <path class="muscle" d="M3573 6282 c-29 -34 -57 -80 -63 -102 -25 -94 44 -304 91 -275 28 18
                    39 76 38 207 0 73 -4 156 -7 183 l-7 50 -52 -63z"/>
                    <path class="muscle" d="M922 6300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M907 6247 c-4 -10 -7 -31 -6 -45 l0 -27 10 25 c5 13 8 33 7 45 -3 19
                    -4 19 -11 2z"/>
                    <path class="muscle" d="M5095 6212 c10 -77 38 -117 113 -161 37 -21 76 -48 86 -60 11 -12 24
                    -21 29 -21 22 0 -50 63 -107 93 -66 35 -80 53 -107 134 -19 56 -19 57 -14 15z"/>
                    <path class="muscle" d="M3870 6209 c-5 -13 -39 -144 -75 -292 -78 -324 -135 -524 -215 -762
                    -33 -99 -60 -197 -60 -217 0 -131 55 -523 101 -715 30 -131 43 -162 85 -212
                    64 -77 86 -40 240 407 112 322 119 363 118 682 0 216 -4 296 -22 430 -36 266
                    -123 665 -152 694 -5 5 -13 -1 -20 -15z"/>
                    <path class="muscle" d="M1301 6204 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                    <path class="muscle" d="M2105 6186 c-23 -56 -71 -240 -99 -386 -49 -252 -61 -384 -61 -705 0
                    -293 1 -303 27 -405 27 -108 82 -268 161 -472 63 -162 96 -226 120 -234 83
                    -26 174 310 222 817 l16 165 -61 169 c-96 267 -165 517 -251 908 -18 81 -38
                    156 -45 165 -11 15 -14 13 -29 -22z"/>
                    <path class="muscle" d="M877 6133 c-9 -17 -33 -41 -52 -53 -43 -26 -40 -38 3 -16 35 18 76
                    70 69 89 -2 6 -11 -2 -20 -20z"/>
                    <path class="muscle" d="M1276 6122 c-4 -24 -6 -46 -2 -49 8 -8 18 42 14 68 -3 18 -6 13 -12
                    -19z"/>
                    <path class="muscle" d="M3439 6118 c-57 -76 -63 -122 -28 -234 31 -102 96 -237 111 -232 14
                    5 46 107 47 151 1 15 -14 67 -33 115 -19 48 -37 107 -40 132 -17 115 -19 117
                    -57 68z"/>
                    <path class="muscle" d="M4722 6110 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M730 6024 c-48 -41 -113 -144 -91 -144 5 0 15 15 22 33 7 17 35 56
                    63 85 53 56 56 67 6 26z"/>
                    <path class="muscle" d="M4730 6040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
                    -10 -4 -10 -10z"/>
                    <path class="muscle" d="M1272 6000 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M4721 5984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                    <path class="muscle" d="M5330 5944 c0 -11 53 -104 57 -100 5 5 -45 106 -52 106 -3 0 -5 -3
                    -5 -6z"/>
                    <path class="muscle" d="M1297 5915 c3 -16 12 -46 19 -65 11 -30 13 -32 13 -10 0 14 -8 43
                    -19 65 -17 35 -18 36 -13 10z"/>
                    <path class="muscle" d="M4695 5906 c-34 -81 -29 -270 11 -364 23 -55 24 -33 3 43 -23 85 -25
                    230 -4 300 17 59 11 72 -10 21z"/>
                    <path class="muscle" d="M567 5773 c-31 -49 -88 -123 -126 -167 -39 -43 -71 -87 -71 -97 0
                    -26 57 -59 100 -58 35 1 35 1 -11 14 -85 23 -87 33 -27 102 88 102 207 277
                    196 289 -3 2 -30 -35 -61 -83z"/>
                    <path class="muscle" d="M5420 5780 c12 -23 24 -40 26 -37 7 7 -22 64 -37 72 -6 4 -1 -12 11
                    -35z"/>
                    <path class="muscle" d="M1334 5759 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z"/>
                    <path class="muscle" d="M5470 5704 c0 -6 29 -44 64 -85 84 -100 94 -118 70 -136 -31 -23 -95
                    -20 -139 7 -17 11 -46 37 -63 60 -39 47 -63 52 -61 13 1 -18 3 -22 6 -10 4 14
                    15 8 64 -37 62 -58 104 -74 156 -61 28 6 73 42 73 56 0 4 -32 45 -71 91 -91
                    106 -99 115 -99 102z"/>
                    <path class="muscle" d="M1327 5690 c-3 -8 -10 -37 -16 -63 -7 -26 -33 -103 -60 -170 -53
                    -132 -84 -228 -76 -235 2 -3 7 1 9 9 3 8 30 82 61 164 65 173 98 281 92 298
                    -3 8 -6 7 -10 -3z"/>
                    <path class="muscle" d="M1914 5593 c-33 -102 -73 -204 -74 -185 0 6 -4 12 -10 12 -15 0 -12
                    -252 5 -431 8 -85 19 -239 25 -344 16 -280 29 -351 99 -535 21 -58 47 -140 57
                    -183 11 -48 23 -76 29 -73 7 2 24 -21 39 -51 26 -53 28 -55 44 -39 14 14 17
                    36 15 148 -3 166 -11 202 -90 423 -121 340 -138 431 -138 775 0 214 4 278 23
                    398 25 161 26 172 13 172 -5 0 -21 -39 -37 -87z"/>
                    <path class="muscle" d="M4050 5663 c0 -12 9 -81 20 -155 40 -257 37 -605 -6 -818 -8 -41 -55
                    -194 -104 -340 -83 -248 -89 -272 -94 -370 -7 -116 3 -211 21 -217 7 -3 24 19
                    39 48 31 63 36 57 54 -61 7 -47 17 -92 22 -100 6 -10 7 -6 4 15 -4 17 -9 82
                    -12 146 l-7 115 56 165 c85 248 90 275 107 579 6 102 17 254 25 339 8 85 15
                    210 15 278 0 110 -2 124 -17 121 -13 -2 -25 24 -60 126 -43 129 -63 171 -63
                    129z"/>
                    <path class="muscle" d="M2773 5619 c19 -78 31 -263 32 -469 0 -206 -2 -240 -19 -280 -26 -63
                    -31 -200 -13 -345 9 -65 13 -123 11 -127 -3 -4 -1 -8 5 -8 5 0 12 4 15 8 15
                    24 132 666 141 772 4 41 2 132 -4 201 -7 69 -9 133 -5 143 3 9 17 16 30 16 13
                    0 24 5 24 10 0 12 -30 13 -49 1 -19 -12 -39 -2 -115 57 -54 42 -59 44 -53 21z"/>
                    <path class="muscle" d="M3199 5612 c-77 -65 -96 -74 -144 -66 -29 4 -45 3 -45 -4 0 -6 12
                    -12 28 -14 l27 -3 -3 -195 c-3 -189 -2 -201 32 -375 19 -99 47 -247 61 -330
                    31 -175 43 -225 54 -225 14 0 36 187 35 295 -1 72 -7 124 -24 185 -20 74 -23
                    107 -22 245 1 159 16 378 32 465 5 25 7 46 5 48 -1 2 -18 -10 -36 -26z"/>
                    <path class="muscle" d="M609 5553 c-69 -73 -80 -95 -16 -34 59 57 62 57 70 -4 2 -16 5 -4 6
                    28 0 31 -3 57 -7 57 -4 0 -28 -21 -53 -47z"/>
                    <path class="muscle" d="M4750 5439 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z"/>
                    <path class="muscle" d="M5353 5415 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
                    <path class="muscle" d="M653 5375 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
                    <path class="muscle" d="M4770 5398 c0 -14 102 -320 106 -315 6 6 -74 264 -91 297 -8 14 -14
                    22 -15 18z"/>
                    <path class="muscle" d="M5362 5245 c-4 -104 -19 -207 -38 -254 -13 -32 -23 -43 -45 -48 -27
                    -5 -29 -8 -27 -52 1 -37 2 -40 5 -13 3 24 14 39 38 54 18 12 38 31 45 41 16
                    26 32 180 27 269 l-3 73 -2 -70z"/>
                    <path class="muscle" d="M642 5185 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
                    <path class="muscle" d="M1128 5110 c-37 -81 -57 -97 -86 -68 -8 8 -14 5 -22 -16 -13 -35 -13
                    -56 1 -33 5 9 20 17 32 17 39 0 64 28 93 100 15 39 25 72 23 75 -3 2 -21 -32
                    -41 -75z"/>
                    <path class="muscle" d="M651 5056 c-1 -49 30 -104 74 -134 33 -21 35 -21 35 -3 0 13 -9 20
                    -30 24 -33 7 -56 44 -70 110 l-8 42 -1 -39z"/>
                    <path class="muscle" d="M4890 5054 c0 -17 28 -49 36 -41 4 4 -2 17 -14 30 -12 12 -22 17 -22
                    11z"/>
                    <path class="muscle" d="M4960 5034 c-12 -14 -12 -17 2 -25 9 -5 24 -30 32 -57 33 -98 74
                    -136 106 -97 18 22 23 19 49 -30 20 -37 29 -45 52 -45 15 0 30 4 33 9 4 5 -3
                    7 -14 4 -29 -8 -56 21 -75 78 -18 55 -30 61 -39 24 -17 -68 -68 -31 -98 69
                    -24 84 -30 92 -48 70z"/>
                    <path class="muscle" d="M990 4925 c-14 -37 -39 -65 -57 -65 -7 0 -21 13 -32 30 l-18 30 -26
                    -60 c-27 -65 -48 -80 -79 -61 -16 10 -19 10 -16 -1 3 -7 18 -14 35 -16 30 -3
                    47 10 79 64 10 17 14 18 37 5 24 -13 29 -12 48 5 27 24 52 72 46 87 -3 7 -10
                    -1 -17 -18z"/>
                    <path class="muscle" d="M2481 4633 c-18 -142 -47 -323 -64 -403 -27 -123 -31 -164 -31 -275
                    1 -150 16 -235 48 -272 l23 -27 47 18 c28 11 75 43 112 78 53 50 64 57 64 39
                    0 -11 4 -21 8 -21 5 0 14 37 21 83 7 45 15 96 18 112 3 23 2 26 -6 15 -13 -19
                    -17 -9 -31 95 -29 206 -145 760 -167 800 -6 10 -21 -77 -42 -242z"/>
                    <path class="muscle" d="M3486 4882 c-21 -34 -143 -620 -167 -798 -14 -112 -18 -126 -29 -99
                    l-9 20 0 -20 c-1 -11 6 -55 14 -98 8 -43 15 -81 15 -83 0 -2 5 -4 11 -4 5 0 7
                    6 3 13 -4 6 22 -16 59 -51 36 -34 87 -72 112 -83 41 -18 49 -19 69 -6 34 23
                    47 79 53 242 5 141 4 160 -26 309 -17 87 -45 271 -62 409 -16 138 -31 253 -33
                    255 -2 2 -7 -1 -10 -6z"/>
                    <path class="muscle" d="M2010 3731 c-6 -38 -10 -80 -8 -93 2 -13 10 16 18 65 7 48 11 90 8
                    93 -3 3 -11 -26 -18 -65z"/>
                    <path class="muscle" d="M3316 3748 c2 -13 11 -106 19 -207 9 -101 20 -191 26 -200 8 -12 9
                    -8 5 17 -3 17 -3 32 -1 32 3 0 23 -36 45 -79 56 -113 70 -118 49 -20 -9 40 -8
                    61 1 90 12 36 10 44 -52 196 -36 87 -68 166 -71 176 -9 25 -25 21 -21 -5z"/>
                    <path class="muscle" d="M2659 3723 c-6 -21 -36 -95 -65 -164 -55 -129 -63 -166 -42 -187 9
                    -9 9 -27 -1 -77 -7 -36 -11 -68 -8 -71 3 -3 25 30 49 73 25 43 50 77 56 76 11
                    -3 17 34 32 217 12 144 12 170 1 170 -5 0 -15 -17 -22 -37z"/>
                    <path class="muscle" d="M4012 3575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
                    <path class="muscle" d="M1992 3560 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M4021 3320 l-1 -125 -34 84 c-48 117 -56 121 -56 29 0 -99 -19 -247
                    -86 -658 -151 -928 -210 -1372 -194 -1441 5 -21 10 -15 29 35 30 81 132 423
                    191 642 63 237 90 409 145 934 l14 135 -5 -140 c-6 -173 -37 -454 -70 -634
                    -29 -157 -83 -383 -183 -766 -39 -148 -71 -278 -71 -289 0 -43 20 4 60 139 23
                    77 64 212 90 300 52 172 86 312 149 605 46 216 61 335 61 494 0 76 4 117 12
                    122 17 11 3 -271 -21 -431 -11 -71 -42 -254 -70 -405 -27 -151 -59 -338 -71
                    -415 -25 -167 -38 -415 -22 -415 14 0 19 21 27 110 17 202 63 502 147 950 38
                    205 41 239 41 385 0 135 -4 179 -27 285 -20 95 -29 175 -36 335 -13 266 -17
                    291 -19 135z"/>
                    <path class="muscle" d="M1968 3205 c-6 -195 -10 -247 -32 -345 -22 -98 -26 -140 -26 -285 0
                    -180 6 -229 74 -600 60 -326 101 -626 125 -920 l7 -90 8 100 c16 191 -4 372
                    -99 895 -84 462 -111 691 -95 790 7 41 8 38 9 -30 4 -260 89 -721 201 -1095
                    26 -88 71 -239 100 -335 48 -163 70 -219 70 -177 0 9 -51 220 -114 469 -114
                    452 -153 638 -181 857 -20 155 -35 359 -34 440 2 63 2 61 10 -29 37 -432 84
                    -741 151 -985 50 -185 176 -598 195 -640 l18 -40 3 45 c8 105 -54 561 -178
                    1320 -43 267 -84 535 -91 595 -17 161 -23 200 -34 200 -6 0 -22 -34 -37 -75
                    l-27 -75 -1 75 c-1 41 -5 95 -9 120 -5 30 -9 -32 -13 -185z"/>
                    <path class="muscle" d="M2635 3331 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
                    <path class="muscle" d="M3374 3263 c3 -10 17 -61 32 -113 26 -96 50 -146 33 -72 -13 60 -59
                    202 -65 202 -3 0 -3 -8 0 -17z"/>
                    <path class="muscle" d="M2612 3234 c-17 -59 -25 -104 -19 -104 9 0 38 110 34 128 -2 10 -8 0
                    -15 -24z"/>
                    <path class="muscle" d="M2567 3097 c-4 -10 -7 -31 -6 -45 l0 -27 10 25 c5 13 8 33 7 45 -3
                    19 -4 19 -11 2z"/>
                    <path class="muscle" d="M3441 2920 c-7 -47 -27 -143 -44 -215 -29 -126 -30 -138 -31 -370 -1
                    -209 1 -248 18 -303 11 -35 25 -66 33 -69 7 -3 33 10 57 29 128 99 163 204
                    153 456 -8 187 -22 239 -136 477 l-38 80 -12 -85z"/>
                    <path class="muscle" d="M2523 2937 c-50 -73 -114 -245 -132 -352 -30 -176 -21 -377 21 -458
                    27 -53 140 -167 166 -167 15 0 25 15 41 61 22 58 22 72 19 332 -4 267 -5 274
                    -35 392 -18 66 -35 144 -39 173 -3 28 -9 52 -13 52 -3 0 -16 -15 -28 -33z"/>
                    <path class="muscle" d="M2414 1973 c3 -49 11 -178 17 -288 5 -110 15 -236 20 -280 6 -44 14
                    -150 19 -235 7 -142 11 -163 44 -255 35 -95 37 -104 30 -185 -7 -101 1 -102
                    16 -2 11 71 11 73 -29 190 l-41 118 0 226 c0 157 5 252 15 314 15 88 67 313
                    80 347 5 13 2 17 -12 17 -11 0 -50 27 -86 60 -36 33 -68 60 -72 60 -4 0 -4
                    -39 -1 -87z"/>
                    <path class="muscle" d="M3516 2002 c-37 -34 -75 -62 -83 -62 -11 0 -14 -6 -9 -22 3 -13 23
                    -84 42 -158 l37 -135 7 -303 c4 -166 10 -305 14 -309 3 -3 6 11 7 33 3 106 18
                    305 27 349 13 64 35 497 30 595 l-3 74 -69 -62z"/>
                    <path class="muscle" d="M3886 1031 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
                    <path class="muscle" d="M3472 907 c-12 -32 -22 -65 -21 -75 0 -9 11 12 25 47 14 35 23 69 21
                    75 -2 6 -13 -15 -25 -47z"/>
                    <path class="muscle" d="M3902 920 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                    <path class="muscle" d="M2098 914 c-5 -4 -8 -21 -7 -38 0 -30 0 -30 10 -7 11 27 8 57 -3 45z"/>
                    <path class="muscle" d="M3915 693 c0 -205 -1 -201 115 -348 33 -41 69 -90 80 -108 24 -39 26
                    -106 4 -149 -12 -23 -13 -29 -2 -26 20 7 40 78 33 120 -8 42 -40 95 -119 193
                    -95 118 -99 131 -92 297 5 105 3 150 -6 171 -11 24 -13 2 -13 -150z"/>
                    <path class="muscle" d="M2065 807 c-4 -10 -4 -55 1 -100 12 -119 10 -188 -9 -223 -8 -17 -48
                    -73 -87 -125 -86 -113 -110 -158 -109 -201 1 -20 3 -25 6 -13 14 62 33 97 103
                    189 106 140 122 172 120 254 -1 116 -15 239 -25 219z"/>
                    <path class="muscle" d="M3451 734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                    <path class="muscle" d="M3467 633 c8 -63 13 -67 13 -10 0 25 -4 49 -10 52 -6 4 -7 -11 -3
                    -42z"/>
                    <path class="muscle" d="M2523 545 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
                    <path class="muscle" d="M3482 480 c0 -19 3 -57 8 -85 l7 -50 1 65 c0 36 -3 74 -8 85 -7 16
                    -9 13 -8 -15z"/>
                    <path class="muscle" d="M2512 415 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
                    <path class="muscle" d="M2501 310 c0 -19 4 -68 9 -109 14 -104 2 -121 -83 -122 -44 0 -56 -3
                    -42 -9 34 -15 79 -11 113 10 30 18 32 23 31 72 0 65 -26 211 -28 158z"/>
                    <path class="muscle" d="M3501 254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                    <path class="muscle" d="M3476 184 c-18 -91 55 -150 139 -114 14 6 4 9 -37 9 -50 1 -60 4 -74
                    26 -11 16 -15 40 -12 70 4 54 -7 60 -16 9z"/>
                    <path class="muscle" d="M1870 92 c0 -19 34 -22 233 -20 l202 1 -205 7 c-113 3 -211 9 -217
                    13 -7 5 -13 4 -13 -1z"/>
                    <path class="muscle" d="M3710 70 c20 -13 67 -13 75 0 4 6 -12 10 -42 10 -35 0 -44 -3 -33
                    -10z"/>
                    <path class="muscle" d="M3888 73 c34 -2 90 -2 125 0 34 2 6 3 -63 3 -69 0 -97 -1 -62 -3z"/>
                </g>
            </svg>
        </>
      )
});

export default MuscleMapFront;