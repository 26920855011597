import React, {useState, useEffect, useRef} from "react";
import {useLocation, useNavigate, useParams } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";

//data
import { useData} from "../../../Utils/UseContext.jsx";
//utils
import { DateNow, DateToString } from "../../../Utils/Utils.jsx";
import { useLanguage } from "../../../Utils/UseContext.jsx";
//API
import { InsertTrainingPlansEvent } from "../../../Utils/ApiRequests.jsx";
//Style
import { Column, Row, RowColumn } from "../../../components/style/Row.styled.jsx";
import { Container, StyledPlannerContainer} from "../../../components/style/Container.styled.jsx";
//components
import { WorkoutMedia } from "../WorkoutMedia.jsx";
import { Loading } from "../../../Utils/Utils.jsx";
import PublishDetails from "../Public/PublishDetails.jsx";
import { ScrollView, CustomPopUp, Modal } from "../../../components/Components.jsx";

//Icons
import { IoIosArrowDown, IoIosArrowBack} from "react-icons/io";
import { CustomButton } from "../../../components/Buttons.jsx";
import { StyledSubmitButton, StyledPublishButton } from "../../../components/style/Buttons.styled.jsx";
// translation
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'




const MyTrainingPlan = ({theme}) => {
    const {language} = useLanguage();
    const todayRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();

    //location.state || {};
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    const {exercisesData, personalTrainingPlan, setPersonalData, setPersonalTrainingPlan} = useData();
    const [days, setDays] = useState([]);
    const [description, setDescription] = useState(""); // Asetetaan oletukseksi 0 päivää
    const [media, setMedia] = useState(null); // Harjoitukset tietokannasta
    const [isLoader, setIsLoader] = useState(false);
    const [publishValue, setPublishValue] = useState({id: null, name: null, list: [], description: null, media: null, type: 'training_plan'});
    const [trainingPlan, setTrainingPlan] = useState();
    const [count, setCount] = useState({workout: 0, rest: 0});
    const [name, setname] = useState("");
    const [infoOpen, setInfoOpen] = useState(false);
    const [isPlanActive, setIsPlanActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPublished, setIsPublished] = useState(false);

    const deviceScreenSize = {
        width: window.screen.width,
        height: window.screen.height,
      };


    // Esimerkki harjoituksista, jotka voisivat tulla tietokannasta
    useEffect(() => {
        if (!personalTrainingPlan || !personalTrainingPlan.trainingplan) {
            setIsLoader(true);
            return; // Odotetaan, että data on ladattu
        }

        try { 
            const findItem = personalTrainingPlan?.trainingplan.find((item) => item.workout_id === id)

            if(findItem){
                const cominedDays = findItem?.training_plan_content.map((item, index) => ({
                    ...item,
                    date_time: findItem?.training_plan_training_dates[index]?.date_time,
                }))
                setTrainingPlan(findItem);
                setDays(cominedDays);
                setDescription(findItem?.description);
                setname(findItem?.workout_name);
                setMedia(findItem?.file);
                setPublishValue({id: findItem?.workout_id, name: findItem?.workout_name, list: findItem?.training_plan_content, description: findItem?.description, media: findItem?.file, type: 'training_plan'});
                setIsPlanActive(DateNow() < new Date(findItem?.expiry_date));
                setIsLoader(false);
            }

        } catch (error) {
          console.error("Failed to parse sessionStorage data:", error);
        }
    }, [id, personalTrainingPlan]);

    useEffect(() => {
      const workoutCount = days.filter((day) => day.status === 'workout').length;
      const restCount = days.filter((day) => day.status === 'rest').length;

      setCount({workout: workoutCount, rest: restCount});
    },[days])

    useEffect(() => {
      if (todayRef.current) {
        todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, []);

    const closeModal = () => {
        setTimeout(() => {
          setIsModalOpen(false);
        }, 0);
        setIsPublished(true);
      };

    const addDays = (date, days) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days); // Lisää päivät
        return newDate;
    };

    const startPlan = async () => {
        setIsLoader(true);
        const now = DateNow();
        const list = trainingPlan?.training_plan_training_dates;

        list.forEach((item, index) => {
            const dateTime = addDays(now, index); // Lisää indeksin mukainen määrä päiviä
            item.date_time = dateTime.toISOString(); // Lisää `date_time`-kenttä objektiin
        });

       const result = await InsertTrainingPlansEvent(trainingPlan, setPersonalData);

       // kaikki ok -> navigoidaan kalenteriin.

       setIsPlanActive(DateNow() < new Date(result?.content));

       setPersonalTrainingPlan({
            type: 'UPDATE_TRAININGPLAN_FIELD',
            payload: {
                workout_id: trainingPlan?.workout_id, // Kohteen ID
                updates: { expiry_date: result?.content }, // Päivitä tämä kenttä
            },
        });

         setTrainingPlan({
             ...trainingPlan,
             training_plan_content: list
         });
        setIsLoader(false);
        navigate('/calendar')
    }



    const toDateString = (date) => {
        if(!date) return;
        const day = new Date(date).getDate();
        const month = new Date(date).getMonth() + 1;
        const year = new Date(date).getFullYear();

        return `${day}.${month}.${year}`
    }

    const DaySection = () => {
       return(
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '50%', marginTop: '10px', paddingBottom: 55}}>
                <Row justify={'space-between'} align={'start'}>
                        <p>{count.workout} {WordTranslations.training[lang]} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                        <p>{count.rest} {WordTranslations.rest[lang]} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                        <p>{WordTranslations.duration[lang]} {days?.length} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                </Row>
        <div style={{overflow: 'auto', maxHeight: '55vh'}}>
          {days.map((day, index) => {
            const isToday = new Date(day?.date_time).toDateString() === new Date().toDateString();
            const id = day?.exercises[0]?.part_of_workout_id;
            const part_name = day?.part_of_workout_name ? day?.part_of_workout_name : `day${index}`
            let values = day?.exercises;
            if (!values.some(value => value.part_of_workout_name === part_name)) {
                values = [{ part_of_workout_name: part_name }, ...values];
            }
            return (
            <div key={index} 
                 ref={isToday ? todayRef : null}
                 onClick={(event) => {
                    if (day.status === "rest") {
                        event.preventDefault(); // Estää navigoinnin
                        return;
                    }
                    navigate(`/workout/my/${id}`, { state: { name, values, id } });
                }}
                 style={{ border: `1px solid ${(isToday && isPlanActive) ? 'green' : theme.colors.border}`, borderRadius: 5, maxHeight:'90px', padding: '10px', margin: '2px 0px 2px 0px', background: (isToday && isPlanActive)  && (theme.colors.textBg), cursor: day.status !== 'rest' && ('pointer')}}>
                <Row padding={'0px 38px 10px 0px'}>
                    <h4>{WordTranslations.day.singular[lang]} {index + 1} - {day.status === 'rest' ? day.status : day?.part_of_workout_name}</h4>
                    {isPlanActive && (<p style={{fontSize: 10}}>{WordTranslations.training[lang]} {WordTranslations.day.plural[lang].toLowerCase()}: {toDateString(day?.date_time)}</p>)}
                </Row>
                <Column style={{overflow: 'auto', maxHeight: '50px'}}>
                    {days[index]?.exercises.length > 0 && (
                        days[index]?.exercises.map((item, i) => (
                            <Row key={i} padding={'0px 38px 0px 0px'}>
                                <p key={i} style={{fontSize: 12}}>{item?.name_of_exercise}</p>
                                <div>
                                    <span> {item?.times} x {item?.amount}</span>
                                </div>
                            </Row>
                        ))
                    )}
                </Column>
            </div>
        )})}
        </div>
        </div>
      )
    }

    const InfoSection = () => {

        return (
            <>
                {deviceScreenSize?.width < 601 && (
                    <Row justify={'flex-end'} align={'start'}>
                        <div></div>
                        <>
                        {infoOpen ? <IoIosArrowDown onClick={() => setInfoOpen(!infoOpen)}/> : <IoIosArrowBack onClick={() => setInfoOpen(!infoOpen)}/>}
                        </>
                    </Row>
                )}
            <Column width={'100%'} height={infoOpen && ('0px')} style={{overflow: 'hidden'}}>
            {trainingPlan?.accessright === 'owner' && (
                <Row margin={'0px 0px 20px 0px'} margin_mobile={'0px 0px 20px 0px'} justify={'flex-start'}>
                    <div style={{borderBottom: `1px solid ${theme.colors.border}`, marginRight: 10}}>
                        <CustomButton 
                            disabled={isPlanActive} 
                            button={StyledPublishButton} 
                            label={`${WordTranslations.start[lang]} ${WordTranslations.now[lang].toLowerCase()}`} 
                            onClick={() => startPlan()}
                            style={{maxWidth: 150, cursor: !isPlanActive && ('pointer')}}/>
                    </div>
                    <div style={{borderBottom: `1px solid ${theme.colors.border}`}}>
                        <CustomButton 
                            // disabled={isPlanActive} 
                            button={StyledPublishButton} 
                            label={isPublished ? `${WordTranslations.unpublish[lang]}` : `${WordTranslations.publish[lang]}`} 
                            onClick={() => setIsModalOpen(true)}
                            style={{cursor: 'pointer'}}/>
                    </div>
                </Row>
                )}
                <Row justify={'flex-start'} align={'start'}>
                    <WorkoutMedia src={media} type="image" width={'100px'} height={'100px'}/>
                    {/* <Column justify={'flex-'} height={'100px'} > */}
                        <h2 style={{marginLeft: 30}}>{name}</h2>
                    {/* </Column> */}
                </Row>
                <Row>
                    <Column height={'fit-content'} style={{margin: '20px 0px 20px 0px', maxHeight: 300, overflow: 'auto'}}>
                        <p>{WordTranslations.description[lang]}</p>
                        <p style={{fontSize: 14}}>{description}</p>
                    </Column>
                </Row>
            </Column>
            </>
        )
    }

    return (
    <Container>
        <Loading data={!isLoader} timeout={3000} theme={theme}>
            <div style={{height: '76vh', width: '100%'}}>
                <h1 style={{textAlign: 'center'}}>{NewWorkoutTranslations.training_plan[lang]}</h1>
                <StyledPlannerContainer>
                    <InfoSection />
                    <DaySection />
                </StyledPlannerContainer>
            </div>
        </Loading>
        <Modal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            children = { <PublishDetails data={publishValue} accessRole={trainingPlan?.accessRight} theme={theme} close={closeModal}/>
            }
          />
    </Container>
    
    );
}

export default MyTrainingPlan;