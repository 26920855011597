import React, {useState, useEffect, useCallback, useRef} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
//components
import { ScrollView, Modal } from "../../../components/Components.jsx";
import { useAuth, useData, useLanguage } from "../../../Utils/UseContext.jsx";
import MuscleMapFront from '../../../components/MuscleMapFront.jsx'
import MuscleMapBack from "../../../components/MuscleMapBack.jsx";
//import { SetBox } from "./NewWorkout.jsx";
//utils
import { Loading, OptimizeImage } from "../../../Utils/Utils.jsx";
//API
import { UpdateWorkout } from "../../../Utils/ApiRequests.jsx";
//Buttons
import { AddButton } from "../../../components/Buttons.jsx";
//icon
import { IoTrash } from "react-icons/io5";
import { TbRotate360} from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoMdClose } from "react-icons/io";


//Style
import { StyledWorkoutInput, StyledTextArea, LABEL, StyledBorderlesInput } from "../../../components/style/CustomInput.styled.jsx";
import { WorkoutInputContainer, WorkoutInputContent } from "../StyledComponents.jsx";
import {TopRightFloatContainer, BorderContainer,ButtonContainer, Container, DropzoneContainer, PreviewImage, PlaceholderText, RightBottomFloatingContainer, RightTopAbsoluteFloatingDiv, RowBox } from "../../../components/style/Container.styled.jsx";
import { CustomButton } from "../../../components/Buttons.jsx";
import { ColumnWrapper } from "../../../components/style/ColumnWrapper.styled.jsx";
import { StyledSubmitButton, StyledColorButton } from "../../../components/style/Buttons.styled.jsx";
import { Row, Column } from "../../../components/style/Row.styled.jsx";
import { RepsContainer } from "../../../components/style/exerciceCard.styled.jsx";

//Translations
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'

const Dropzone = ({index, image, setValues, placeholder}) => {
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState(null);

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
      
            // Check file size (e.g., limit to 5MB)
            const maxSize = 5 * 1024 * 1024;
            if (file.size > maxSize) {
              setError('File size exceeds the limit of 5MB');
              return;
            }

            const optimizedImage = await OptimizeImage(file);
            const previewUrl = URL.createObjectURL(file);
            setPreview(previewUrl);
            setError(null);
            setValues(prevValues => 
                prevValues.map((item, i) => 
                    i === index ? { ...item, media_url: previewUrl, file: optimizedImage } : item
                )
            );
          }
    }, [index, setValues]);

    useEffect(() => {
        // If the component is initialized with an existing image, set it as the preview
        if (image) {
            setPreview(image);
        }
    }, [image]);

  
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ 
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp']
            // 'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm']
        }
     });
  
    return (
      <div>
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
            {preview ? (
              <PreviewImage src={preview} alt="Preview" />
            ) : (
              <PlaceholderText>{isDragActive ? 'Drop the image here...' : placeholder}</PlaceholderText>
            )}
            {error && <p style={{ color: 'red', position: 'absolute', bottom: '10px' }}>{error}</p>}
        </DropzoneContainer>
      </div>
    );
  };

const SetBox = ({set, ind, index, updateSet, unit, option, exercise}) => {

    if(option === 'rep'){
        option = 'x'
    }

    if(unit === 'No unit'){
        unit = ""
    }

    
    return(
        <RowBox key={index}>
            <BorderContainer
                    maxWidth='30px'
                    >
                <StyledColorButton 
                    radius_top_left='10px' 
                    radius_top_right='10px'
                    onClick={() => updateSet(ind, index, "reps", Math.min(exercise.sets?.[ind]?.reps + 1, 999))}>
                    <p>+</p>
                </StyledColorButton>
                <StyledBorderlesInput 
                    fontSize='13px'
                    width={'100%'}
                    value={exercise.sets?.[ind]?.reps ?? 0}
                    onChange={(e) => {
                        let newValue = e.target.value;
                        if (/^\d*$/.test(newValue)) {
                          updateSet(ind, index, "reps", Math.min(Number(newValue), 999));
                        }
                      }}
                      onBlur={() => {
                        if (!exercise.sets?.[ind]?.reps) {
                          updateSet(ind, index, "reps", 0);
                        }
                      }}/>
                <StyledColorButton 
                    radius_bottom_left='10px' 
                    radius_bottom_right='10px'
                    onClick={() => updateSet(ind, index, "reps", Math.max(exercise.sets?.[ind]?.reps - 1, 0))}>
                    <p>-</p>
                </StyledColorButton>
            </BorderContainer>
            <span style={{margin: '0px 2px 0px 2px'}}>{option}</span>
            <BorderContainer
                    maxWidth='30px'
                    >
            <StyledColorButton 
                    radius_top_left='10px' 
                    radius_top_right='10px'
                    onClick={() => updateSet(ind, index, "weight", Math.min(exercise.sets?.[ind]?.weight + 1, 999))}>
                    <p>+</p>
                </StyledColorButton>
                <StyledBorderlesInput 
                    fontSize='13px'
                    width={'100%'}
                    value={exercise.sets?.[ind]?.weight ?? 0}
                    onChange={(e) => {
                        let newValue = e.target.value;
                        if (/^\d*$/.test(newValue)) {
                          updateSet(ind, index, "weight", Math.min(Number(newValue), 999));
                        }
                      }}
                      onBlur={() => {
                        if (!exercise.sets?.[ind]?.weight) {
                          updateSet(ind, index, "weight", 0);
                        }
                      }}/>
                <StyledColorButton 
                    radius_bottom_left='10px' 
                    radius_bottom_right='10px'
                    onClick={() => updateSet(ind, index, "weight", Math.max(exercise.sets?.[ind]?.weight - 1, 0))}>
                    <p>-</p>
                </StyledColorButton>
            </BorderContainer>
            {unit}
        </RowBox>
    )
}


const EditWorkout = ({theme}) => {
    const {language} = useLanguage();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']
    const {dispatch} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {data, workoutName, workoutId, index} = location.state || {};
    const {personalData ,setPersonalData} = useData();
    const [values, setValues] = useState([]);
    const [deletedItems, setDeletedItems] = useState([]);
    const [nameofpart, setNameofpart] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [setsObj, setSetsObj] = useState({weight: 0, reps: 3});
    const [isFrontMuscles, setIsFrontMuscles] = useState(true);
    const [musclesOpen, setIsMusclesOpen] = useState([]);


    const [unit] = useState([
        {value: 'kg', label: 'Kilo'},
        {value: 'lb', label: 'Pounds'},
        {value: null, label: 'No unit'}
    ]);
    const [options] = useState([
        { value: 'rep', label: `${WordTranslations.repetitions[lang]}` },
        { value: 'sek', label: `${WordTranslations.seconds[lang]}` },
        { value: 'min', label: `${WordTranslations.minutes[lang]}` },
        { value: 'h', label: `${WordTranslations.hours[lang]}` }]);

    const nameOfWorkoutPart = data?.part_of_workout_name || "";

    useEffect(() => {
        if(data){
            const value = data?.part_of_workout_exercises || [];
            const partName = data?.part_of_workout_name;
            //Object.values(data)[0];


            setValues(value);
            setNameofpart(partName);
        }
    },[data])

    useEffect(() => {
        const anyFieldEmpty = isAnyFieldEmpty(values);
        setIsSubmitDisabled(anyFieldEmpty);
      }, [values]);


    const navTo = (values, id) => {
        navigate(`/workout/my/${id}`,{state: {values, id}});
    }

    const handleOnBlur = (name, value, index) => {
        const sanitizedValue = value.replace(new RegExp(/[\\/]/, 'g'), '');
        setValues(prevValues => 
            prevValues.map((item, i) => 
                i === index ? { ...item, [name]: sanitizedValue } : item
            )
        );
    }

    const handleCancel = () => {
        navigate('/workout/my')
    }

    const handleSubmit = async (valueToDb) => {
        setIsLoading(true)
        const partId = data?.part_of_workout_id;
        const obj = {newworkout: valueToDb, partName: nameofpart, partId: partId, workoutId: workoutId, deletedItems: deletedItems}
        const formData = new FormData();
        formData.append('data', JSON.stringify(obj));
        valueToDb.map((item, index) => {
            
            if (item.file) {
                formData.append(`media_${index}`,item.file);
            } else if (item.media_url) {
                // Jos tiedostoa ei ole päivitetty, lähetä vanha URL
                formData.append(`media_${index}`, item.media_url);
            }
        })

        const resp = await UpdateWorkout(formData, setPersonalData, personalData, navTo)
        setIsLoading(resp?.isLoading)
    }

    const handleOnPartChange = (value, name, index) => {
        const sanitizedValue = value.replace(new RegExp(/[\\/]/, 'g'), '');
        setNameofpart(sanitizedValue);
        // setValues(prevValues => 
        //      prevValues.map((item, i) => (
        //          i === index ? { ...item, [name]: sanitizedValue } : item
        //      )
        //      )
        //  );
    } 

    const handleChange = async (e, index) => {
        const value = e.target.value;
        const name = e.target.name;

        if(name === 'option'){
            setValues(prevValues => 
                prevValues.map((item, i) => (
                    i === index ? { ...item, [name]: value } : item
                )
                ))
        }else if(name === 'unit'){
            setValues(prevValues => 
                prevValues.map((item, i) => (
                    i === index ? { ...item, [name]: value } : item
                )
                ))
        }
    };

    const updateSet = (ind, index, key, value) => {
        setValues((prev) => 
            prev.map((item, i) => 
                i === index ? {
                    ...item, 
                    sets: item.sets.map((set, setIndex) => 
                        setIndex === ind 
                    ? {...set, [key]: value} : set)} : item
            )
         );
    };

    const dropdown = (options, name, value, onChange, index) => {
    
        return(
            <select name={name} style={{background: 'transparent', border: 'none', height: '3em'}} value={value} onChange={(e) => onChange(e, index)} >
                {options.map((option, index) => (
                  <option  key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
        )
    }

    const addEmptyItem = () => {
        const emptyItem = {
                name_of_exercise:"",
                description:"",
                media_url:"",
                option:"rep",
                unit:"kg",
                muscles:{front:[], back:[]},
                sets:[]
        }

        setValues([...values, emptyItem])
    }

    const removeItem = (indexToRemove) => {
        const newValues = values.filter((item, index) => index !== indexToRemove);
        const deletedItemId = values[indexToRemove]?.exercise_id; // Ota ID talteen

        setValues(newValues); // Päivitetään tila ilman poistettua kohdetta
        if (deletedItemId) {
            setDeletedItems([...deletedItems, deletedItemId]); // Lisää ID poistettavien listaan
        }
    };

    const removeImage = (indexToRemove) => {

        // jos item löytyy päivitetään media_url = null
        setValues(prevValues => 
            prevValues.map((item, index) => 
                index === indexToRemove 
                    ? { ...item, media_url: null }  // Päivitetään `media_url` nulliksi vain valitulle itemille
                    : item // Muut itemit säilyvät muuttumattomina
            )
        );

    };

    const isAnyFieldEmpty = (items) => {
        return items.slice(1).some(item => 
            !item.name_of_exercise || item.name_of_exercise.trim() === ""
        );
      }

     const Content = ({item, index}) => {
        const [text, setText] = useState("")
        const [focused, setFocused] = useState({"": false})
        const [setsLeng, setSetsLeng] = useState(item?.sets.length || 0);
        
        const muscleFront = item?.muscles?.front.length > 0;
        const muscleBack = item?.muscles?.back.length > 0;

        const frontMuscleRef = useRef(null);
        const backMuscleRef = useRef(null);

        const handleMuscleBackSelection = (muscleIndex) => {
            setValues((prev) => 
                prev.map((exercise, idx) => {
                    if(idx === index){
                        const currentMuscles = exercise?.muscles?.back || [];
                        const updatedMuscles = currentMuscles.includes(muscleIndex)
                            ? currentMuscles.filter((i) => i !== muscleIndex) // Poistetaan lihas
                            : [...currentMuscles, muscleIndex];  
                            
                            return {
                                ...exercise,
                                muscles: {
                                    ...exercise.muscles,
                                    back: updatedMuscles,
                                },
                            };
                    }
                    return exercise;
                })
            )
           };
    
          const handleMuscleFrontSelection = (muscleIndex) => {
            setValues((prev) => 
                prev.map((exercise, idx) => {
                    if(idx === index){
                        const currentMuscles = exercise?.muscles?.front || [];
                        const updatedMuscles = currentMuscles.includes(muscleIndex)
                            ? currentMuscles.filter((i) => i !== muscleIndex) // Poistetaan lihas
                            : [...currentMuscles, muscleIndex];  
    
                            return {
                                ...exercise,
                                muscles: {
                                    ...exercise.muscles,
                                    front: updatedMuscles,
                                },
                            };
                    }
                    return exercise;
                })
            )
          };

        const handleOnFocus = (value, key) => {
            setFocused({[key]: true});
            setText(value);
          }

        const handleOnChange = (value) => {
            setText(value)
        }

        const updateSetsLength = (newLength) => {
            setValues((prev) =>
              prev.map((item, idx) => {
                if (idx === index) {
                  let newList = [...(item.sets || [])];
          
                  if (newLength > newList.length) {
                    for (let i = newList.length; i < newLength; i++) {
                      newList.push(setsObj);
                    }
                  } else if (newLength < newList.length) {
                    newList = newList.slice(0, newLength);
                  }
          
                  return { ...item, sets: newList };
                }
                return item;
              })
            );
            setSetsLeng(newLength);
          };
          

          const toggleMuscleView = () => {
            setIsFrontMuscles((prev) => !prev);
        };

        const toggleMuscleOpen = () => {
            setIsMusclesOpen((prev) => 
                prev.includes(index) 
                    ? prev.filter(i => i !== index) // Poistetaan, jos löytyy
                    : [...prev, index]              // Lisätään, jos ei löydy
            );
        }

        return (
                     <WorkoutInputContent align={'flex-start'} position={'relative'} border={'solid'} padding={'15px'}>
                        <RightTopAbsoluteFloatingDiv>
                            <Row justify='center' width='fit-content' onClick={() => removeItem(index)} style={{cursor: 'pointer'}}>
                                <IoTrash size={20} style={{cursor: 'pointer'}}/>
                                <p style={{cursor: 'pointer'}}>{WordTranslations.delete[lang]} {WordTranslations.exercise.singular[lang]}</p>
                            </Row>
                        </RightTopAbsoluteFloatingDiv>
                        <LABEL>{WordTranslations.name[lang]}</LABEL>
                         <StyledWorkoutInput
                             name="name_of_exercise"
                             type="text"
                             value={focused["name_of_exercise"] ? text : item?.name_of_exercise}
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                             onChange={(e) => handleOnChange(e.target.value)}
                             width={'100%'}
                         />
                        <Column width='100%' align='center' padding='0px 10px 0px 10px' margin='10px 0px 10px 0px' style={{border: `solid ${theme.colors.border} 1px`, borderRadius: 6, overflow: 'hidden'}}>
                            <Row>
                                {dropdown(options, 'option', item.option, handleChange, index)}
                                {dropdown(unit, 'unit', item.unit, handleChange, index)}
                            </Row>
                            <Row justify='flex-start' justify_mobile='flex-start' padding='0px'>
                                <p>{'Sarjat'}</p>
                            </Row>
                            <BorderContainer padding='5px' margin="0px 0px 10px 0px" width='100%'>
                                <Row justify='flex-start' justify_mobile='flex-start' align='end' padding='0px'>
                                    <BorderContainer radius={'10px'} maxHeight={'90px'} maxWidth={'fit-content'}>
                                        <Column align={'center'}>
                                            <StyledColorButton 
                                                radius_top_left='10px' 
                                                radius_top_right='10px'
                                                onClick={() => updateSetsLength( Math.min(setsLeng + 1, 999))}>
                                                <h2>+</h2>
                                            </StyledColorButton>
                                            <StyledBorderlesInput 
                                                width={'fit-content'}
                                                value={setsLeng}
                                                onChange={(e) => {
                                                    let newValue = e.target.value;
                                                    if (/^\d*$/.test(newValue)) {  // Tarkistaa, että syöte sisältää vain numeroita
                                                      let numericValue = Number(newValue);
                                                      if (numericValue > 999) numericValue = 999;  // Jos syöte on yli 999, asetetaan 999
                                                      if (numericValue < 0) numericValue = 0;      // Jos syöte on alle 0, asetetaan 0
                                                      updateSetsLength(numericValue);
                                                    }
                                                  }}
                                                  onBlur={() => {
                                                    if (setsLeng === '') {
                                                       updateSetsLength(0);  // Jos kenttä jää tyhjäksi, asetetaan 0
                                                    }
                                                  }}/>
                                            <StyledColorButton 
                                                radius_bottom_left='10px' 
                                                radius_bottom_right='10px'
                                                onClick={() => updateSetsLength(Math.max(setsLeng - 1, 0))}>
                                                <h2>-</h2>
                                            </StyledColorButton>
                                        </Column>
                                    </BorderContainer>
                                        <RepsContainer direction={'row'} margin={'0px 0px 0px 30px'} padding='0px'>
                                            {item?.sets.map((set, ind) => (
                                                <SetBox set={set} ind={ind} index={index} updateSet={updateSet} unit={item?.unit} option={item?.option} exercise={item}/>
                                            ))}
                                        </RepsContainer>
                                </Row>
                            </BorderContainer>
                        </Column>
                        <Column width='100%' align='center' margin='0px 0px 10px 0px' style={{border: `solid ${theme.colors.border} 1px`, borderRadius: 6, overflow: 'hidden'}}>
                            <Row>
                                <p>Muscles</p>
                                {musclesOpen.includes(index) ?
                                    <IoIosArrowDown size={20} onClick={toggleMuscleOpen}/>
                                   :
                                    <IoIosArrowBack size={20} onClick={toggleMuscleOpen}/>
                                }
                            </Row>
                            <Column width='100%' align='center' height={!musclesOpen.includes(index) && ('0px')}>
                                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', paddingRight: 15, paddingTop: 15}}>
                                    <TbRotate360 
                                            size={25} 
                                            color={theme.colors.text}
                                            onClick={toggleMuscleView}/>
                                </div>
                                {isFrontMuscles ? 
                                    <MuscleMapFront ref={frontMuscleRef} width={200} height={375} onSelectionChange={handleMuscleFrontSelection} activeMuscles={item.muscles.front}/>
                                :
                                    <MuscleMapBack ref={backMuscleRef} width={200} height={375} onSelectionChange={handleMuscleBackSelection} activeMuscles={item.muscles.back}/>
                                }
                            </Column>
                        </Column>

                         <LABEL>{WordTranslations.description[lang]}</LABEL>
                         <StyledTextArea
                             name="description"
                             type="text"
                             value={focused['description'] ? text : item.description}
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                             onChange={(e) => handleOnChange(e.target.value)}
                             width={'100%'}
                         />
                         <Row padding={'0'}>
                            <Dropzone 
                                index={index} 
                                image={item?.media_url} 
                                setValues={setValues}
                                placeholder={NewWorkoutTranslations.upload_image[lang]}/>
                            <Row justify='center' width='fit-content' onClick={() => removeImage(index)}>
                                <IoTrash  size={20} style={{cursor: 'pointer'}}/>
                                <p style={{cursor: 'pointer'}}>{WordTranslations.delete[lang]} {WordTranslations.image[lang]}</p>
                            </Row>
                         </Row>
                     </WorkoutInputContent>
        )
     }

     const closeModal = () => {
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      };

    return(
    <Container>
        <Loading data={!isLoading} timeout={3000} theme={theme}>
        <ColumnWrapper>
                <StyledWorkoutInput
                    name="part_of_workout_name"
                    type="text"
                    value={nameofpart}
                    onChange={(e) => handleOnPartChange(e.target.value, e.target.name, 0)}
                />
                <ScrollView items={values} renderItem={(item, index) => 
                    item.part_of_workout_name ? null : (
                        <Content key={index} item={item} index={index}/>
                    )
                }/>
            <ButtonContainer>
                <CustomButton label={WordTranslations.cancel[lang]} onClick={() => handleCancel()} button={StyledSubmitButton}/>
                <CustomButton disabled={isSubmitDisabled} label={WordTranslations.submit[lang]} onClick={() => handleSubmit(values)} button={StyledSubmitButton}/>
            </ButtonContainer>
        </ColumnWrapper>
        <RightBottomFloatingContainer>
            <AddButton onClick={addEmptyItem}/>
        </RightBottomFloatingContainer>
        </Loading>
    </Container>
    )
}

export default EditWorkout;