import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderContent, StyledHeader, HeaderStyled } from "./style/Header.styled.jsx";
import { useTimer, useAuth, useLanguage } from "../Utils/UseContext.jsx";
import Logo from './style/images/Logo.png'
import { MdArrowBackIos } from "react-icons/md";
import { LoginIcon, LogoutIcon } from "./AuthIcon.jsx"; 
import { ProfileIcon } from "./Nav.jsx";
import { CheckServer, GetPublicData, HandleLogout } from "../Utils/ApiRequests.jsx";
import LanguageChanger from "./LanguageChanger.jsx";
import { BurgerMenu } from "./style/nav.style.jsx";
import { HeaderStyle } from "./style/MainGrid.styled.jsx";
//icons
import { MdOutlineCircleNotifications, MdOutlineNotificationsActive } from "react-icons/md";
import { useData, useWebSocket } from "../Utils/UseContext.jsx"; 
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
//Translate
import WordTranslations from '../Main/Translations/WordTranslation.json'



const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {language} = useLanguage();
    const {state, dispatch} = useAuth();
    const {isTimerRunning} = useTimer();
    const [isup, setisup] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isShaking, setIsShaking] = useState(false);
    const {notifications, setNotifications} = useData();
    const ws = useWebSocket();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    //Laitetaan tila joka muuttuessa näyttää määrän

    useEffect(() => {
        if(state?.isAuthenticated && ws){
            GetPublicData({setNotifications, ws});
        }
    },[ws])


    useEffect(() => {
        const interval = setInterval(() => {
          setIsShaking(true);
    
          setTimeout(() => {
            setIsShaking(false);
          }, 2400); // Heiluu 2 kertaa ja pysähtyy (täsmää animaation kestoon)
          
        }, 5000); // Heiluminen tapahtuu 10 sekunnin välein
    
        return () => clearInterval(interval);
      }, []);

    const navTo = () => {
        if(!isTimerRunning){
            navigate('/');
        }
    }

    const back = () => {
        navigate(-1);
    }

    const isUp = async () => {
        return await CheckServer();
    }

    isUp().then(serverStatus => {
        setisup(serverStatus)
    });

    const handleLogout = async () => {
        await HandleLogout(dispatch)
    }
    


    return(
        <HeaderStyle>
            <HeaderContent justify={'flex-start'}>
                <MdArrowBackIos onClick={() => back()} size={20}/>
                <div style={{width: '5px', height: '5px', background: !isup ? 'red' :'transparent', borderRadius: '50%'}}></div>
                {(state?.isAuthenticated && location.pathname !== '/notifications') && ((notifications?.notifications?.length <= 0 || !notifications?.notifications) 
                    ? <MdOutlineCircleNotifications size={20} /> 
                    : <MdOutlineNotificationsActive onClick={() => navigate('/notifications')} size={20} className={`notification-icon ${isShaking ? "shake" : ""}`}/>)}

            </HeaderContent>
            <HeaderContent>
                <img onClick={() => navTo()} style={{height:'2.5em', cursor:'pointer'}}src={Logo} alt="Logo" />
            </HeaderContent>
            <HeaderContent justify={'flex-end'}>
                {/* {!state?.isAuthenticated && (<LanguageChanger />)} */}
                {/* {location.pathname === '/profile'? <LogoutIcon onClick={handleLogout} /> : <ProfileIcon />} */}
                {/* <LoginIcon lang={lang}/> */}
            </HeaderContent>
        </HeaderStyle>
    )
}

export default Header;