import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarController, LineController } from 'chart.js';
//utils
import { FilterEventsByDateRange, DateNow, Datepicker } from '../../Utils/Utils';
// input
import { StyledCustomInput } from '../../components/style/CustomInput.styled';
//style
import { Row } from '../../components/style/Row.styled';
import {Content20Round} from '../../components/style/Container.styled';
// icons
import { CiCalendarDate } from "react-icons/ci";
//components
import Dropdown from '../../components/Dropdown';


// Rekisteröi Chart.js komponentit
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.register(BarController, LineController, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const TrainingGraph = ({ data, range, setRange, theme }) => {
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [viewMode, setViewMode] = useState('day'); // 'day', 'week', 'month'

  useEffect(() => {
    setSelectedExercises(exercises)
  },[data])

  const handleExerciseChange = (exercise) => {
    setSelectedExercises(prev => 
      prev.includes(exercise) ? prev.filter(item => item !== exercise) : [...prev, exercise]
    );
  };

  const exercises = Array.from(new Set(data.map(item => item.part_of_workout_name)));
  const filteredData = FilterEventsByDateRange(data, {startOfWeek: range.startDate, endOfWeek: range.endDate});
  const labels = getLabels(filteredData, viewMode);

    // Data for bar chart (number of workouts)
    const barData = labels.map(label => {
      return selectedExercises.reduce((total, exercise) => {
        const exerciseData = filteredData.filter(item => item.part_of_workout_name === exercise);
        return total + exerciseData.filter(item => matchesViewMode(item, label, viewMode)).length;
      }, 0);
    });

      // Data for line chart (duration of workouts)
  const lineData = labels.map(label => {
    return selectedExercises.reduce((totalDuration, exercise) => {
      const exerciseData = filteredData.filter(item => item.part_of_workout_name === exercise);
     
      const durationSum = exerciseData
        .filter(item => matchesViewMode(item, label, viewMode))
        .reduce((sum, item) => sum + item.duration, 0);
      return totalDuration + (durationSum / (60 * 1000));
    }, 0);
  });

  // LINE DATASET
  // const chartData = {
  //   labels: labels,
  //   datasets: selectedExercises.map(exercise => {
  //     const exerciseData = filteredData.filter(item => item.part_of_workout_name === exercise);
  //     const groupedData = groupDataByViewMode(exerciseData, labels, viewMode);
  //     return {
  //       label: exercise,
  //       data: groupedData,
  //       fill: false,
  //       borderColor: getRandomColor(),
  //       backgroundColor: getRandomColor(),
  //     };
  //   }),
  // };

  const chartData = {
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: 'Workouts',
        data: barData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        yAxisID: 'y-axis-1',
      },
      {
        type: 'line',
        label: 'Duration (min)',
        data: lineData,
        fill: false,
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        yAxisID: 'y-axis-2',
      },
    ],
  };

  // LINE OPTIONS
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       text: `${range.startDate.toLocaleDateString()} - ${range.endDate.toLocaleDateString()}`,
  //     },
  //   },
  // };

  const options = {
    responsive: true,
    // maintainAspectRatio: false,
    scales: {
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Number of Workouts',
        },
        ticks: {
          callback: function(value) {
            return Math.round(value); // Pyöristää arvon kokonaisluvuksi
          },
          stepSize: 1, // Pakottaa kokonaisluvut
        },
        beginAtZero: true // Varmistaa, että akseli alkaa nollasta
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Duration of Workouts (min)',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        // display: false,
      },
      title: {
        display: true,
        text: `Training data from ${range.startDate.toLocaleDateString()} to ${range.endDate.toLocaleDateString()}`,
      },
    },
  };

  const handleRangeChange = (start, end) => {
    if(viewMode === "month"){
      // setRange({ startDate: new Date(), endDate: new Date(end) });

    }

     setRange(prevRange => ({ 
       startDate: start && start !== prevRange.startDate.toISOString().split('T')[0]  ? new Date(start) : prevRange.startDate, 
       endDate: end && end !== prevRange.endDate.toISOString().split('T')[0] ? new Date(end) : prevRange.endDate
     }));
  };

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
  };

  return (
    <div>
      <Row style={{
        display: "flex",
        flexWrap: "wrap", // Elementit menevät uudelle riville jos tila ei riitä
        gap: "10px", // Vähän tilaa elementtien väliin
        alignItems: "center"
      }}>
        <Row padding={'0px'} style={{ display: "flex", gap: "0px", flexWrap: "wrap", order: 3 }}>
        <label style={{margin:0}}>
          Start Date:
          <Content20Round 
            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            minWidth="120px"
            width="fit-content"
            padding="2px 5px"
            margin="0px"
            margin_mobile={'0px'}>
            <Datepicker 
              handleDateChange={e => handleRangeChange(e, range.endDate)} 
              selected={range?.startDate}
              maxDate={range?.endDate} 
              theme={theme}
              style={{width: 10}}/>
            <CiCalendarDate />
          </Content20Round>
          </label>

          {/* <StyledCustomInput 
            type="date" 
            onChange={e => handleRangeChange(e.target.value, range.endDate)} 
            //onBlur={e => handleRangeChange(e.target.value, range.endDate)} 
            value={range.startDate.toISOString().split('T')[0]} 
          /> */}
        <label style={{marginLeft:0 }}>
          End Date:
          <Content20Round 
            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            minWidth="120px"
            width="fit-content"
            padding="2px 5px"
            margin="0px"
            margin_mobile={'0px'}>
            <Datepicker 
              handleDateChange={e => handleRangeChange(range.startDate, e)} 
              selected={range?.endDate}
              minDate={range?.startDate}
              maxDate={DateNow()} 
              theme={theme}
              style={{width: 10}}/>
            <CiCalendarDate />
          </Content20Round>
          {/* <StyledCustomInput 
            type="date" 
            onChange={e => handleRangeChange(range.startDate, e.target.value)} 
            value={range.endDate.toISOString().split('T')[0]} 
          /> */}
        </label>
        </Row>

      {/* </Row> */}
      <div style={{ order: 2 }}>
      <label style={{display: 'flex', flexDirection: 'column'}}>
          View:
        </label>
          <select value={viewMode} onChange={handleViewModeChange} style={{background: 'transparent', border: `1px solid ${theme.colors.border}`, borderRadius: 20, padding: '2px 5px'}}>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </select>
      </div>
      <div style={{ order: 1 }}>
        <p>Exercises</p>
        <Dropdown data={exercises} selected={selectedExercises} handleChange={handleExerciseChange} theme={theme}/>
      </div>
      </Row>
      {/* <Line data={chartData} options={options} /> */}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export const WeightGraph = ({data}) => {
  const exercises = Array.from(new Set(data.map(item => item.exercise_array)));
}

// Apufunktio satunnaisen värin luomiseen
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Funktio labelsien saamiseksi viewModen mukaan
const getLabels = (data, viewMode) => {
  switch (viewMode) {
    case 'day':
      return Array.from(new Set(data.map(item => moment(item.date).format('YYYY-MM-DD')))).sort();
    case 'week':
      return Array.from(new Set(data.map(item => moment(item.date).isoWeek()))).sort((a, b) => a - b);
    case 'month':
    default:
      return Array.from(new Set(data.map(item => moment(item.date).format('YYYY-MM')))).sort();
  }
};

// Funktio datan ryhmittämiseen viewModen mukaan
const groupDataByViewMode = (data, labels, viewMode) => {
  return labels.map(label => {
    switch (viewMode) {
      case 'day':
        return data.filter(item => moment(item.date).format('YYYY-MM-DD') === label).length;
      case 'week':
        return data.filter(item => moment(item.date).isoWeek() === label).length;
      case 'month':
      default:
        return data.filter(item => moment(item.date).format('YYYY-MM') === label).length;
    }
  });
};

// Funktio tarkistamaan onko item valitussa viewModessa
const matchesViewMode = (item, label, viewMode) => {
  switch (viewMode) {
    case 'day':
      return moment(item.date).format('YYYY-MM-DD') === label;
    case 'week':
      return moment(item.date).isoWeek() === label;
    case 'month':
    default:
      return moment(item.date).format('YYYY-MM') === label;
  }
};


