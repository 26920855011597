import styled from 'styled-components';

export const FooterStyled = styled.footer`

`

export const StyledFooter = styled.footer`
    background-color: rgba(140, 140, 140, 0.25);
    color: #fff;
    padding: 20px 0;
    scroll-snap-align: end;
    padding-bottom: 60px;

    & > p {
        margin-left:10px;
        font-size:10px
    }

    @media screen and (min-width: 600px) {
        & > p {
            margin-left:120px;
            
        }
    }
`

export const StyledFooterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 500px) {
        flex-direction: row;
        align-items: center;
    }
`

export const StyledFooterSection = styled.div`
    flex: 1;
    max-width: fit-content;
    margin: 10px;
    text-align: center;
    color: ${({theme}) => theme.colors.text};


    h4{
        margin-bottom: 10px;
    }

    p{
        cursor: pointer;
    }
    @media screen and (min-width: 500px) {
        text-align: start;
    }
`