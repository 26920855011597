import React from "react";
import { useNavigate} from 'react-router-dom';

//style
import { StyledFooter, StyledFooterContainer, StyledFooterSection } from "./style/Footer.styled";
import { Row } from "./style/Row.styled";
import { FooterStyle } from "./style/MainGrid.styled";
//Icons
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Footer = ({onInstallClick, language}) => {
    const navigate = useNavigate();
    const canDowload = navigator.userAgent.toLowerCase().includes('chrome');
    
    return(
        <FooterStyle>
            <StyledFooterContainer>
                <StyledFooterSection>
                    <h4>About</h4>
                    <p style={styles.inActive}>Blog</p>
                    <p onClick={() => navigate('/termofservice')}>Terms of service</p>
                </StyledFooterSection>
                <StyledFooterSection>
                    <h4>Contact</h4>
                    <p onClick={() => navigate('/contact')}>Contact</p>
                    <p style={styles.inActive}>Jobs</p>
                </StyledFooterSection>
                <StyledFooterSection>
                    <h4 style={!canDowload ? styles.inActive : {cursor:'pointer'}} onClick={onInstallClick}>Download app</h4>
                    <Row padding={'0'}>
                        <FaSquareXTwitter size={25}/>
                        {/* <FaFacebookSquare size={25}/> */}
                        {/* <FaInstagramSquare size={25}/> */}
                        <FaLinkedin size={25}/>
                    </Row>
                </StyledFooterSection>
            </StyledFooterContainer>
            <p>
                &copy; 2024 <span style={{display:'none'}}>Rasmus Granholm</span> NosVitale.
                {language === 'fi-FI' ? " Kaikki oikeudet pidätetään" : " All rights reserved"}
            </p>
        </FooterStyle>
    )
}

const styles = {
    inActive:{
        color: "rgba(147, 147, 147, 0.431)",
        cursor: "none"
    }
}

export default Footer;