import React, { useDebugValue, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

// Components
import { Dropzone } from "../Modify_workout/NewWorkout.jsx";
import { CustomPopUp, CustomCheckBox, DynamicInput } from "../../../components/Components.jsx";
//utils
import { TextMinLenCount } from "../../../Utils/Utils.jsx";
// Style
import { Container, DescriptionContainer, ButtonContainer, PublishContainer } from "../../../components/style/Container.styled";
import { Row, Column, RowColumn } from "../../../components/style/Row.styled";
import { StyledSubmitButton, StyledAnalyticsButton } from "../../../components/style/Buttons.styled.jsx";
import { StyledTextArea, StyledCustomInput } from "../../../components/style/CustomInput.styled";
//Icon
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { Trash } from "../../../components/style/icons.jsx";
//button
import { CustomButton, PublishButton } from "../../../components/Buttons.jsx";
//API
import { PublishExercise } from "../../../Utils/ApiRequests.jsx";
import { useLanguage } from "../../../Utils/UseContext.jsx";
//Translate
import WordTranslations from '../../../Main/Translations/WordTranslation.json'
import PublicTranslations from '../../../Main/Translations/Public.json'


const PublishDetails = ({data, accessRole, theme, close}) => {
    const {language} = useLanguage();
    const [newData, setNewData] = useState([]);
    const [partnames, setPartnames] = useState([]);
    const [expires, setExpires] = useState();
    const [price, setPrice] = useState();
    const [content, setContent] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [termReaded, setTermReaded] = useState(false);
    const [showPart, setShowPart] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [isUnlimited, setIsUnlimited] = useState(false);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [trainingplanCount, setTrainingplanCount] = useState({workout: 0, rest: 0});

    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']
    const currency = "eur";
    const minDescLen = 50;


    useEffect(() => {
        data.description = "";
        data.media = null;
        setNewData(data);
        if(data?.content_type === 'training_plan'){
            const countRest = data?.list.filter(item => item?.status === 'rest').length;
            const countWorkout = data?.list.filter(item => item?.status === 'workout').length;
            const uniquePartNames = [
                ...new Set(
                  data?.list
                    .filter(item => item?.status === 'workout') // Suodata vain 'workout'-statuksella olevat
                    .map(item => item.part_of_workout_name) // Poimi 'part_of_workout_name'-arvot
                    .filter(name => name) // Poista mahdolliset undefined tai null arvot
                ),
              ];
            setPartnames(uniquePartNames);
            setTrainingplanCount({workout: countWorkout, rest: countRest});
            setExpires(countRest + countWorkout);
            setIsUnlimited(true);
        }
    },[data])

    // jatkossa nämä tulee json/db jota voi muokata
    const description = `${PublicTranslations.terms_message[lang]}`

    const ConfirmationPopUp = (formData) => {
      const buttons = [
        // { label: 'Ei', onClick: handleCancel, color: Colors.red},
        { label: 'Yes', onClick: async () => await handleConfirm(formData)},
        { label: 'No', onClick: () => setIsPopUpOpen(false)}
      ];

      const message =`${PublicTranslations.confirmation_message[lang].split(":")[0]} ${data.workout_name} ${PublicTranslations.confirmation_message[lang].split(":")[1]} ${price}${returnCurrency(currency)} \n` + 
                        `${expires > 0 ? WordTranslations.expires[lang] + " " + expires + " " + WordTranslations.day.ss[lang]: PublicTranslations.confirmation_message[lang].split(":")[2]}`

      setContent({message: message, buttons: buttons});
      setIsPopUpOpen(!isPopUpOpen);
    }

    const ShowTerms = () => {
        setShowInfo(!showInfo);
        setTermReaded(true);
    }

    const handleOnPublish = async () => {

        const checkLen = TextMinLenCount(newData?.description, minDescLen);
        const metaData = {workoutDays: trainingplanCount.workout, restDays: trainingplanCount.rest}

        if(!checkLen){
            toast.info("Please check the description.");
            return;
        }

        if(!newData?.file){
            toast.info("Please check the image.");
            return;
        }

        if(price < 5 && !isFree){
            setPrice(5);
            toast.info("The lower limit of the price is €5 or free");
            return;
        }

        if(expires < 14 && !isUnlimited){
            setExpires(14);
            toast.info("The minimum validity period is 14 days or unlimited");
            return;
        }

        const dataObj = {id: newData.workout_id, description: newData?.description, price:price, expires:expires, showPart:showPart, partnames:partnames, currency: currency, type: data?.content_type, metaData: metaData};
        const formData = new FormData();
        formData.append('data', JSON.stringify(dataObj)); // Lisätään muu data
        formData.append(`media`,newData.file);
        // custom popup
        ConfirmationPopUp(formData)

        // const visible = await GetWorkoutVisibility(id);
        // setVisibility(visible)

    }

    const handleConfirm = async (formData) => {

        setLoading(true);
        setIsPopUpOpen(false);


        await PublishExercise(formData);

        setLoading(false);
        toast.success("Published");
        close();
    }

    const returnCurrency = (currency) => {
        switch (currency) {
            case 'eur':
                return '€';
            case 'usd':
                return '$'
            default:
                return '€';
        }
    }

    const handleSetPrice = () => {

        const updatedPrice = price < 5 ? 5 : price;
        
        if (price < 5) {
          toast.info("The lower limit of the price is €5 or free");
        }
      
        setPrice(updatedPrice);
      };

    const handleSetExpirity = () => {

        const updatedExpirity = expires < 14 ? 14 : expires;
        
        if (expires < 5) {
          toast.info("The minimum validity period is 14 days or unlimited");
        }
        
        setExpires(updatedExpirity);
      };
    
    const handleCheckFree = (value) => {

        setIsFree(value)

        if(value){
            setPrice(0);
        }
    }

    const handleCheckUnlimited = (value) => {

        setIsUnlimited(value)

        if(value){
            setExpires(0);
        }
    }

    return(
        <Container background={theme.colors.body}>
            {!loading ?
            <PublishContainer>
            <Row>
                <Column>
                    <Row margin={'0 0 30px 0'} padding={'0'}>
                        <h3 style={{marginRight: 10}}>{data.name}</h3>
                        <IoInformationCircleOutline onClick={() => setShowInfo(!showInfo)}size={30} color={theme.colors.text}/>
                    </Row>
                    <Dropzone 
                        roleCheck={'beginner'}
                        content={newData}
                        setContent={setNewData}
                        placeholder={`${WordTranslations.image[lang]}*`}
                        theme={theme}
                        accept={{
                            'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                        }}/>
                </Column>
                <Column justify={'end'}>
                    <Row width={'fit-content'}>{WordTranslations.free[lang]}
                        <CustomCheckBox
                            isChecked={isFree}
                            onChange={isFree => handleCheckFree(isFree)}
                        />
                    </Row>
                    <Row width={'fit-content'}>
                        <p>{`${WordTranslations.price[lang]}`}</p>
                        <DynamicInput
                            placeholder="10"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            onBlur={handleSetPrice}
                            maxLength={5}
                            border={'none'}
                            text_decoration={'underline'} 
                            text_align={'center'} 
                            margin={'0'}
                            disabled={isFree}
                        />
                        {/* <StyledCustomInput
                            placeholder="10"
                            value={price} 
                            onChange={(e) => setPrice(e.target.value)}
                            onBlur={handleSetPrice}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            width={30}
                            margin={'0'}
                            disabled={isFree}/> */}
                        <p>{returnCurrency(currency)}</p>
                    </Row>
                    {data?.type !== 'training_plan' && (
                    <Row width={'fit-content'} >{WordTranslations.unlimited[lang]}
                        <CustomCheckBox
                            isChecked={isUnlimited}
                            onChange={isUnlimited => handleCheckUnlimited(isUnlimited)}
                        />
                    </Row>
                    )}
                    <Row width={'100%'}>
                        <p>{`${WordTranslations.expires[lang]}`}</p>
                        <DynamicInput
                            placeholder="30"
                            value={expires}
                            onChange={(e) => setExpires(e.target.value)}
                            onBlur={handleSetExpirity}
                            maxLength={5}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            margin={'0'}
                            disabled={isUnlimited}
                        />
                        {/* <StyledCustomInput 
                            placeholder="30"
                            value={expires}
                            onChange={(e) => setExpires(e.target.value)}
                            onBlur={handleSetExpirity}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            width={30}
                            margin={'0'}
                            disabled={isUnlimited}/> */}
                        <p>{`${WordTranslations.day.ss[lang].toLowerCase()}`}</p>
                    </Row>
                    {!data?.type &&(
                    <Row margin={'8px 0 0 0'}>
                        <p>{data?.parts.length} {data?.parts.length > 1 ? `${WordTranslations.exercise.plural[lang]}` : `${WordTranslations.exercise.singular[lang]}`}</p>
                    </Row>
                )}
                </Column>
            </Row>
            <Row>
                <Column width={'100%'}>
                    <h4 style={{marginBottom: 10}}>{WordTranslations.workout[lang]} {WordTranslations.content[lang].toLowerCase()}</h4>
                    <RowColumn padding={'6px'} justify={'start'} maxheight={'150px'} style={{background: theme.colors.textBg, borderRadius: 10}}>
                        {data?.content_type  && (data?.parts?.map(part => {
                            (part?.part_of_workout_name && (data.parts.length > partnames.length) ) && (partnames.push(part.part_of_workout_name))
                            const content = part.part_of_workout_exercises?.map(item => {
                                 return (
                                     <div>
                                         {part?.part_of_workout_name && (
                                             <h5 style={{margin: '10px 0px 5px 0', textTransform: 'uppercase', color: !showPart && (theme.colors.disabledText)}}>{part.part_of_workout_name}</h5>
                                         )}
                                         {item?.name_of_exercise && (
                                             <p style={{color: theme.colors.disabledText, fontSize: 15}}>{item.name_of_exercise}</p>
                                         )}
                                     </div>
                                 )
                            })

                            return(
                                <Column margin={'0 10px 0 0'} borderRadius={'10px'}>
                                    {content}
                                </Column> 
                            )
                        }))}
                        {/* training plan */}
                        {data?.content_type === 'training_plan' && ( 
                            <Column width={'100%'}>               
                            <Row justify={'space-between'} align={'center'} padding={'0px 0px 10px 0px'}>
                                <p style={{padding: 2}}>{trainingplanCount.workout} {WordTranslations.training[lang]} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                                <p style={{padding: 2}}>{trainingplanCount.rest} {WordTranslations.rest[lang]} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                                <p style={{padding: 2}}>{WordTranslations.duration[lang]} {trainingplanCount.workout + trainingplanCount.rest} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                            </Row>
                            <Column>
                                {data?.list
                                    .map((item, index) => ({...item,index}))
                                    .filter(item => item?.status === 'workout') // Suodatetaan vain workout-statuksella olevat
                                    .map((item) => (
                                        <div key={item.index} style={{borderBottom: `1px solid ${theme.colors.border}`, marginBottom: 10}}>
                                            <p>{item?.part_of_workout_name ? item?.part_of_workout_name : WordTranslations.day.singular[lang] + "-" + item.index}</p>
                                            {item?.exercises.map((exercise, index) => (
                                                <p key={index} style={{fontSize: 12}}>{exercise.name_of_exercise}</p>
                                            ))}
                                        </div>
                                    ))
                                }
                             </Column>
                            </Column>
                        )}
                    </RowColumn>
                    {data?.content_type && (
                    <Row padding={'0'} margin={'10px 0 10px 0'} width={'fit-content'} onClick={() => setShowPart(!showPart)}>
                        {!showPart ? <MdOutlineCheckBoxOutlineBlank size={20}/> : <MdOutlineCheckBox size={20}/>}
                        <p>{WordTranslations.display[lang]} {WordTranslations.exercise.singular[lang].toLowerCase()} {WordTranslations.part.plural[lang].toLowerCase()} {WordTranslations.name[lang].toLowerCase()}</p>
                    </Row>
                    )}
                </Column>
            </Row>
            <Row>
                <Column width={'100%'}>
                    <h5>{WordTranslations.description[lang]}*</h5>
                    <StyledTextArea
                        value={newData.description} 
                        onChange={(e) => setNewData({...newData, description: e.target.value})}
                    />
                    <p style={{fontSize: 12, opacity: '70%'}}>{minDescLen} / {newData?.description?.length}</p>
                </Column>
            </Row>
            <ButtonContainer style={{padding: '15px 10px 0px 10px'}}>
                <Row padding={'0'} width={'fit-content'} onClick={() => ShowTerms()}>
                    {!termReaded ? <MdOutlineCheckBoxOutlineBlank size={30}/> : <MdOutlineCheckBox size={30}/>}
                    <p>{WordTranslations.accept[lang]} {WordTranslations.terms[lang].toLowerCase()}</p>
                </Row>
                <Row width={'fit-content'}>
                <CustomButton
                    button={StyledSubmitButton}
                    onClick={() => handleOnPublish()}
                    label={`${WordTranslations.publish[lang]}`}
                    style={{width: '100px'}} 
                    disabled={!termReaded}/>
                <CustomButton
                    button={StyledAnalyticsButton}
                    icon={<Trash />}
                    onClick={() => close()}
                />
                </Row>
            </ButtonContainer>
            </PublishContainer>
            :
            <ClipLoader
                color={theme.colors.button}
                loading={loading}
                // cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            }
          <CustomPopUp content={description} isOpen ={showInfo} setIsOpen={() => ShowTerms()} div={DescriptionContainer}/>
          <CustomPopUp content = {content} isOpen={isPopUpOpen} setIsOpen={() => close()} div={DescriptionContainer}/>
        </Container>
    )
}

export default PublishDetails;