import { useState } from "react";

const Dropdown = ({ data, selected, handleChange, theme}) => {
  const [isOpen, setIsOpen] = useState(false); // Hallitsee dropdownin näkyvyyttä

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Dropdownin avaava nappi */}
      <button onClick={() => setIsOpen(!isOpen)} style={{ background: theme.colors.body, color: theme.colors.text, border: `1px solid ${theme.colors.border}`, borderRadius: '20px', padding: "2px 5px", cursor: "pointer" }}>
        {selected.length + " valittu "} {isOpen ? "▲" : "▼"}
      </button>

      {/* Dropdown-sisältö */}
      {isOpen && (
        <div 
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            background: theme.colors.body,
            border: `1px solid ${theme.colors.border}`,
            borderRadius: "6px",
            boxShadow: `0 2px 5px ${theme.colors.shadow}`,
            padding: "10px",
            zIndex: 10,
            minWidth: "max-content", // Kasvattaa divin leveyttä, jos sisältö on pidempi
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {data.map(value => (
            <label key={value} style={{ display: "block", marginBottom: "5px" }}>
              <input 
                type="checkbox" 
                checked={selected.includes(value)}
                onChange={() => handleChange(value)}
                style={{marginRight: "5px"}}
              />
              {value}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
