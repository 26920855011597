import React, {useEffect, useState} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//components
import PersonalExercises from "./PersonalExercises.jsx";
import { WorkoutNav, SubWorkoutNav } from "../../../components/Nav.jsx";
import { AddButton, CircularMenuButton } from "../../../components/Buttons.jsx";
//style
import {WorkoutContainer} from '../StyledComponents.jsx'
import { StyledAnalyticsButton } from "../../../components/style/Buttons.styled.jsx";
import { CustomButton } from "../../../components/Buttons.jsx";
import { RightBottomFloatingContainer, ColumnContainer } from "../../../components/style/Container.styled.jsx";
//utils
import { useData, useLanguage } from "../../../Utils/UseContext.jsx";
import { HasRole} from "../../../Utils/Role.jsx";

//icons
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import WordTranslations from '../../Translations/WordTranslation.json'
import { FaDumbbell, FaClipboardList, FaPlay, FaTimes } from "react-icons/fa";
import { ReactComponent as Exercise } from "../../../components/style/images/exercise.svg"
import { GoVideo } from "react-icons/go";
import { ReactComponent as TrainingPlan } from "../../../components/style/images/training_plan.svg"
import { ReactComponent as TrainingProgram } from "../../../components/style/images/training_program.svg"


const iconStyle = (theme) => {
  return (
    {
      width: '20px',
      height: '20px',
      color: theme.colors.text
    }
  )
}


const PersonalWorkout = ({theme}) => {
  const {language} = useLanguage();
  let lang = language;
  // if language is not supported change to default (en-EN)
  lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']
  const navigate = useNavigate();
  const location = useLocation(); 
  const {personalData, personalTrainingPlan, setPersonalData} = useData();
  const {personalVideo, setPersonalVideo} = useData();

  const [activeData, setActiveData] = useState('all');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');

    if(success){
      toast.success("congratulations, you've got a new exercise!");
      navigate(location.pathname, { replace: true });
    }

  },[location.search])

  const navToAnalytics = async () =>{
    const isRole = await HasRole('admin');
    if(!isRole){
      toast.info("Upcoming feature!");
      return;
    }else{
      navigate('/analytics')
    }
  }

  const innerBtns = [
    //{ icon: <FaClipboardList />, value: "training_plan" },
    { icon: <TrainingProgram style={iconStyle(theme)}/>, label: `${'training plan'}`, value: 'workout', onClick: () => navigate(`/workout/new`, {state: {type: 'workout'}}) },
    { icon: <GoVideo size={20} color={theme.colors.disabled}/>, label: 'Video', value: 'video', onClick: () => toast.info("Upcoming feature") },
  ];

  return(
      <WorkoutContainer>
        <WorkoutNav lang={lang}/>
        <SubWorkoutNav setActiveData={setActiveData} activeData={activeData} lang={lang}/>
        <PersonalExercises theme={theme} personalData = {personalData?.data} setPersonalData={setPersonalData} personalTrainingPlan={personalTrainingPlan} personalVideoData={personalVideo} activeData={activeData} lang={lang}/>
        <RightBottomFloatingContainer>
          <ColumnContainer>
            <CustomButton button={StyledAnalyticsButton} onClick = {() => navToAnalytics()} label={<TbBrandGoogleAnalytics size={20}/>}/>
            {/* <AddButton onClick={() => navigate(`/workout/new`)}/> */}
            <CircularMenuButton innerButtons={innerBtns}/>
          </ColumnContainer>
        </RightBottomFloatingContainer>
      </WorkoutContainer>
  )
}

export default PersonalWorkout;