
import styled from 'styled-components';
import { Colors } from '../../components/style/Colors.jsx';

export const WorkoutContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height:85vh;
  margin-top: 20px;
  // margin: 10px 20px auto auto;
  // border: 2px solid ${({theme}) => theme.colors.border};
  
  @media screen and (min-width: 500px) { //desktop screen
    margin: 20px auto;
    max-width: 60em;
    
  }
`

export const ExerciseContainer = styled.div`
   display:flex;
   flex-direction: column;
   padding: 20px auto;
   width: 100%;
   height: calc(100vh - 87px);


  @media screen and (min-width: 600px) {
    flex-direction: ${({direction}) => direction || 'row'};
    height: calc(100vh - 87px);
    // border-radius: 20px;
    // padding: 0 1em;
  }
`

export const ListContainer = styled.div`
  flex: ${(props) => (props.isCardOpen ? "1" : "1.5")};
  transition: flex 0.3s ease-in-out;
  overflow-y: auto;
  border-radius: 12px;

  @media screen and (max-width: 600px) {
    width: 100%;
    flex: unset;
    display: ${(props) => (props.isCardOpen ? "none" : "block")};
  }
`;


export const PartContainer = styled.div`
   display:flex;
   flex-direction: column;
   aling-items: center;
   justify-content: ${(props) => props.justify || 'space-between'};

   padding: 20px auto;
  

   @media screen and (min-width: 500px) and (min-height: 500px) {
    //  margin: 25px auto auto auto;
     //position:relative;
    //  width:100%;
     height: calc(100vh - 87px);
     border-radius: 20px;
     padding: 0 1em 0 1em;
   }
`

export const ExerciseContent = styled.div`
  overflow: auto;
`

export const WorkoutButtons = styled.div`
  display: flex;
  aling-items: center;
  justify-content: space-around;
  // width: 35em;
  margin: auto;

  button{
    border-radius: 20px;
    // width: 50%;
    padding: 5px;
    // border: 2px solid ${({theme}) => theme.colors.border};
    background: none;
    // ${({theme}) => theme.colors.body};
    color: ${({theme}) => theme.colors.text};
    margin: 10px;
    
    &:hover {
      background: rgba(128, 128, 128, 0.402);
      border: none; 
    }

  }
`

export const WorkoutRow = styled.div`
  // width: 100%;
  min-height: 80px;
  height: ${(props) => props.height || 'fit-content'};
  display: grid;
  grid-template-columns: ${(props) => props.columns || '1fr 1fr 1fr 1fr 0.5fr'};
  grid-template-rows:${(props) => props.rows || 'auto'};
  column-gap:1px;  
  // flex-direction: row;
  //justify-content: space-between;
  // border-bottom: 2px solid ${({theme}) => theme.colors.border};
  border-radius: 10px;
  margin-bottom: 1em;
  // margin-top: 5px;
  padding: 10px 10px 5px 10px;
  background: ${({bg, theme}) => bg || theme.colors.textBg};

`;

export const WorkoutMedia = styled.div`
    background-color: ${(props) => (props.src ? 'transparent' : 'rgba(142, 142, 142, 0.2)')};
    background-image: url(${(props) => props.src ? props.src : 'none'});
    background-size: contain; /* Säädä kuvan kokoa */
    background-repeat: no-repeat; /* Estää kuvaa toistumasta */
    background-position: center;
    border: 1px solid ${({theme}) => theme.colors.border};
    border-radius: 6px;
    width: ${({width}) => width || '200px'};
    height: ${({height}) => height || '200px'};
    min-width: ${({minWidth}) => minWidth && (minWidth)};
    min-height: ${({minHeight}) => minHeight && (minHeight)};

    @media screen and (max-width: 600px) { // mobile
      width: ${({mobile_width}) => mobile_width || '100%'};
      max-width: ${({maxWidth}) => maxWidth || '60vw'};
      height: ${({mobile_height}) => mobile_height && (mobile_height)};
      max-height: ${({maxHeight}) => maxHeight || '100%'};
    }
`

export const WorkoutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'center'};
  overflow: hidden;
  align-items: ${(props) => props.align || 'center'};
  padding: 0 5px 0 5px;
  color:${({theme}) => theme.colors.text};
  background: transparent;

  & > p{
    font-size: ${(props) => props.font ||14}px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  & > h4{
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: 600px) { // BIG screen
 
      & > p{
        font-size: ${(props) => props.font || 16}px;
      }

      & > h4{
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
  }

`;

export const WorkoutBox = styled.div`
  grid-column: ${({column}) => column || '1 / 2'};
  grid-row: ${({row}) => row || '1 / 3'};

    & > p{
    font-size: ${(props) => props.font ||14}px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

    @media screen and (min-width: 600px) { // BIG screen
 
      & > p{
        font-size: ${(props) => props.font || 16}px;
      }
  }
`

export const WorkoutDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0.7em;
  padding: 0.1em;
  // background: ${Colors.transparentgBlue};
  background: red;



  & > p {
    margin: ${(props) => props.margin}; // string
    // color: ${Colors.lightMode.TEXT_COLOR};
    font-weight: 700;
  }

  & > input {
    // color: ${Colors.lightMode.TEXT_COLOR};
    font-weight: 600;
  }
`
export const WorkoutPartContainer = styled.div`
position:relative;
display: flex;
justify-content:center;
align-items: ${(props) => props.align || 'center'};
flex-direction: row;
width: 100%;
border-radius: 0.7em;
// padding: 0.1em;
// background: ${Colors.transparentgBlue};
`

export const WorkoutInputContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
// overflow: hidden;
align-items: ${(props) => props.align || 'center'};
// margin-left: 0.5em;
// color:${({theme}) => theme.colors.text};
// background: ${({theme}) => theme.colors.textBg};
border-radius: 6px;
padding: 5px;
width: ${({width}) => width && (width)};

.amount_inside_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${(props) => props.align || 'center'};
  width: 100vw;
}

  @media screen and (min-width: 481px) and (min-height: 500px) {

    .content_div{
      display: flex;
    }

    .amounts_div {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .amount_inside_div {
      max-width: 50%;
    }

  }


`
export const WorkoutInputContent = styled.div`
  position: ${({position}) => position && (position)};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${({justify}) => justify && (justify) };
  // overflow: hidden;
  align-items: ${(props) => props.align || 'flex-start'};
  width: ${({width}) => width || '100%'};
  height ${({height}) => height};
  margin: ${({margin}) => margin || '0 0 0.5em 0'};
  padding: ${({padding}) => padding || '0 2px 10px 2px'};
  color:${({theme}) => theme.colors.text};

  @media screen and (max-width: 481px) { // mobile
    width: 100vw;
    border-bottom: ${({border}) => border || 'none'} ${({theme}) => theme.colors.border};
  }


  @media screen and (min-width: 481px) and (min-height: 500px) { // desktop
    max-width: 100%;
    border: ${({border}) => border || 'none'} ${({theme}) => theme.colors.border};
    border-radius: 20px;
  }

`

export const CreatorName = styled.p`
  font-size: 5px;
  font-weight: 400;
  margin: 0;
  text-aling: flex-start;
 `;

export const Title = styled.h3`
  // font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  color: ${({theme}) => theme.colors.text};
`;

export const Details = styled.p`
  margin: 0;
`;

