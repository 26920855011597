import React, {useState, useEffect, useCallback} from "react";
import Modal from 'react-modal';
import { useLocation, useNavigate} from 'react-router-dom';
import { CustomTextInput } from "../../../components/Components.jsx";
// style
import { ExerciseContainer, ExerciseContent, WorkoutRow, WorkoutContent, WorkoutDetailsRow, Title, ListContainer} from "../StyledComponents.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";
import { ExerciseDetailsInput } from "../../../components/style/CustomInput.styled.jsx";
import { DescriptionContainer, MobileOnlyContainer, RightBottomFloatingContainer, TimerContainerResp, ContentRoundWrapper } from "../../../components/style/Container.styled.jsx";
import { WorkoutMedia } from "../StyledComponents.jsx";
import { ToggleButton } from "../../../components/style/Buttons.styled.jsx";
//API
import { UpdateExerciseDetails, UpdateUserExerciseOrder} from "../../../Utils/ApiRequests.jsx";
import Timer from '../../../components/Timer.jsx'
import { CustomPopUp, CustomCheckBox } from "../../../components/Components.jsx";
import { SetToLocalStorage, getLast } from "../../../Utils/Utils.jsx";
import { Reorder } from "framer-motion"
// import { WorkoutMedia } from "../WorkoutMedia.jsx";
// components
import ExerciseCard from "./ExerciseCard.jsx";
import { ExerciseNavigation } from "../../../components/Nav.jsx";
// icon
import { IoSwapVerticalSharp, IoCloseOutline, IoInformationCircleOutline } from "react-icons/io5";
import { useAuth, useLanguage } from "../../../Utils/UseContext.jsx";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
//Translations
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'




Modal.setAppElement('#root');

const ClearCheckbox = (data) => {
  const clearCheckbox = data.map((item) => {
    return item.isCompleted = false;
  })

}

const AddIsComplete = (data) => {
  const updateItems = data.map((item) => {
    return {...item, isCompleted: false}
  })

  return updateItems;
}

const CountCompletionRate = (data) => {
  let rate;
  const orginalLength = data.length;
  const completedArray = [];
  data.map((item) => {
    if(item.isCompleted){
      const completedObj = {
        "id":item.ExerciseId,
        "name":item.name_of_exercise,
        "description":item.description,
        "sets": item.sets,
        "muscles": item.muscles
        //"weight":item.weight,
        //"amount":[item.times] + 'x' + [item.amount]
      }
      completedArray.push(completedObj)
    }
  })
  
  rate = (completedArray.length/orginalLength)* 100;

  return {completed: completedArray, rate: rate};
}

const CheckExerciseId = (id) => {
  const parsed = JSON.parse(localStorage.getItem('currentlyRun'));
  const isSame = id === parsed?.id;
  
  return isSame;
}

// export const FillIsComplete = (workoutLength, setExerciseCompleted) => {
//   const fillCompleteList = Array(workoutLength).fill(false);
//   setExerciseCompleted(fillCompleteList)
// }

export const DraggableList = ({ theme, setCurrentIndex, items, id, handleTaskToggle, setExercises, exercises, isTimer, lang}) => {
  const {dispatch} = useAuth();
  const [isDraggable, setIsDraggable] = useState(false);
  let Wrapper = isDraggable ? Reorder.Group : 'div';
  const part_id = id;


  const handleOnBlur = async (name, value, id, index) => {
    if(value){
      setExercises((prevState) => {
      const newData = [...prevState]
       if(newData[index] !== undefined){
         switch (name) {
           case "weight":
             newData[index].weight = value;
             break;
           case "amount":
             newData[index].amount = value;
             break;
           case "times":
             newData[index].times = value;
             break;
           default:
             console.error("Something went wrong!")
             break;
         }
       }
      return newData;
     })
      await UpdateExerciseDetails(id, value, name, part_id)

    }else{
      console.error(" KEY IS NOT VALID")
    }
  }

  const saveNewOrder = async (value) => {
    sessionStorage.setItem('order', JSON.stringify(value));
    await UpdateUserExerciseOrder(value);
  }

  return (
    <>
      <Wrapper axis="y" onReorder={setExercises} values={items} style={{listStyle:'none', paddingLeft:0}} >
          {items.map((value, index) => {
            return <ExercriseRow key={value.ExerciseId}
                      theme={theme}
                      index={index}
                      setCurrentIndex={setCurrentIndex}
                      isDraggable={isDraggable} 
                      value={value} 
                      partId={id} 
                      id={value.exercise_id} 
                      name={value.name_of_exercise} 
                      option={value.option}
                      description={value.description} 
                      unit={value.unit} 
                      media={value.file}
                      isCompleted={value.isCompleted} 
                      handleOnBlur= {handleOnBlur} 
                      handleTaskToggle={handleTaskToggle} 
                      isTimer={isTimer}
                      lang={lang}/>        
          })}
      </Wrapper>
      <RightBottomFloatingContainer bottom={'120px'}>
        <span onClick={() => setIsDraggable(!isDraggable)}>
          {!isDraggable ? <IoSwapVerticalSharp size={25} style={{cursor: 'pointer'}}/> : <IoCloseOutline size={25} style={{cursor: 'pointer'}} onClick={() => saveNewOrder(exercises)}/>}
        </span>
      </RightBottomFloatingContainer>
    </>
  );
};

export function ExercriseRow({ theme, setCurrentIndex, isDraggable, partId, value, index, id, name, amount, option, unit, description, times, weight, isCompleted, handleTaskToggle, isTimer, media, lang}) {
  const navigate = useNavigate();  
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)
    let Wrapper = isDraggable ? Reorder.Item : 'div';

    let { minReps, maxReps } = value?.sets?.length ? value?.sets?.reduce(
      (acc, curr) => ({
        minReps: Math.min(acc.minReps, curr.reps),
        maxReps: Math.max(acc.maxReps, curr.reps),
      }),
      { minReps: Infinity, maxReps: -Infinity }
    ) : { minReps: null, maxReps: null };

    let { minWeight, maxWeight } = value?.sets?.length ? value?.sets?.reduce(
      (acc, curr) => {
        if (curr.weight === null) return acc; // Ohitetaan null-arvot
        return {
          minWeight: Math.min(acc.minWeight, curr.weight),
          maxWeight: Math.max(acc.maxWeight, curr.weight),
        };
      },
      { minWeight: Infinity, maxWeight: -Infinity } // Alustetaan null-arvoilla
    ) : { minWeight: null, maxWeight: null };

    

    const handleCardClick = () => {
      const obj ={
        name: name,
        exercise_id: id,
        exercise_part_id: partId,
        description: description,
        weight: weight,
        amount: amount,
        times: times,
        option: option,
        unit: unit,
        media: media
      }

      setCurrentIndex(index);
      // navigate(`/workout/my/${obj?.exercise_part_id}/${obj?.exercise_id}`, {state: obj});
    }

    return (
      <Wrapper value={value} id={value} drag={isDraggable} style={{width:'100%'}} >
        <Row padding={'5px 0 5px 1em'}>
            <h4>{name}</h4>
        </ Row>
      <WorkoutRow columns={isTimer ? '3.7fr 0.3fr' : '3fr'}>
        <WorkoutRow columns={'0.7fr 1.5fr 1.5fr'} bg={'transparent'} onClick={() => handleCardClick()} style={{cursor: 'pointer'}}>
          <WorkoutMedia src={media} type="image" width={'80px'} height={'80px'} mobile_width={'80px'} mobile_height={'80px'}/>
          <WorkoutContent align={'flex-start'}>
            <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{ display: 'flex', flexWrap: 'wrap', gap: '2px'}}>
              <p>
                {value?.sets?.length > 0 && (
                  <>
                    {value?.sets.length}{' X '}
                  </>
                )}
              </p>
              <p>
                  {minReps != Infinity && (minReps)}{(maxReps !== minReps && maxReps !== -Infinity) && (` - ${maxReps}`)}
              </p>
              <p>
                {(value?.sets?.length > 0 || (minReps !== Infinity && minReps > 0) || (maxReps !== -Infinity && maxReps > 0)) && (
                  <>
                    {value?.option === 'rep' ? ` ${WordTranslations.repetitions[lang]}` : value?.option} 
                  </>
                )}
              </p>
            </Row>
          </WorkoutContent>
          <WorkoutContent align={'flex-end'}>
            <Row width={'fit-content'} justify={'start'} padding={'0'}>
              {/* {minWeight !== Infinity && maxWeight !== -Infinity && ( */}
              <ContentRoundWrapper>
                <span style={{fontSize: 20}}>
                
                  {minWeight !== Infinity ? minWeight !== Infinity && (minWeight) :  <p style={{color: theme.colors.shadow, fontSize: 14}}>n/a</p>}{(maxWeight !== minWeight && maxWeight !== -Infinity) && (`-${maxWeight}`)} 
                </span>
                <span style={{fontSize: 20}}>
                  {value?.unit}
                </span>
              </ContentRoundWrapper>
              {/* )} */}
            </Row>
          </WorkoutContent>
        </WorkoutRow>
            <WorkoutContent>               
              {/* {description !== "" && (
                <IoInformationCircleOutline onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}size={30}/>
                )
              } */}
              {(isTimer && CheckExerciseId(partId)) && (
                <CustomCheckBox
                  isChecked={isCompleted}
                  onChange={isChecked => handleTaskToggle(id, isChecked)}
                />
              )}
            </WorkoutContent>
          <CustomPopUp content={description ? description : ""} isOpen ={isDescriptionVisible} setIsOpen={() => setIsDescriptionVisible(!isDescriptionVisible)} div={DescriptionContainer}/>
      </WorkoutRow>
    </Wrapper>   
    );
  }

const MyExercise = ({theme}) => {
    const {language} = useLanguage();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']
    const location = useLocation();
    const {workout_name, part_name, values, id} = location.state || {}; // name = workout name
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [content, setContent] = useState("");
    const [shouldTimerContinue, setShouldTimerContinue] = useState(false);
    const [reset, setReset] = useState(false);
    const [isTimer, setIsTimer] = useState(false);
    const [stopTimer, setStopTimer] = useState(false);
    const [exercises, setExercises] = useState();
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isTimerHidden, setIsTimerHidden] = useState(false);
    const totalExercises = exercises?.length;

    useEffect(() => {
        const orderedValue = loadOrder(id);
        sessionStorage.setItem('order-' + id, JSON.stringify(orderedValue));
        setExercises(AddIsComplete(orderedValue))
        setIsTimer(() => localStorage.getItem('currentlyRun') ? true : false);
    },[])

    const loadOrder = () => {
      const savedOrder = sessionStorage.getItem('order-' + id);
      return savedOrder ? JSON.parse(savedOrder) : (values[0].part_of_workout_name ? values.slice(1) : values);
    };

    const handleTaskToggle = (exerciseId) => {
      setExercises(exercises => exercises.map(exercise => 
          exercise.ExerciseId === exerciseId ? {... exercise, isCompleted: !exercise.isCompleted} : exercise));
     };

     const handleContinue = useCallback(() => {
      setIsPopUpOpen(false);
      setShouldTimerContinue(prev => !prev); // Kääntää tilan arvon

    }, []);

    const handleConfirm = async (completed) => {
      const currentData = await JSON.parse(localStorage.getItem("currentlyRun"));

      // Lisätään uusi arvo tietoihin
      // currentData.id = id;
      currentData.completionRate = completed?.rate;
      currentData.completed = completed?.completed;
      currentData.endTime = Date.now();

      const index = await getLast(Object.keys(localStorage), 'performance-');

      // Tallennetaan päivitetyt tiedot takaisin LocalStorageen
      SetToLocalStorage("performance-" + (parseInt(index) + 1) , currentData)
      setStopTimer(true);
      handleClose();
      // toast.dismiss();
    }

    const handleClose = () => {
      setIsTimer(false)
      setIsPopUpOpen(false);
      setReset(true);
      ClearCheckbox(exercises);
    };
   
    const ConfirmationPopUp = () => {
      const buttons = [
        // { label: 'Ei', onClick: handleCancel, color: Colors.red},
        { label: `${WordTranslations.yes[lang]}`, onClick: () => handleConfirm(CountCompletionRate(exercises))},
        { label: `${WordTranslations.continue[lang]}`, onClick: handleContinue}
      ];

      const message =`${NewWorkoutTranslations.my_confirm_message[lang]}`;

      setContent({message: message, buttons: buttons});
      setIsPopUpOpen(!isPopUpOpen);
    }

    const handlePrevious = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };

    const handleNext = () => {
      if (currentIndex < totalExercises - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    };

    const handleDone = () => {
      const exerciseId = exercises[currentIndex]?.ExerciseId;
      handleTaskToggle(exerciseId);
      if(!exercises[currentIndex]?.isCompleted){
        if(currentIndex < totalExercises - 1){
          setCurrentIndex(currentIndex + 1);
        }else{
            setCurrentIndex(null);
            ConfirmationPopUp();
        }
      }

    };

     return(
          <ExerciseContainer>
            <ListContainer isCardOpen={!!selectedExercise}>
             {exercises && (
               <div>
                 <Title>{part_name}</Title>
                 <DraggableList theme={theme} setCurrentIndex={setCurrentIndex} items={exercises} id={id} handleTaskToggle={handleTaskToggle} setExercises={setExercises} exercises={exercises} isTimer={isTimer} lang={lang}/>
               </div>
             )}
            </ListContainer>

            {currentIndex != null && (
              <>
                <ExerciseCard
                  id={id}
                  exercise={exercises[currentIndex]}
                  setExercises={setExercises}
                  onClose={() => setCurrentIndex(null)}
                  theme={theme}
                  lang={lang}
                /> 
                <MobileOnlyContainer>
                  <ExerciseNavigation
                    currentIndex={currentIndex}
                    totalExercises={totalExercises}
                    isCompleted={exercises[currentIndex]?.isCompleted}
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    onDone={handleDone}
                    isTimer={isTimer}
                  />
                </MobileOnlyContainer>
              </>
            )}
            <TimerContainerResp isHidden={isTimerHidden}>
              <ToggleButton onClick={() => setIsTimerHidden(!isTimerHidden)}>
                    {isTimerHidden ? <IoIosArrowBack size={20} />
                     : <IoIosArrowForward size={20} />
                     }
              </ToggleButton>
              <Timer name = {{name: workout_name, partname: part_name}}
                      shouldContinue={shouldTimerContinue} setTimer = {setIsTimer}
                      setStop = {setStopTimer} stop = {stopTimer}  
                      ConfirmationPopUp={ConfirmationPopUp} 
                      onContinueChange={handleContinue} 
                      reset={reset} 
                      onTimerReset={() => setReset(false)}
                      partId={id}/>
            </TimerContainerResp>
            <CustomPopUp content = {content} isOpen={isPopUpOpen} setIsOpen={() => handleClose()} div={DescriptionContainer}/>
          </ExerciseContainer>
        
     )
   }

export default MyExercise;
  

