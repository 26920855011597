import React, {useEffect, useState, useMemo, useRef} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fi'; // Import Finnish locale
//API
import { InsertWorkoutEvent, fetchPersonalData, fetchPersonalExercisesData } from "../../Utils/ApiRequests";
//Data
import { useData, useLanguage } from "../../Utils/UseContext";
//Utils
import { ScrollView } from "../../components/Components";
import { isObjectEmpty } from "../../Utils/Utils";
//Style
import { CustomDatepicker, CustomDateTimePicker } from "../../components/style/Date.styled";
import { Container, CalendarPlanerContainer } from "../../components/style/Container.styled";
import { StyledSubmitButton } from "../../components/style/Buttons.styled";
import "../../components/style/datetime-style.css"
import { Row, Column, RowColumn } from "../../components/style/Row.styled";
//icon
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
//Buttons
import { CustomButton, ExitButton } from "../../components/Buttons";
import { InsertCalenderExercise } from "../../Utils/ApiRequests";
import { Loading } from "../../Utils/Utils";
// Translation
import WordTranslations from '../Translations/WordTranslation.json'


function findKeyByObjectName(data, targetName, targetKey) {
    const item = data.find(item => item[targetKey] === targetName);

    if(item) return {key: item.part_of_workout_id, value: item.part_of_workout_exercises};

    return null;
}

const Datepicker = (props) => {
    moment.locale('en'); // this need to retrieve from logged user (default 'en')
    return(
        <div style={{display: 'flex', flexDirection: 'row', margin: '0 0 15px 5px', border: `solid 1px ${props.theme.colors.border}`, borderRadius: 20, padding: 6, fontSize: '16px'}}>        
            {WordTranslations.start[props.lang]} {WordTranslations.time[props.lang]}:
            <CustomDateTimePicker
                onChange={props.handleDateChange}
                value={props.date}
                dateFormat={moment.localeData().longDateFormat('L')}
                timeFormat={moment.localeData().longDateFormat('LT')}
                theme={props.theme}
            />
        </div>
    )
}

const fetchData = async (personalData, setPersonalData) => {
    if (!personalData?.data) {
        await fetchPersonalData(setPersonalData);
    }

    const updatedWorkout = personalData?.data;
    if (updatedWorkout) {
        for (let i = 0; i < updatedWorkout.length; i++) {
            await fetchPersonalExercisesData(updatedWorkout[i].workout_id, setPersonalData);
        }
    }
    await new Promise((resolve) => setTimeout(resolve, 200));
    return updatedWorkout;  // Palautetaan ladattu data
};

const DropdownList = ({theme, data, selectedWorkout, setSelectedWorkout, lang}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isPartOpen, setIsPartOpen] = useState(false);
    const [newItem, setNewItem] = useState('');
    const [values, setValues] = useState([]);
    const [partOfWorkoutNames, setPartOfWorkoutNames] = useState([]);

    const isInitialized = useRef(false);

    //List of workout title
    const workoutNames = useMemo(() => {
        return data?.filter(f => f.content_type === 'training_program').map(item => item.workout_name) || [];
     }, [data]);
   // console.log("ITEM filter: ", partOfWorkoutNames)
  
    const toggleDropdown = () => setIsOpen(!isOpen);
    const togglePartDropdown = () => setIsPartOpen(!isPartOpen);

    const setPartNames = async (workoutName) => {

        const partOfWorkoutNamesList = data.flatMap(item => {
            const key = item.workout_name;
             if (key === workoutName) {
                 const values = item?.parts
                 //Object.values(item)[0];
                 // Update values
                setValues(values)
                const name = item?.parts?.flatMap(exercise => exercise.part_of_workout_name);
                const id = item?.parts?.flatMap(exercise => exercise.part_of_workout_id)
                const nameIdPair = {name: name, id: id}

                 return nameIdPair;

             }else{
                 return [];
             }
        });

        // asetetaan part nimet listaan
        setPartOfWorkoutNames(partOfWorkoutNamesList[0]);
     
    }

    useEffect(() => {

            // const initialWorkout = workoutNames[0];
    
            // setSelectedWorkout(prev => ({
            //     ...prev,
            //     title: initialWorkout
            // }));
    
            // setPartNames(initialWorkout);
            
    },[]);

    useEffect(() => {
     if (partOfWorkoutNames?.name?.length > 0) {
         const keyValuePair = findKeyByObjectName(values, partOfWorkoutNames?.name[0], 'part_of_workout_name');
       
         setSelectedWorkout(prev => ({
             ...prev,
             name_of_exercise: partOfWorkoutNames?.name[0],
             workout_value: keyValuePair?.value,
             part_of_workout_id: keyValuePair?.key
         }));
     }
    }, [partOfWorkoutNames])

    const handleSelectWorkout = (item) => {
        setSelectedWorkout(prev => ({
            ...prev,
            title: item
            // exercise: partOfWorkoutNames[0]
          }));

        setPartNames(item);
      };

    const handleSelectPart = (item) => {
        const keyValuePair = findKeyByObjectName(values, item, 'part_of_workout_name');

        setSelectedWorkout(prev => ({
            ...prev,
            name_of_exercise: item,
            workout_value: keyValuePair?.value,
            part_of_workout_id: keyValuePair?.key
          }));

        setIsPartOpen(false);
    };

      const filteredWorkouts = workoutNames?.filter(item =>
        item?.toLowerCase().includes(newItem.toLowerCase())
      );

       const filteredParts = partOfWorkoutNames?.name?.filter(item =>
         item?.toLowerCase().includes(newItem.toLowerCase()) || []
       );



        const dropdown = (options, handle, value) => {

            return(
                <select style={{background: 'transparent', border: `solid 1px ${theme.colors.border}`, borderRadius: 20, width: '15em', padding: 6, fontSize: '16px'}} value={value || ''} onChange={(e) => handle(e.target.value)}>
                    <option value="" disabled>
                        Valitse harjoitus
                    </option>
                    {options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
            )
        }
    return(
        <Column style={{height: '140px'}} mobile_Justify='space-between' justify='space-between' padding='10px'>
            <Column align='start'>
                <h4>Ohjelma</h4>
                {dropdown(filteredWorkouts, handleSelectWorkout, selectedWorkout?.title)}
            </Column>
            {selectedWorkout?.name_of_exercise && (
                <Column align='start'>
                    <h4>Treeni</h4>
                    {dropdown(filteredParts, handleSelectPart, selectedWorkout?.name_of_exercise)}
                </Column>
            )}
        </Column>
    )
}

const Content = ({selected}) => {

    if(!selected) return null;

    return (
        <div>
        <h4>Sisältö</h4>
        <ul style={{listStyle: 'none', margin: 0, padding: 0, width:'15em'}}>
        {selected && (
            selected.map((value, index)=> {
                if(value?.part_of_workout_name) return;
                return (
                    <li key={index}>
                        {value?.name_of_exercise}
                    </li>
                )
             }
            )
        )
        }
        </ul>
        </div>
    )
}

const WorkoutPlaner = ({theme}) => {
    const {language} = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const {date} = location.state || {date: new Date()};
    const {personalData, setPersonalData} = useData();
    const [selectedWorkout, setSelectedWorkout] = useState({workout_id: null, title: null, name_of_exercise: null, part_of_workout_id: null, workout_value: null, date_time:date});
    const [values, setValues] = useState([]);
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN'];

    //const values = personalData?.data && (Object.values(personalData?.data));

    useEffect(() => {
        if(!personalData?.data) return;
        const workout_id = selectedWorkout?.title ? personalData?.data?.find(item => item[selectedWorkout?.title])?.workout_id : personalData?.data[0]?.workout_id;

        if(!personalData?.data[0]?.parts){
            fetchData(personalData, setPersonalData);
        }

        if (workout_id) {
            setSelectedWorkout(prev => ({
                ...prev,
                workout_id: workout_id
              }));
        }

        setValues(Object.values(personalData?.data));
    }, [personalData?.data, selectedWorkout?.title]);

    const navto = () => {
        navigate('/calendar', {state: {from: 'workout_event'}})
    }

 
    

    const handleDateChange = async (date) => {
        setSelectedWorkout(prev => ({
            ...prev,
            date_time: date
          }));
    }

    const handleSaveEvent = async () => {
        //SAVE DATA TO DB (start_time, workout_id, part_of_workout_id)
        await InsertWorkoutEvent(selectedWorkout, navto)

        // navigate('/calendar')
    }

    return(
        <Container>
            <CalendarPlanerContainer>
                <Loading data={personalData?.data} theme={theme}>
                    { personalData?.data && (isObjectEmpty(personalData?.data)) ?
                        <p onClick={() => navigate(`/workout/new`)}>Please click here and create new exercise first.</p>
                    :
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Column align={'center'}>
                            <Datepicker handleDateChange={handleDateChange} date={selectedWorkout?.date_time} theme={theme} lang={lang} />
                            <RowColumn>
                                <DropdownList theme = {theme} data={values} selectedWorkout={selectedWorkout} setSelectedWorkout={setSelectedWorkout} lang={lang}/>
                                <Content selected={selectedWorkout?.workout_value} lang={lang}/>
                            </RowColumn>
                        </Column>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: '400px'}}>
                            <CustomButton onClick={() => navigate('/calendar')} button={StyledSubmitButton} label={`${WordTranslations.cancel[lang]}`} style={{maxWidth: '150px'}} />
                            <CustomButton onClick={() => handleSaveEvent()} button={StyledSubmitButton} label={`${WordTranslations.save[lang]}`} style={{maxWidth: '150px'}}/>
                        </div>
                    </div>
                    }
                </Loading>
            </CalendarPlanerContainer>
        </Container>
    )
}

export default WorkoutPlaner;