

import { AddTokenToHeaders,AuthorizationHeaders, DateNow, ClearLoginCookie, delay, getFromLocalstorage, GetCookieData} from "../Utils/Utils";
import {jwtDecode} from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { subscribeUserToPush } from '../Utils/pushNotification.js';

const host_name = process.env.REACT_APP_HOST_API_KEY;
const ws_host = process.env.REACT_APP_WS_API_KEY;


const MAX_RETRIES = 10; // Maksimimäärä yrityksiä
let retries = 0


export const CheckServer = async () => {
  try {
    const response = await fetch(`${host_name}/`,{
      method: 'GET',
      headers: {'Content-type':'application/json'}
    });
    const resp = await response.json();

    return resp.isUp;
  } catch (error) {
    console.error("ERROR: ", error)
    return false;
  }

}

function setLoginCookie(token, userKey) {
  const currentDate = DateNow();
  const expirationDate = new Date(currentDate);
  expirationDate.setFullYear(currentDate.getFullYear() + 1);
  const userid = userKey;

  const userData = { isAuthenticated: true, token: token, user: userid};
  const cookieData = JSON.stringify(userData);
  document.cookie = `user=${cookieData}; expires=${expirationDate.toString()}; path=/`;
}

export const refreshAccessToken = async () => {

  try {
    const res = await fetch(`${host_name}/refresh-token`, {
      method: 'POST',
      credentials: 'include' // Sisältää cookien, jossa refresh token on
    });



    if(res.ok){
      const data = await res.json();

      const token = data?.token;
      const decodedToken = jwtDecode(token);
      const userKey = decodedToken?.userId;

      setLoginCookie(token, userKey)

      return 

    }else{
      throw new Error("Failed to refresh token");
    }

  } catch (error) {
    console.error("Tokenin uudistaminen epäonnistui", error);
    ClearLoginCookie();
    localStorage.clear();
    sessionStorage.clear();
  }
};

export const FetchPrivacyPolicy = async (setPrivacyPolicy) => {
  try {
    const response = await fetch(`${host_name}/privacypolicy`,{
      method: 'GET',
      headers: {'Content-type':'application/json'}
    });
    if(response.ok){
      const data = await response.json();
      
      setPrivacyPolicy(data)
    }else{
      if(response.status === 401){
        console.error("Response 401: ", response.status)
      }else{
        console.error("Error in response.", response.status)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export const HandleLogin = async (email, password, navigate, dispatch, setIsWrong, setIsVerified) => {

  try{
    const response = await fetch(`${host_name}/post/login`,{
      method: 'POST',
      headers: {'Content-type':'application/json'},
      body: JSON.stringify({email, password}),
      credentials: 'include'
    });

    if(response.status === 403){
      toast.info('Check your email and Verify your account');
      setIsVerified(false);
      return;
    }

    if(response.ok){
      const {token} = await response.json();
      const decodedToken = jwtDecode(token);
      const userKey = decodedToken?.userId;

      // login(email, token)
      const pushPermission = sessionStorage.getItem('pushPermissionGranted');
      if (pushPermission === 'true') {
        // Käyttäjä on antanut luvan push-ilmoituksille
        subscribeUserToPush();
      }

      setLoginCookie(token, userKey);
      dispatch({ type: 'LOGIN', payload: token, user: userKey});

      navigate('/home')
    }else{
      setIsWrong(true);
      const message = await response.json();
      console.error("Server error occured", message)
    }
  }catch(error){
    console.error("ERROR: ", error)
  }
};

export const HandleRegisteration = async (firstname, lastname, email, password, dispatch, navigate, setEmailExist, checkValidation, setUndefinedErr) => {
  if(checkValidation()){
    try {
          const response = await fetch(`${host_name}/post/register`,{
              method: 'POST',
              headers: {'Content-type':'application/json'},
              body: JSON.stringify({email, firstname, lastname, password}),
              credentials: 'include'
            });
            if(response.ok){
              const {token} = await response.json();
              if(!token){
                setEmailExist(true)
              }else{
              
               const decodedToken = jwtDecode(token);
               const userKey = decodedToken?.userId;
               // login(email, token)
               dispatch({ type: 'LOGIN', payload: token, user: userKey});
               setLoginCookie(token, userKey);
               navigate('/');
              }
            }else if(response.status === 409){
              setEmailExist(true)
            }
        
    } catch (error) {
        console.error("Error in registration: ", error)
    }
}else{
  setUndefinedErr(true)
}
}

export const HandleLogout = async (dispatch) => {


  try{
    const response = await fetch(`${host_name}/post/logout`,{
      method: 'POST',
      headers: {'Content-type':'application/json'},
      credentials: 'include'
    });

    if(response.ok){
      dispatch({ type: 'LOGOUT'});

      ClearLoginCookie();
      localStorage.clear();
      sessionStorage.clear();
      // navigate('/');
    }

  }catch(error){
    console.error("ERROR: ", error);
    ClearLoginCookie();
    localStorage.clear();
    sessionStorage.clear();
    // navigate('/');
  }
}

export const UpdateExerciseDetails = async (id, value, key, index, part_id) => {
        let headers = await AddTokenToHeaders();


        const fetchData = async () => {
          const response = await fetch(`${host_name}/put/update_exercise_details`,{
            method: 'PUT',
            headers,
            // headers: {'Content-type':'application/json'},
            body: JSON.stringify({id, value, key, index}) // Tämän pitää olla sama kuin serveri puolella
          });

          if (!response.ok) {
            throw new Error('Failed to update details');
          }

          return response;
        }

        try {

          headers = await AddTokenToHeaders();

          let response =  await fetchData();

          // if (response.status === 401) {

          //   await refreshAccessToken();
          //   headers = await AddTokenToHeaders(); 
          //   response = await fetchData();

          //         // Tarkista uusi vastaus
          //   if (!response.ok) {
          //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
          //   }
          // }

          if(response.ok){
            console.info("response ok")
            const responseData = await response.json();
            // sessionStorage.removeItem('order-'+part_id);
            // Hae sessionStorage-tiedot

            let orderData = sessionStorage.getItem('order-' + part_id);
            const responseSets = responseData.value;
                      
            if (orderData) {
              // Purkaa sessionStorage-objekti JSON-muotoon
              orderData = JSON.parse(orderData);
            
              // Etsi oikea harjoitusobjekti
              const exercise = orderData.find(item => item.ExerciseId === id); // Esimerkiksi ExerciseId:n perusteella

              if (exercise) {
                // Päivitä sets-tiedot oikeiksi (näin saat vastaanotetun sets-tiedon)
                exercise.sets = responseSets; // responseSets on palautettu uusi "sets" data
              
                // Tallenna päivitetty data takaisin sessionStorageen
                sessionStorage.setItem('order-' + part_id, JSON.stringify(orderData));
              

              } else {
                console.error(`Exercise with ID ${id} not found`);
              }
            } else {
              console.error('No data found in sessionStorage for order-' + part_id);
            }


            console.info("Details updated: ")
          }else{
            if(response.status === 401){
              console.error("Response: ", response.status);
            //    HandleExpiredToken().then(tok => {
            //      setToken(tok)
            //    });
            }else{
              console.error("Error in response.", response.status)
            }
          }
        } catch (error) {
          console.error("Error addin data to the database: ", error)
        }
      }

export const UpdateWorkout = async (formData, setPersonalData, personalData, navTo) => {
    let headers = await AuthorizationHeaders();
    const deleted = [];
    //  const updatedData = {
    //    ...data.data,
    //   //  [workoutId] : {...data.data[workoutId],  [workoutName]: [...data.data[workoutId][workoutName],  {[index] : {...data.data[workoutId][workoutName][index]}}]}
    //   [workoutId]: {
    //     ...data.data[workoutId],
    //     [workoutName]: data.data[workoutId][workoutName].map((item, i) => {
    //       if (i === index) {
    //         // Korvaa vain kyseinen item
    //         return {
    //           ...item,
    //           ...newworkout, // Oletetaan että `newworkout` sisältää päivitetyt arvot
    //         };
    //       }
    //       return item;
    //     }),
    //   },
    // };

    const fetchData = async () => {
      const response = await fetch(`${host_name}/put/update_exercise`,{
        method: 'PUT',
        headers,
        body: formData// Tämän pitää olla sama kuin serveri puolella
      });

      return response;
    }

     try {

       headers = await AuthorizationHeaders();

        let response =  await fetchData();

        // if (response.status === 401) {

        //   await refreshAccessToken();
        //   headers = await AuthorizationHeaders(); 
        //   response = await fetchData();

        //         // Tarkista uusi vastaus
        //   if (!response.ok) {
        //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
        //   }
        // }

        const data = await response.json();
        if(response.ok){
          const part_id = data?.part_id;
          const workout_id = data?.workout_id;
          //const newData = Object.values(data?.data[data?.workout_id][workoutName].find((key) => Object.keys(key)[0] === part_id))
          //data?.data[data?.workout_id][workoutName][0][data?.part_id];

//console.log("NEW DATA: ", newData[0], data?.data[data?.workout_id][workoutName].find((key) => Object.keys(key)[0] === part_id).part_id)

           sessionStorage.removeItem('order-' + part_id);
           //await setPersonalData({type: 'LOAD' , payload: data?.data});
           const parts = await fetchPersonalExercisesData(workout_id, setPersonalData);        

          toast.success(data?.message)
          navTo(parts[0]?.part_of_workout_exercises, part_id)
          return {isLoading: false}
        }else{
          if(response.status === 401){
            console.error("Response: ", response.status);
           
          }else{
            console.error("Error in response.", response.status)
           
          }
        }
     } catch (error) {
       console.error("Error addin data to the database: ", error)
       throw error;
   }
}

export const fetchPersonalData = async (setPersonalData) =>{   
      let headers = await AddTokenToHeaders();
        
        let fetchData = async () => {
          const response = await fetch(`${host_name}/load/workout/my`,{
            method: 'POST',
            headers: headers
          });
          return response;
        }

        try {
          headers = await AddTokenToHeaders();

          let response =  await fetchData();


          // if (response.status === 401) {

          //   await refreshAccessToken();
          //   headers = await AddTokenToHeaders(); 
          //   response = await fetchData();

          //         // Tarkista uusi vastaus
          //   if (!response.ok) {
          //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
          //   }
          // }

          if(response.ok){
            const data = await response.json();
            // SetToLocalStorage("myWorkout", data)
            await setPersonalData({type: 'LOAD' , payload: data?.data});
          }else{
            
            if(response.status === 400){
              console.error("expired token")
            }else{
              const localData = getFromLocalstorage("myWorkout");
              if (localData) {
                setPersonalData({ type: 'LOAD', payload: localData });
              }
            }
          }
        } catch (error) {
          const localData = getFromLocalstorage("myWorkout");
          if (localData) {
            setPersonalData({ type: 'LOAD', payload: localData });
          }
        }
}

export const fetchPersonalExercisesData = async (workout_id, setPersonalData) => {
  let headers = await AddTokenToHeaders();
        
        let fetchData = async () => {
          const response = await fetch(`${host_name}/load/exercises/my`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({workout_id})
          });
          return response;
        }

        try {
          headers = await AddTokenToHeaders();

          let response =  await fetchData();


          // if (response.status === 401) {

          //   await refreshAccessToken();
          //   headers = await AddTokenToHeaders(); 
          //   response = await fetchData();

          //         // Tarkista uusi vastaus
          //   if (!response.ok) {
          //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
          //   }
          // }

          if(response.ok){
            const data = await response.json();
          //SetToLocalStorage("myWorkout", data)
             await setPersonalData({
               type: 'ADD_PART_TO_WORKOUT',
               payload: { workout_id, parts: data.data }
           });

           return data.data;

          }else{
            console.info("Do nothing!")
          }
        } catch (error) {
          console.error(error)
        }
}

export const fetchExercisesData = async (setExercisesData) => {
  let headers = await AddTokenToHeaders();
        
        let fetchData = async () => {
          const response = await fetch(`${host_name}/load/exercises/my`,{
            method: 'POST',
            headers: headers
          });
          return response;
        }

        try {
          headers = await AddTokenToHeaders();

          let response =  await fetchData();


          // if (response.status === 401) {

          //   await refreshAccessToken();
          //   headers = await AddTokenToHeaders(); 
          //   response = await fetchData();

          //         // Tarkista uusi vastaus
          //   if (!response.ok) {
          //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
          //   }
          // }

          if(response.ok){
            const data = await response.json();
            // SetToLocalStorage("myWorkout", data)
            await setExercisesData({type: 'LOAD' , payload: data?.data});
          }else{
            console.info("Do nothing!")
          }
        } catch (error) {
          console.error(error)
        }
}

export const GetPersonalVideo = async (setPersonalVideo) => {
  let headers = await AddTokenToHeaders();
        
  let fetchData = async () => {
    const response = await fetch(`${host_name}/load/video/my`,{
      method: 'GET',
      headers: headers
    });
    return response;
  }

  try {
    headers = await AddTokenToHeaders();

    let response =  await fetchData();


    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData();

    //         // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();
      // SetToLocalStorage("myWorkout", data)
      await setPersonalVideo({type: 'LOADVIDEO' , payload: data?.data});

    }else{
      console.info("response fail")
    }
  } catch (error) {
    console.error("video: ",error)
  }
}

export const GetPersonalTrainingPlan = async (setPersonalTrainingPlan) => {
  let headers = await AddTokenToHeaders();
        
  let fetchData = async () => {
    const response = await fetch(`${host_name}/load/trainingplan/my`,{
      method: 'GET',
      headers: headers
    });
    return response;
  }

  try {
    headers = await AddTokenToHeaders();

    let response =  await fetchData();


    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData();

    //         // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();

      // SetToLocalStorage("myWorkout", data)
      await setPersonalTrainingPlan({type: 'LOADTRAININGPLAN' , payload: data?.data});

    }else{
      console.info("response fail")
    }
  } catch (error) {
    console.error("training plan: ",error)
  }
}

export const GetPublicData = async ({setPublicData = null, setNotifications = null, ws}) => {

  // if(GetCookieData()?.token){
  //   await refreshAccessToken();
  // }

  ws.onmessage = async (event) => {
    const message = await JSON.parse(event.data);

    if(setPublicData){
      if (message.type === 'PUBLIC_DATA') {
        setPublicData({type: 'LOAD' , payload: message.payload});
      }
    }

    if(setNotifications){
      if(message.type === 'NOTIFICATION'){
        setNotifications({type: 'NOTIFICATION', payload: message.payload})
      }
    }

  };
}

export const GetSettings = async () => {
  let headers = await AddTokenToHeaders();

  const fetchData = async () => {
    const response = await fetch(`${host_name}/load/settings`,{
      method: 'GET',
      headers: headers
    });

    return response;
  }
        
  try {

    headers = await AddTokenToHeaders();

    let response =  await fetchData();

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData();

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();
      return data?.settings
    }
  } catch (error) {
    console.error("setting error: ", error)
  }
}

export const GetEvaluationsData = async (starList, setEvaluationsData) =>{    
   try {
     const response = await fetch(`${host_name}/load/evaluations`,{
       method: 'GET',
       headers: {'Content-type':'application/json'}
     });
     if(response.ok){
       const data = await response.json();
      if(starList.length <= 0 && data.length > 0){ 
       data.forEach((i) => {
        starList.push(Number(i.stars))
       });
      }
       setEvaluationsData(data)
     }else{
       if(response.status === 401){
         console.error("Response 401: ", response.status)
       }else{
         console.error("Error in response.", response.status)
       }
     }
   } catch (error) {
     console.error(error)
   }
 }

export const GetWorkoutVisibility = async (workout_id) => {
  let headers = await AddTokenToHeaders();

  const fetchData = async () => {
    const response = await fetch(`${host_name}/load/workout/visibility`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({workout_id}) // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
  }
 
  try {

    headers = await AddTokenToHeaders();

    let response =  await fetchData();

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData();

    //         // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const result = await response.json();

      const visibility = result?.visible;

      return visibility;
    }
  } catch (error) {
    console.error("ERROR: ", error)
    throw error;
  }
} 

export const GetCompletedWorkout = async (start, end) => {

  let  headers = await AddTokenToHeaders();

  const fetchData = async () => {
    const response = await fetch(`${host_name}/load/workout/completed`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({start, end}) // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
  }
  try {

    headers = await AddTokenToHeaders();

    let response =  await fetchData();

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData();

    //         // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const result = await response.json();

      return result;
    }
  } catch (error) {
    console.error(error);
    // throw error;
  }
}

export const GetWorkoutEvents = async (start, end) => {
  let headers = await AddTokenToHeaders();

  const fetchData = async () => {
    const response = await fetch(`${host_name}/load/workout/event`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({start, end})
    });

    return response;
  }
  try {

    headers = await AddTokenToHeaders();

    let response =  await fetchData();

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData();

    //         // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

     if(response.ok){
       const result = await response.json();

       return result;
     }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const VerifyEmail = async (email, verificationCode) => {
  try {
    const response = await fetch(`${host_name}/verify`,{
      method: 'POST',
      headers: {'Content-type':'application/json'},
      body: JSON.stringify({email, verificationCode}) // Tämän pitää olla sama kuin serveri puolella
    });
    const data = await response.json();
    const message = data?.message;
    const verified = data?.verified;

    return {message: message, verified: verified};
  } catch (error) {
    console.error("VERIFICATION ERROR: " ,error)
  }
}

export const VerifyEmailUpdate = async (email) =>{
  let headers = await AddTokenToHeaders();
  try {
    const response = await fetch(`${host_name}/update/email/verify`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({email}) // Tämän pitää olla sama kuin serveri puolella
    });
    const data = await response.json();
    const message = data?.message;

    return {message: message};
  } catch (error) {
    console.error("VERIFICATION ERROR: " ,error)
  }
}

export const VerifyPasswordUpdate = async (password, newPassword) => {
  let headers = await AddTokenToHeaders();
  try {
    const response = await fetch(`${host_name}/update/password/verify`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({password, newPassword}) // Tämän pitää olla sama kuin serveri puolella
    });
    const data = await response.json();
    const message = data?.message;
    const status = data?.status;

    return {message: message, status: status};
  } catch (error) {
    console.error("VERIFICATION ERROR: " ,error)
  }
}

export const UserAuthorizarion = async (password) => {
  let headers = await AddTokenToHeaders();
  try {
    const response = await fetch(`${host_name}/update/password/check`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({password}) // Tämän pitää olla sama kuin serveri puolella
    });
    const data = await response.json();
    const message = data?.message;
    const status = data?.status;

    return {message: message, status: status};
  } catch (error) {
    console.error("VERIFICATION ERROR: " ,error)
  }
}

export const ReVerifyEmail = async (email) => {
  try {
    const response = await fetch(`${host_name}/verification/reorder`,{
      method: 'POST',
      headers: {'Content-type':'application/json'},
      body: JSON.stringify({email}) // Tämän pitää olla sama kuin serveri puolella
    });
    const data = await response.json();
    const message = data?.message;

    if(response.status === 401){
      toast.info(message);
      console.info("user not found")
      return;
    }else{
      toast.info(message)
      return {message: message};
    }


  } catch (error) {
    console.error("VERIFICATION ERROR: " ,error)
  }

}

export const ContactEmail = async (formData, setMessageSuccess) => {

    try {
      const response = await fetch(`${host_name}/post/contact`,{
        method: 'POST',
        headers: {'Content-type':'application/json'},
        body: JSON.stringify({formData}) // Tämän pitää olla sama kuin serveri puolella
      });
      const data = await response.json();
      if(response.ok){
        setMessageSuccess({message: data?.message, success: data?.success}) // success = true
      }else{
        setMessageSuccess({message: data?.message, success: data?.success}) // success = false
      }
    } catch (error) {
      console.error("contact ERROR: " ,error);
      throw error;
    }
}

export const PublishExercise  = async (formData) => {
      //let headers = await AuthorizationHeaders();

      const fetchData = async (headers) =>{
        const response = await fetch(`${host_name}/put/publish/workout`,{
          method: 'POST',
          headers,
          body: formData // Tämän pitää olla sama kuin serveri puolella
        });

        return response;
      }

      try {

        let headers = await AuthorizationHeaders();

        let response =  await fetchData(headers);
    
        // if (response.status === 401) {

        //   await refreshAccessToken();
        //   headers = await AuthorizationHeaders(); 
        //   response = await fetchData(headers);
    
        //   // Tarkista uusi vastaus
        //   if (!response.ok) {
        //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
        //   }
        // }

        if(response.ok){
          const data = await response.json();
          console.info("status: ", response.status, " : ", data)

          return "Update"
          //setPersonalData({type: 'LOAD' , payload: updatedData})
        }else{
          if(response.status === 401){
            console.error("Response: ", response.status);
          }else{
            console.error("Error in response.", response.status)
          }
        }
      } catch (error) {
        console.error("Error addin data to the database: ", error)
      }
}

export const ConfirmAccessRight = async (workout_id, expirydate) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/exercise_accessright`,{
      method: 'POST',
      headers,
      body: JSON.stringify({workout_id, expirydate}) // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
  }
  
   try {

     let headers = await AddTokenToHeaders();

     let response =  await fetchData(headers);
 
    //  if (response.status === 401) {

    //    await refreshAccessToken();
    //    headers = await AddTokenToHeaders(); 
    //    response = await fetchData(headers);
 
    //    // Tarkista uusi vastaus
    //    if (!response.ok) {
    //      throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //    }
    //  }
     
     const result = await response.json();

     if(response.ok){
       toast.success(result?.message)
     }

     if(response.status === 409){

         toast.info(result?.message)
      }else if(response.status !== 200){
         console.error("Error in response.", response.status)
      }
     
   } catch (error) {
     console.error("Error addin data to the database: ", error)
   }
  }

export const InsertCompletedIntoDb = async (key, completedData) => {
   //const headers = await AddTokenToHeaders();
   const parsedData = JSON.parse(completedData)

   const part_of_workout_name = parsedData.partname;
   const part_of_workout_id = parsedData.id;
   const exercise_array = parsedData.completed;
   const completion_rate = parsedData.completionRate;
   const date = parsedData.date;
   const start_time = parsedData.startTime;
   const completed_time = parsedData.endTime;
   const duration = completed_time - start_time;

   const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/completed/exercise`,{
      method: 'POST',
      headers,
      body: JSON.stringify({part_of_workout_name, part_of_workout_id, exercise_array, completion_rate, date, duration, completed_time, start_time}) // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
   }

   while(retries < MAX_RETRIES) {
   try {

     let headers = await AddTokenToHeaders();

     let response =  await fetchData(headers);
 
    //  if (response.status === 401) {

    //    await refreshAccessToken();
    //    headers = await AddTokenToHeaders(); 
    //    response = await fetchData(headers);
 
    //    // Tarkista uusi vastaus
    //    if (!response.ok) {
    //      throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //    }
    //  }

     if(response.ok){
       localStorage.removeItem(key)
       toast.success('Saved');
       retries = 0;
       return;
     }else{
      //  if(response.status === 401){
      //    console.error("Response 401");
      //    toast.warn('Unauthorized')
         
      //    return;
      //  }else{
      //    console.error("Error in response.", response.status)
      //    return;
      //  }
     }
   } catch (error) {
    //  console.error("Error addin data to the database: ", error);
    // console.error(`Attempt ${retries + 1} failed: ${error.message}`);
    retries++;

    toast.warn(`Attempt ${retries} failed: ${error.message}`,{
      autoClose: 8000,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
    })

     if (retries < MAX_RETRIES) { // MAX_RETRIES = 5
      console.info(`Retrying in 10 second... (${retries}/${MAX_RETRIES})`);
      // Aseta viive odottamista varten
      await delay(10000); // Esimerkiksi 10 sekuntia
      // Tarkista, onko palvelin palautunut
      const serverIsOnline = await CheckServer();
      if (!serverIsOnline) {
        // Yritä uudelleen tietokantaan lisäämistä
        // await InsertCompletedIntoDb(key, completedData);
        console.info('Server is still offline.');
        break;
      } 
      
    } else {
      console.info('Maximum retries reached. Aborting.');
      toast.error('Maximum retries reached. Aborting.')
    }
  }
   }
}

export const InsertNewWorkoutToDb = async (formData, navTo, setPersonalData, data) => {
  //const headers = await AuthorizationHeaders();

  const updatedData = {
    ...data.data,
    ...formData
  }

    const fetchData = async (headers) => {
      const response = await fetch(`${host_name}/post/workout/new`,{
        method: 'POST',
        headers,
        body: formData // Tämän pitää olla sama kuin serveri puolella
      });

      return response;
    }

    try {

     let headers = await AuthorizationHeaders();

     let response =  await fetchData(headers);
 
    //  if (response.status === 401) {

    //    await refreshAccessToken();
    //    headers = await AuthorizationHeaders(); 
    //    response = await fetchData(headers);
 
    //    // Tarkista uusi vastaus
    //    if (!response.ok) {
    //      throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //    }
    //  }

       if(response.ok){
         const data = await response.json();

          console.info("Tiedot lisätty: ", response.status)
          await setPersonalData({type: 'LOAD' , payload: data?.data});
          navTo();
       }
     } catch (error) {
       console.error("Error addin data to the database: ", error)
     }
}

export const InsertNewExercise = async (formData, navTo) => {

    const fetchData = async (headers) => {
      const response = await fetch(`${host_name}/post/exercise/new`,{
        method: 'POST',
        headers,
        body: formData // Tämän pitää olla sama kuin serveri puolella
      });

      return response;
    }

    try {

     let headers = await AuthorizationHeaders();

     let response =  await fetchData(headers);
 
    //  if (response.status === 401) {

    //    await refreshAccessToken();
    //    headers = await AuthorizationHeaders(); 
    //    response = await fetchData(headers);
 
    //    // Tarkista uusi vastaus
    //    if (!response.ok) {
    //      throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //    }
    //  }

       if(response.ok){
         const data = await response.json();

          console.info("Tiedot lisätty: ", response.status, " : ", data)
          //navTo();
       }
     } catch (error) {
       console.error("Error addin data to the database: ", error)
     }
}

export const InsertNewTrainingplan = async (formData, navTo) => {

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/trainingplan/new`,{
      method: 'POST',
      headers,
      body: formData // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
  }

  try {

   let headers = await AuthorizationHeaders();

   let response =  await fetchData(headers);

  //  if (response.status === 401) {

  //    await refreshAccessToken();
  //    headers = await AuthorizationHeaders(); 
  //    response = await fetchData(headers);

  //    // Tarkista uusi vastaus
  //    if (!response.ok) {
  //      throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
  //    }
  //  }

     if(response.ok){
       const data = await response.json();

        console.info("Tiedot lisätty: ", response.status, " : ", data)
        navTo();
     }
   } catch (error) {
     console.error("Error addin data to the database: ", error)
   }
}

export const InsertNewVideo = async (formData) => {
  
  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/video/new`,{
      method: 'POST',
      headers,
      body: formData // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
  }

  try {

   let headers = await AuthorizationHeaders();

   let response =  await fetchData(headers);

  //  if (response.status === 401) {

  //    await refreshAccessToken();
  //    headers = await AuthorizationHeaders(); 
  //    response = await fetchData(headers);

  //    // Tarkista uusi vastaus
  //    if (!response.ok) {
  //      throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
  //    }
  //  }

     if(response.ok){
       const data = await response.json();

        console.info("Tiedot lisätty: ", response.status, " : ", data)
        //navTo();
     }
   } catch (error) {
     console.error("Error addin data to the database: ", error)
   }
}

export const InsertWorkoutEvent = async (workoutEvent, navto) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) =>{
    const response = await fetch(`${host_name}/post/workout/event`,{
      method: 'POST',
      headers,
      body: JSON.stringify({workoutEvent}) // Tämän pitää olla sama kuin serveri puolella
    })

    return response;
  }

  try {

      let headers = await AddTokenToHeaders();

      let response =  await fetchData(headers);
  
      // if (response.status === 401) {

      //   await refreshAccessToken();
      //   headers = await AddTokenToHeaders(); 
      //   response = await fetchData(headers);
  
      //   // Tarkista uusi vastaus
      //   if (!response.ok) {
      //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
      //   }
      // }

      if(response.ok){
        const events = await response.json();

       //ladataan tiedot uudelleen
       //navigoidaan
       navto();
      }
  } catch (error) {
    console.error("Event: ", error)
  }
}

export const InsertTrainingPlansEvent = async (trainingPlan, setPersonalData, navto) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) =>{
    const response = await fetch(`${host_name}/post/trainingplan/event`,{
      method: 'POST',
      headers,
      body: JSON.stringify({trainingPlan}) // Tämän pitää olla sama kuin serveri puolella
    })

    return response;
  }

  try {

      let headers = await AddTokenToHeaders();

      let response =  await fetchData(headers);
  
      // if (response.status === 401) {

      //   await refreshAccessToken();
      //   headers = await AddTokenToHeaders(); 
      //   response = await fetchData(headers);
  
      //   // Tarkista uusi vastaus
      //   if (!response.ok) {
      //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
      //   }
      // }

      if(response.ok){
        const data = await response.json();

        return data;

       //ladataan tiedot uudelleen
       //navigoidaan
      //  navto();
      }
  } catch (error) {
    console.error("Event: ", error)
  }
}

export const HandleForgotPwdSubmit = async (email, checkValidation, setMessage) => {
  if(checkValidation){
    try {
          const response = await fetch(`${host_name}/forgot/password`,{
              method: 'POST',
              headers: {'Content-type':'application/json'},
              body: JSON.stringify({email})
            });

            const data = await response.json();
            const message = data?.message;
            setMessage(message);
            
    } catch (error) {
        console.error("Error responding password reset: ", error)
    }
}
}

export const HandleResetPassword = async (password, verificationData, checkValidation, setMessage) => {
  if(checkValidation){
    const verificationCode = verificationData?.code;
    const email = verificationData?.email;
    try {
          const response = await fetch(`${host_name}/reset/password`,{
              method: 'POST',
              headers: {'Content-type':'application/json'},
              body: JSON.stringify({email, verificationCode, password})
            });

            const data = await response.json();
            const message = data?.message;

            setMessage(data);
            
    } catch (error) {
        console.error("Error responding password reset: ", error)
    }
}
}

// UPDATE
export const UpdateProfile = async (key, value) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) =>{
    const response = await fetch(`${host_name}/patch/profile`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({key, value})
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();

      return data;
    }

  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const UpdateContactInfo = async (value) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/put/address`,{
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({value})
    });

    return response;
  }
  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();
  
      return data?.value;
    }

  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const UpdateUsername = async (value) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/patch/username`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({value})
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();
  
      return data;
    }

  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const UpdateUserExerciseOrder = async (value) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) =>{
    const response = await fetch(`${host_name}/update/order`,{
      method: 'POST',
      headers,
      body: JSON.stringify({value}) // Tämän pitää olla sama kuin serveri puolella
    });

    return response;
  }
  
  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const Resp = await response.json();

      console.info("New order updated: ", Resp)
    }
} catch (error) {
  console.error("Update order: ", error)
}
}

export const UpdateEmail = async (value, code) => {
  // palauttaa lataus ikonin kunnes verifioitu uusi email päivittää vasta kun verifioitu
  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/patch/email`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({value, code})
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);


    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();

      const message = data?.message;
      const verified = data?.verified;

      // return data;
    return {message: message, verified: verified};
    }

  } catch (error) {
    console.error("update erroe: ",error);
    throw error;
  }
}

export const UpdatePassword = async (value, code, meta) => {

  // palauttaa lataus ikonin kunnes verifioitu uusi email päivittää vasta kun verifioitu
  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/patch/password`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({value, code})
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);



    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();

      const message = data?.message;
      const verified = data?.verified;

      // return data;
    return {message: message, verified: verified};
    }

  } catch (error) {
    console.error("update erroe: ",error);
    throw error;
  }
}

//DELETE
export const UnpublishWorkout = async (workout_id) => {
 // const headers = await AddTokenToHeaders();

 const fetchData = async (headers) => {
  const response = await fetch(`${host_name}/patch/unpublish`,{
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify({workout_id})
  });

  return response;
 }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    
    if(response.ok){
      const data = await response.json();

      toast.success("Unpublished");
      return {unpublished: true};
    }else{
      console.error("ERROR IN UNPUBLISHING: ", response.status)
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

}

export const DeleteWorkout = async (workout_id, deleteItem, setPersonalData) => {
  //const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/delete/workout`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({workout_id})
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    const data = await response.json();
    if(response.ok){

      deleteItem();
      const isItem = localStorage.getItem('myWorkout');
      if(isItem){
        localStorage.setItem('myWorkout', deleteItem());
      }

      await setPersonalData({type: 'LOAD' , payload: data?.data});

      toast.success(data?.message)
    }else{
      toast.warn(data?.message)
    }
    // return data;
  } catch (error) {
    console.error("error deleting workout: ", error);
    throw error;
  }
}

export const DeleteEvent = async (event_id) => {
  // const headers = await AddTokenToHeaders();
  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/delete/event`,{
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({event_id})
    });

    return response;
  }
  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();

      return data.data;
    }
  } catch (error) {
    console.error("error deleting event: ", error);
    throw error;
  }
}

export const SaveSubscription = async (subscription) => {
  // const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/save/subscription`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({subscription}),
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const data = await response.json();

    }
    // return data;
  } catch (error) {
    console.error("error subscription: ", error);
    throw error;
  }
}

export const Notify = async (message) => {
  // const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/notify`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({message}),
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const resp = await response.json();
    }
    // return data;
  } catch (error) {
    console.error("error subscription: ", error);
    throw error;
  }
}

export const PatchNotificationStatus = async (item_id) => {
  // const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/patch/notification/status`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({item_id}),
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const resp = await response.json();

      toast.success(resp?.message);

      return response.status
    }
    // return data;
  } catch (error) {
    toast.warn('Something went wrong, please contact us.')
    console.error("error rating: ", error);
    throw error;
  }
}

export const Rate = async (comment, stars, workout_id, item_id) => {
  // const headers = await AddTokenToHeaders();

  const fetchData = async (headers) => {
    const response = await fetch(`${host_name}/post/rate`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({comment, stars, workout_id, item_id}),
    });

    return response;
  }

  try {

    let headers = await AddTokenToHeaders();

    let response =  await fetchData(headers);

    // if (response.status === 401) {

    //   await refreshAccessToken();
    //   headers = await AddTokenToHeaders(); 
    //   response = await fetchData(headers);

    //   // Tarkista uusi vastaus
    //   if (!response.ok) {
    //     throw new Error("Tokenin uusiminen ei onnistunut uudessa pyynnössä");
    //   }
    // }

    if(response.ok){
      const resp = await response.json();

      toast.success(resp?.message);

      return response.status
    }
    // return data;
  } catch (error) {
    toast.warn('Something went wrong, please contact us.')
    console.error("error rating: ", error);
    throw error;
  }
}



