import React, {useState, useRef, useEffect, forwardRef} from "react";
import "./style/muscleStyle.css";

const MuscleMapBack = forwardRef(({width, height, onSelectionChange, activeMuscles}, ref) => {

    // const svgRef = useRef(null);

    useEffect(() => {
      if (ref?.current) {
        const musclePaths = ref.current.querySelectorAll('.muscle');
        musclePaths.forEach((path, index) => {
          path.setAttribute('data-index', index);  // Asetetaan jokaiselle pathille indeksi
          path.classList.toggle('active', activeMuscles.includes(index));  // Lisätään "active"-luokka, jos lihas on valittu
        });
      }
    }, [activeMuscles]);

    const handleMuscleClick = (e) => {
        e.target.classList.toggle('active');
            // Palauta lihaksen indeksi ylätasolle
            const index = parseInt(e.target.getAttribute('data-index'), 10);

            //const muscles = document.querySelectorAll('.muscle'); // Hakee kaikki lihaselementit
            //const index = Array.from(muscles).indexOf(e.target);  // Laskee indeksin listassa
            if (onSelectionChange && !isNaN(index)) {
              onSelectionChange(index);
            }
      };

      //default width = 586.000000pt height = 1171.000000pt
      if(!width || !height) return;

      return(
        <>
            <svg 
                ref={ref}
                version="1.0" xmlns="http://www.w3.org/2000/svg"
                width={`${width}pt`} height={`${height}pt`} viewBox="0 0 587.000000 1173.000000"
                preserveAspectRatio="xMidYMid meet"
                onClick={handleMuscleClick}>

                <g transform="translate(0.000000,1173.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path class="muscle" d="M2790 11639 c-95 -19 -227 -103 -284 -181 -42 -58 -55 -110 -66 -261
                -14 -184 -15 -187 -45 -187 -14 0 -29 -8 -35 -19 -13 -25 -13 -96 0 -141 6
                -19 15 -66 20 -104 12 -80 22 -96 61 -96 21 0 32 -7 43 -27 17 -32 21 -26 6
                11 -6 18 -19 27 -44 32 -31 5 -36 10 -41 43 -4 20 -13 66 -21 103 -8 37 -14
                92 -14 122 l0 55 38 3 37 3 2 70 c5 144 24 289 45 336 27 64 111 142 193 181
                40 18 107 38 168 48 98 17 99 18 42 18 -33 0 -80 -4 -105 -9z"/>
                <path class="muscle" d="M3010 11637 c0 -3 31 -17 69 -32 98 -37 175 -96 222 -168 21 -33 39
                -54 39 -46 0 26 -56 105 -102 142 -59 49 -228 126 -228 104z"/>
                <path class="muscle" d="M3356 11273 c9 -76 14 -80 14 -10 0 31 -5 59 -10 62 -7 4 -8 -13 -4
                -52z"/>
                <path class="muscle" d="M3378 11174 c-5 -4 -8 -39 -8 -78 0 -49 3 -67 10 -60 6 6 10 40 8 78
                -2 37 -6 64 -10 60z"/>
                <path class="muscle" d="M3375 11000 c-4 -6 8 -10 29 -10 48 0 61 -25 47 -91 -14 -65 -13 -81
                4 -44 8 17 14 51 15 77 0 37 -4 50 -22 62 -25 18 -64 21 -73 6z"/>
                <path class="muscle" d="M3420 10794 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
                <path class="muscle" d="M3408 10714 c-7 -38 -11 -42 -43 -48 -40 -8 -50 -23 -58 -86 -3 -25
                -18 -80 -32 -123 -16 -50 -25 -102 -26 -145 -1 -120 -12 -117 -48 14 -38 137
                -58 181 -73 166 -19 -19 -12 -142 11 -202 41 -107 77 -157 157 -216 42 -31 73
                -58 70 -61 -12 -13 -117 51 -159 96 -54 58 -85 117 -122 231 -59 186 -70 215
                -86 218 -10 2 -19 -6 -22 -20 -3 -13 -4 -446 -2 -963 3 -742 1 -987 -10 -1165
                -17 -259 -19 -410 -6 -410 15 0 47 61 71 135 27 88 141 324 249 519 143 257
                179 353 231 612 31 151 59 254 87 309 8 16 67 81 130 142 l114 113 105 -31
                c188 -56 122 -22 -92 47 -295 94 -401 137 -483 194 -96 68 -105 88 -106 230 0
                97 4 124 23 170 12 30 28 87 35 125 11 66 14 71 45 81 45 14 54 26 50 70 l-3
                39 -7 -41z"/>
                <path class="muscle" d="M2795 10550 c-3 -5 -21 -64 -40 -130 -37 -132 -79 -230 -122 -285
                -31 -39 -143 -119 -183 -130 l-25 -7 25 25 c14 14 45 36 70 50 53 29 117 116
                150 204 28 76 37 181 17 207 -13 18 -16 16 -35 -35 -11 -30 -31 -93 -43 -139
                l-23 -85 -12 40 -12 40 -1 -42 c-1 -23 -6 -65 -12 -92 -20 -96 -125 -164 -416
                -272 -178 -65 -178 -67 -61 -165 144 -121 165 -161 222 -425 20 -90 45 -202
                56 -249 24 -97 100 -258 245 -515 56 -99 116 -224 145 -300 86 -223 119 -282
                121 -219 2 68 -2 211 -12 384 -7 106 -12 594 -13 1145 -1 528 -6 970 -10 983
                -9 23 -21 28 -31 12z"/>
                <path class="muscle" d="M2504 10515 c6 -44 55 -164 55 -138 1 12 -13 55 -30 95 -16 40 -28
                60 -25 43z"/>
                <path class="muscle" d="M1840 9808 c-112 -32 -117 -34 -107 -44 7 -8 207 53 222 67 13 13 2
                11 -115 -23z"/>
                <path class="muscle" d="M1604 9731 c-168 -66 -252 -165 -325 -381 -27 -80 -33 -114 -36 -215
                -5 -112 18 -379 33 -394 3 -3 10 3 16 13 6 10 75 88 154 172 79 85 155 171
                168 192 13 20 34 73 46 117 11 44 26 92 32 107 13 34 134 150 217 209 68 48
                77 73 34 91 -16 6 -71 31 -124 55 -52 23 -101 43 -107 43 -7 0 -12 5 -12 10 0
                15 -19 12 -96 -19z"/>
                <path class="muscle" d="M4108 9753 c14 -5 -19 -21 -163 -78 -130 -51 -131 -53 -28 -130 43
                -32 108 -88 144 -124 66 -66 66 -67 88 -163 13 -57 33 -113 50 -139 15 -24 94
                -114 175 -199 80 -85 149 -165 152 -177 13 -48 21 -19 32 110 16 177 15 347
                -3 430 -21 101 -101 260 -165 330 -59 65 -109 98 -195 127 -50 17 -126 29 -87
                13z"/>
                <path class="muscle" d="M2021 9570 c-63 -23 -107 -50 -175 -109 -26 -22 -56 -46 -67 -51 -26
                -14 -24 -32 6 -60 13 -13 49 -74 79 -135 44 -90 67 -123 110 -162 58 -54 163
                -112 290 -163 114 -45 125 -35 82 74 -13 34 -36 117 -51 186 -78 370 -88 400
                -150 426 -44 18 -58 17 -124 -6z"/>
                <path class="muscle" d="M3641 9561 c-37 -39 -63 -118 -96 -291 -36 -186 -61 -278 -90 -341
                -15 -33 -21 -57 -15 -63 13 -13 125 25 246 84 139 67 194 123 268 272 31 62
                69 123 83 136 22 20 24 25 12 36 -143 128 -234 182 -327 193 -48 5 -53 4 -81
                -26z"/>
                <path class="muscle" d="M1720 9319 c-19 -34 -3 -66 51 -109 28 -22 55 -40 60 -40 26 0 0 70
                -47 127 -40 49 -49 52 -64 22z"/>
                <path class="muscle" d="M4051 9321 c-44 -44 -98 -151 -76 -151 16 0 106 65 120 87 17 25 13
                54 -10 73 -11 9 -18 7 -34 -9z"/>
                <path class="muscle" d="M1696 9184 c-3 -9 -6 -24 -6 -34 0 -35 52 -196 86 -267 31 -66 38
                -74 91 -99 48 -24 71 -28 167 -32 94 -4 120 -1 171 17 32 12 66 28 74 36 22
                22 3 36 -94 75 -99 39 -173 84 -252 154 -101 89 -207 166 -226 166 -3 0 -8 -7
                -11 -16z"/>
                <path class="muscle" d="M4059 9171 c-24 -15 -93 -69 -154 -119 -104 -88 -145 -112 -328 -197
                -67 -31 -65 -40 16 -76 63 -27 205 -37 289 -20 110 23 145 65 203 243 34 104
                44 181 26 192 -4 3 -28 -7 -52 -23z"/>
                <path class="muscle" d="M1627 9021 c-32 -38 -149 -269 -170 -336 -41 -127 -70 -428 -71 -747
                -1 -185 0 -198 20 -225 12 -15 28 -29 37 -31 32 -7 89 73 168 235 44 89 83
                163 89 165 5 1 24 88 42 191 29 169 31 194 20 246 -7 31 -12 91 -12 132 0 158
                -39 347 -79 383 -18 16 -20 15 -44 -13z"/>
                <path class="muscle" d="M4120 8986 c-35 -71 -44 -128 -55 -339 -6 -105 -11 -139 -22 -146
                -13 -7 -13 -17 1 -82 8 -41 27 -139 41 -219 14 -80 32 -167 40 -195 l15 -50
                -5 55 c-6 55 -5 54 74 -105 85 -172 132 -232 170 -220 55 17 64 106 46 425
                -17 296 -30 429 -51 520 -23 103 -184 420 -213 420 -5 0 -24 -29 -41 -64z"/>
                <path class="muscle" d="M1440 8850 c-46 -24 -80 -62 -119 -130 -30 -54 -31 -54 -31 -22 0 17
                -4 32 -9 32 -4 0 -8 -42 -8 -92 0 -51 -13 -205 -27 -343 -27 -246 -40 -306
                -90 -417 -9 -21 -13 -38 -9 -38 5 0 26 41 47 91 45 103 54 101 55 -13 1 -100
                28 -88 50 22 6 30 27 179 46 330 43 331 63 420 115 515 22 40 40 75 40 79 0
                11 -24 6 -60 -14z"/>
                <path class="muscle" d="M4320 8860 c0 -6 23 -56 50 -111 l51 -100 39 -312 c55 -428 68 -496
                90 -482 5 3 10 32 10 63 0 77 11 137 21 121 4 -8 10 -11 13 -9 2 3 -6 106 -19
                230 -14 124 -26 276 -27 337 -3 110 -10 135 -26 92 -7 -19 -10 -16 -25 22 -20
                51 -62 99 -118 134 -41 27 -59 31 -59 15z"/>
                <path class="muscle" d="M2265 8767 l-90 -42 -185 0 c-102 0 -191 -4 -198 -8 -15 -10 -11
                -232 5 -263 35 -67 174 -614 198 -775 20 -141 19 -175 -10 -299 -13 -57 -23
                -105 -22 -106 1 -2 24 23 50 54 51 61 55 77 32 122 -9 17 -17 71 -20 142 -7
                141 2 126 50 -82 38 -165 72 -247 165 -397 58 -95 78 -103 132 -54 l32 28 7
                139 c12 221 30 279 194 622 53 110 101 225 107 255 22 102 -18 208 -177 482
                -125 216 -132 225 -158 224 -12 0 -62 -19 -112 -42z"/>
                <path class="muscle" d="M3397 8778 c-32 -43 -177 -289 -215 -364 -16 -32 -42 -94 -57 -137
                -23 -65 -27 -88 -23 -151 4 -67 11 -86 71 -204 81 -160 138 -286 184 -412 31
                -86 35 -110 44 -254 9 -166 17 -193 60 -216 38 -21 62 -8 106 59 87 133 130
                237 173 416 52 214 49 205 65 205 8 0 17 12 20 28 20 91 84 338 131 504 56
                196 89 393 74 448 l-6 25 -195 0 -194 0 -91 43 c-51 23 -99 42 -107 42 -9 0
                -27 -15 -40 -32z"/>
                <path class="muscle" d="M1676 7985 c-22 -74 -56 -234 -56 -263 0 -19 -4 -22 -19 -17 -15 4
                -25 -1 -37 -22 -10 -15 -40 -52 -67 -81 -59 -64 -58 -74 14 -176 27 -38 49
                -79 50 -90 0 -21 0 -21 9 -1 12 28 56 270 70 385 6 52 22 144 36 204 26 118
                26 153 0 61z"/>
                <path class="muscle" d="M4606 7963 c70 -166 69 -161 56 -211 -7 -26 -36 -93 -64 -150 -49
                -99 -50 -105 -44 -162 8 -73 33 -160 46 -160 13 0 39 63 57 140 27 117 56 210
                64 210 14 0 -1 -84 -38 -230 -31 -117 -36 -155 -41 -288 -7 -221 -2 -247 147
                -777 76 -269 112 -370 96 -270 -43 277 -85 753 -90 1035 -5 211 -4 232 9 205
                14 -27 15 -28 16 -7 0 29 -92 386 -123 477 -35 100 -90 225 -99 225 -5 0 -1
                -17 8 -37z"/>
                <path class="muscle" d="M2754 7932 c-52 -33 -101 -100 -163 -226 -96 -194 -141 -359 -127
                -467 10 -76 -10 -142 -65 -215 -27 -36 -49 -71 -49 -78 0 -20 29 -42 113 -81
                88 -42 169 -111 232 -199 57 -79 98 -119 122 -114 17 3 18 33 26 658 5 360 9
                673 10 695 2 39 1 40 -33 43 -21 1 -47 -5 -66 -16z"/>
                <path class="muscle" d="M2960 7930 c-8 -15 -8 -60 -1 -158 6 -75 13 -366 16 -647 3 -280 8
                -525 11 -542 3 -21 11 -33 20 -33 21 0 67 49 124 129 56 80 152 161 220 185
                60 22 110 60 110 85 0 10 -18 39 -40 63 -55 63 -61 84 -70 238 -10 163 -38
                264 -115 426 -63 133 -125 226 -170 254 -43 26 -91 26 -105 0z"/>
                <path class="muscle" d="M4151 7894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                <path class="muscle" d="M4165 7820 c4 -19 11 -39 16 -45 6 -6 7 4 3 25 -4 19 -11 40 -16 45
                -7 7 -8 -2 -3 -25z"/>
                <path class="muscle" d="M1106 7737 c-28 -91 -31 -111 -17 -103 11 7 35 -63 51 -144 16 -79
                50 -183 66 -199 11 -12 15 -11 23 11 18 47 39 167 34 190 -3 13 -25 64 -49
                113 -23 50 -50 117 -58 149 -9 32 -18 61 -20 63 -2 3 -16 -34 -30 -80z"/>
                <path class="muscle" d="M4193 7615 c5 -97 43 -275 58 -275 5 0 9 6 9 13 0 6 23 45 50 85 28
                41 50 82 50 92 0 10 -21 42 -46 72 -25 29 -53 65 -62 80 -11 19 -23 27 -40 25
                -24 -2 -24 -2 -19 -92z"/>
                <path class="muscle" d="M3802 7650 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
                <path class="muscle" d="M1056 7560 c-21 -72 -66 -271 -66 -290 1 -9 7 -5 16 10 14 23 15 1 9
                -230 -6 -274 -21 -468 -63 -805 -15 -117 -24 -211 -20 -209 9 6 17 33 127 420
                107 376 115 423 115 619 -1 150 -3 169 -38 310 -21 83 -43 168 -51 190 l-13
                40 -16 -55z"/>
                <path class="muscle" d="M3786 7520 c-4 -30 -12 -60 -18 -67 -17 -21 -1 -83 30 -122 38 -48
                59 -87 41 -75 -8 4 -24 21 -37 36 -37 44 -63 54 -79 32 -28 -38 -16 -74 87
                -253 l65 -113 8 49 c11 69 1 148 -39 311 -19 79 -38 169 -42 200 l-8 57 -8
                -55z"/>
                <path class="muscle" d="M1364 7372 c-6 -4 -14 -25 -18 -47 -4 -22 -30 -101 -57 -175 -60
                -167 -84 -266 -153 -642 -30 -164 -64 -336 -76 -383 -11 -48 -20 -99 -20 -113
                l1 -27 19 23 c18 22 55 113 120 297 40 111 112 287 116 282 2 -1 -19 -84 -47
                -182 -27 -99 -48 -187 -47 -195 2 -8 23 53 47 135 87 299 170 562 205 645 19
                47 51 123 70 170 20 47 36 96 35 110 0 22 -1 21 -15 -10 l-16 -35 -23 51 c-28
                63 -109 118 -141 96z"/>
                <path class="muscle" d="M4377 7359 c-45 -24 -77 -72 -77 -116 0 -52 -17 -39 -30 22 -6 30
                -14 55 -17 55 -16 0 16 -104 81 -270 41 -102 97 -258 126 -347 51 -157 69
                -199 57 -130 -9 53 43 -67 118 -273 88 -241 119 -310 140 -310 10 0 15 5 12
                14 -16 41 -62 252 -102 471 -70 378 -102 514 -155 659 -26 72 -51 147 -55 166
                -9 43 -31 80 -48 80 -6 -1 -29 -10 -50 -21z"/>
                <path class="muscle" d="M2004 7284 c-65 -65 -72 -84 -81 -221 -5 -92 -4 -103 10 -103 10 0
                17 4 17 10 0 5 16 35 36 65 52 83 114 215 114 246 0 75 -24 76 -96 3z"/>
                <path class="muscle" d="M4836 7120 c19 -434 77 -989 110 -1042 15 -24 21 21 18 140 -3 121
                -3 125 10 82 23 -75 18 -12 -13 178 -16 98 -48 296 -71 442 -51 328 -61 366
                -54 200z"/>
                <path class="muscle" d="M952 7088 c-12 -68 -33 -199 -47 -293 -14 -93 -37 -236 -50 -316 -27
                -161 -31 -222 -10 -149 12 40 13 28 8 -115 -6 -170 1 -188 31 -85 23 78 56
                395 86 825 6 83 13 174 16 203 4 31 2 52 -3 52 -6 0 -20 -55 -31 -122z m-85
                -700 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
                <path class="muscle" d="M2091 7161 c-18 -22 -56 -82 -85 -133 -36 -64 -58 -92 -69 -90 -11 2
                -18 -7 -21 -30 -21 -133 -28 -189 -22 -195 3 -3 6 2 6 13 1 36 38 103 67 117
                15 8 69 24 119 36 156 38 183 68 148 170 -22 67 -72 145 -95 149 -10 2 -30
                -14 -48 -37z"/>
                <path class="muscle" d="M3644 7168 c-31 -37 -84 -144 -84 -170 0 -56 55 -98 155 -118 88 -17
                151 -38 168 -58 8 -9 21 -46 27 -82 7 -36 17 -69 22 -75 6 -6 7 -1 4 15 -17
                78 -36 197 -36 227 0 30 -2 33 -14 24 -16 -14 -18 -11 -80 101 -39 71 -111
                168 -125 168 -5 0 -22 -15 -37 -32z"/>
                <path class="muscle" d="M2140 6772 c-71 -6 -89 -30 -153 -202 -38 -104 -53 -170 -39 -170 5
                0 46 25 91 56 46 31 142 87 214 126 73 38 148 85 167 104 l35 35 -23 19 c-13
                10 -45 21 -75 24 -91 9 -171 12 -217 8z"/>
                <path class="muscle" d="M3424 6761 c-42 -11 -54 -19 -54 -40 0 -28 36 -55 170 -126 69 -37
                169 -96 222 -131 53 -35 100 -64 104 -64 25 0 -60 258 -109 332 l-23 33 -139
                2 c-77 0 -154 -2 -171 -6z"/>
                <path class="muscle" d="M2450 6665 c-30 -25 -95 -66 -145 -92 -229 -117 -314 -183 -362 -282
                -28 -55 -28 -59 -27 -236 1 -196 23 -345 67 -435 29 -61 174 -210 239 -245 46
                -25 65 -29 158 -33 180 -7 331 37 415 122 76 76 79 94 72 420 -9 362 -20 475
                -54 546 -45 92 -239 280 -290 280 -10 0 -43 -20 -73 -45z"/>
                <path class="muscle" d="M3225 6679 c-87 -55 -194 -179 -235 -273 -24 -53 -38 -217 -47 -546
                -7 -270 0 -315 62 -388 73 -85 241 -137 419 -130 136 5 201 35 304 142 126
                130 161 241 169 547 6 186 6 186 -21 245 -47 102 -128 171 -328 278 -58 31
                -137 79 -174 106 -38 28 -76 50 -84 50 -9 0 -38 -14 -65 -31z"/>
                <path class="muscle" d="M1881 6634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                <path class="muscle" d="M3942 6495 c0 -49 3 -112 8 -140 7 -48 7 -47 7 25 1 41 -3 104 -7
                140 l-7 65 -1 -90z"/>
                <path class="muscle" d="M1858 6465 c-5 -55 -7 -136 -7 -180 l2 -80 8 75 c5 41 8 122 7 180
                l-3 105 -7 -100z"/>
                <path class="muscle" d="M4526 6485 c3 -16 11 -34 17 -40 6 -7 7 0 3 20 -4 17 -12 35 -17 40
                -7 7 -8 0 -3 -20z"/>
                <path class="muscle" d="M4550 6419 c0 -3 18 -82 40 -175 22 -93 40 -184 40 -201 0 -18 -12
                -72 -26 -120 -38 -125 -39 -259 -2 -358 71 -188 128 -327 124 -298 -3 18 -24
                81 -47 140 -80 210 -82 216 -86 313 -5 84 -2 105 23 185 36 118 35 168 -11
                345 -32 124 -54 191 -55 169z"/>
                <path class="muscle" d="M808 6240 c-4 -14 -8 -34 -8 -45 1 -19 1 -19 11 -2 6 10 9 30 7 45
                l-3 27 -7 -25z"/>
                <path class="muscle" d="M3964 6130 c8 -131 24 -248 37 -270 6 -10 8 -4 4 20 -3 19 -12 107
                -20 194 -8 88 -18 162 -21 165 -4 3 -4 -46 0 -109z"/>
                <path class="muscle" d="M5001 6195 c0 -11 8 -39 18 -61 15 -34 32 -49 85 -79 77 -43 115 -83
                157 -168 43 -84 111 -186 192 -286 37 -46 67 -90 67 -98 0 -8 -15 -20 -34 -28
                -50 -21 -98 -4 -166 61 -31 30 -62 54 -68 54 -7 0 -11 -14 -11 -32 1 -24 3
                -27 6 -10 2 12 8 22 12 22 4 0 27 -21 51 -46 73 -74 127 -91 189 -59 54 28 50
                49 -28 139 -65 75 -125 163 -189 279 -33 60 -68 118 -78 128 -10 11 -48 36
                -85 55 -49 26 -71 44 -82 67 -7 18 -19 43 -25 57 -9 21 -11 22 -11 5z"/>
                <path class="muscle" d="M779 6135 c-8 -17 -31 -41 -52 -55 -21 -13 -33 -26 -27 -28 26 -9
                107 73 98 99 -3 9 -10 2 -19 -16z"/>
                <path class="muscle" d="M1176 6098 c-4 -37 -4 -85 0 -105 l9 -38 3 53 c2 28 2 76 0 105 l-3
                52 -9 -67z"/>
                <path class="muscle" d="M1843 6115 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
                <path class="muscle" d="M616 6007 c-23 -23 -58 -74 -78 -113 -52 -98 -146 -239 -200 -299
                -74 -82 -79 -104 -28 -130 33 -17 106 -20 115 -5 4 6 -13 10 -45 10 -52 0 -90
                13 -90 30 0 5 28 44 63 87 78 96 129 172 193 287 27 49 66 108 87 132 50 56
                36 57 -17 1z"/>
                <path class="muscle" d="M1827 6003 c-10 -17 -18 -138 -9 -128 5 6 13 38 16 73 6 57 4 74 -7
                55z"/>
                <path class="muscle" d="M1203 5905 c4 -11 9 -32 13 -47 3 -16 10 -28 14 -28 10 0 -8 72 -22
                85 -7 7 -8 4 -5 -10z"/>
                <path class="muscle" d="M1791 5802 c-7 -24 -10 -49 -8 -55 2 -7 10 7 16 31 7 24 10 49 8 55
                -2 7 -10 -7 -16 -31z"/>
                <path class="muscle" d="M4010 5803 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
                -5 3 -10 3 -10 -2z"/>
                <path class="muscle" d="M1242 5750 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
                <path class="muscle" d="M4030 5694 c0 -8 -5 -12 -10 -9 -16 10 -27 -33 -44 -176 -9 -75 -28
                -185 -42 -245 -53 -230 -52 -215 -48 -768 2 -285 6 -521 8 -523 6 -7 11 12 75
                332 101 506 126 728 118 1033 -5 193 -28 372 -48 372 -5 0 -9 -7 -9 -16z"/>
                <path class="muscle" d="M1221 5655 c-6 -22 -11 -44 -11 -48 0 -5 -24 -70 -54 -145 -59 -151
                -90 -246 -76 -237 5 3 14 22 20 43 7 20 34 93 60 162 55 142 84 235 77 253 -2
                6 -9 -6 -16 -28z"/>
                <path class="muscle" d="M1750 5623 c-19 -99 -20 -105 -30 -243 -12 -168 7 -381 71 -785 22
                -137 105 -533 119 -569 5 -12 9 76 9 216 1 130 6 337 12 460 11 244 7 291 -46
                538 -13 63 -32 171 -41 240 -24 179 -30 200 -59 200 -21 0 -25 -7 -35 -57z"/>
                <path class="muscle" d="M539 5578 c-8 -7 -28 -29 -44 -48 l-30 -34 43 37 c23 20 45 37 48 37
                3 0 4 -55 1 -122 -4 -83 -3 -105 3 -68 11 73 12 210 2 210 -5 0 -15 -6 -23
                -12z"/>
                <path class="muscle" d="M5253 5425 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
                <path class="muscle" d="M2868 5425 c-14 -8 -34 -33 -44 -54 -15 -35 -20 -39 -37 -30 -10 6
                -36 9 -56 6 -45 -5 -240 -56 -263 -68 -32 -17 -22 -62 62 -264 71 -170 93
                -286 115 -591 6 -83 9 -91 26 -87 28 8 45 77 85 343 19 129 45 276 56 325 14
                62 22 133 24 227 3 142 10 172 44 183 11 3 20 11 20 16 0 11 1 11 -32 -6z"/>
                <path class="muscle" d="M2910 5431 c0 -5 9 -13 20 -16 35 -11 43 -46 51 -205 4 -89 14 -180
                23 -215 9 -33 33 -172 52 -310 39 -268 58 -338 93 -343 19 -3 21 1 21 55 0 74
                25 303 45 410 9 45 36 131 61 190 84 198 99 259 72 280 -23 17 -254 73 -301
                73 -41 0 -48 3 -62 29 -21 41 -75 78 -75 52z"/>
                <path class="muscle" d="M4952 5365 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
                <path class="muscle" d="M827 5197 c-16 -104 -26 -191 -24 -193 7 -7 14 26 37 183 12 78 20
                155 18 170 -2 15 -16 -57 -31 -160z"/>
                <path class="muscle" d="M992 5350 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                <path class="muscle" d="M5113 5300 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
                <path class="muscle" d="M4820 5322 c0 -7 12 -71 26 -143 23 -122 24 -133 9 -148 -15 -15 -18
                -14 -32 4 -8 11 -31 52 -49 90 -20 40 -32 57 -28 40 3 -16 20 -57 37 -90 24
                -45 38 -61 57 -63 35 -5 45 -16 60 -64 25 -85 79 -130 107 -92 16 21 20 18 54
                -44 36 -64 99 -38 99 41 0 49 8 64 41 80 45 22 62 77 65 211 l3 121 -8 -95
                c-12 -136 -20 -176 -43 -205 -22 -29 -43 -32 -56 -9 -6 12 -11 -9 -14 -65 -3
                -45 -10 -87 -15 -92 -34 -34 -82 42 -109 175 -21 102 -24 102 -17 4 6 -92 -2
                -118 -37 -118 -29 0 -53 68 -104 303 -31 141 -46 194 -46 159z"/>
                <path class="muscle" d="M686 5258 c-13 -133 -16 -192 -7 -178 10 17 27 230 18 230 -3 0 -8
                -24 -11 -52z"/>
                <path class="muscle" d="M970 5271 c0 -10 -3 -34 -6 -52 -4 -23 -2 -29 4 -19 15 24 24 90 12
                90 -5 0 -10 -8 -10 -19z"/>
                <path class="muscle" d="M2162 5268 c-93 -45 -149 -154 -177 -343 -19 -130 -36 -509 -35 -794
                1 -234 -3 -278 -40 -421 -21 -82 -39 -262 -40 -402 0 -93 17 -89 80 17 27 47
                50 88 50 91 0 3 36 78 79 167 85 175 101 216 101 266 0 17 7 64 15 104 8 40
                21 187 30 327 28 457 45 669 65 778 28 150 26 185 -6 211 -34 27 -68 26 -122
                -1z"/>
                <path class="muscle" d="M3537 5278 c-38 -30 -41 -101 -8 -251 15 -65 29 -235 56 -671 34
                -541 32 -529 117 -725 34 -77 48 -121 44 -137 -5 -17 -2 -24 9 -24 8 0 16 -10
                16 -22 1 -13 2 -30 3 -39 1 -9 40 -56 87 -106 l85 -90 23 111 c29 143 20 208
                -44 326 -16 30 -35 159 -35 239 0 31 -5 62 -11 68 -8 8 -14 130 -20 385 -13
                622 -35 756 -145 877 -56 63 -136 89 -177 59z"/>
                <path class="muscle" d="M2361 5271 c-36 -23 -102 -672 -126 -1241 -4 -85 -14 -188 -22 -228
                -17 -88 -9 -177 18 -200 12 -11 15 -20 9 -32 -5 -9 -5 -22 1 -31 5 -7 9 -26 9
                -40 0 -51 124 -359 145 -359 3 0 3 35 0 78 l-6 77 20 -70 c11 -38 26 -97 35
                -129 15 -58 22 -73 30 -65 7 8 76 275 81 314 21 163 27 226 34 345 5 74 13
                148 20 163 6 16 11 40 11 55 0 15 7 45 14 67 8 22 17 87 21 145 4 58 11 124
                15 148 4 23 5 42 1 42 -3 0 -13 6 -23 13 -12 10 -19 42 -28 131 -6 65 -15 128
                -20 140 -8 20 -8 20 -13 -4 -4 -14 -13 -81 -21 -150 -9 -69 -22 -147 -30 -175
                -51 -185 -76 -283 -76 -306 0 -15 -7 -46 -15 -70 -9 -24 -18 -68 -20 -99 -2
                -30 -11 -74 -19 -96 -19 -51 -38 -234 -27 -249 5 -5 9 -41 9 -80 l-1 -70 -13
                65 c-24 123 -16 234 28 420 6 25 14 70 18 100 5 30 28 135 53 233 69 269 91
                405 91 582 1 140 -1 156 -28 235 -16 47 -41 111 -57 142 -16 31 -36 85 -45
                120 -10 35 -24 69 -33 76 -17 14 -22 15 -40 3z"/>
                <path class="muscle" d="M3413 5272 c-6 -4 -23 -41 -36 -82 -14 -41 -47 -127 -73 -190 l-48
                -115 -4 -148 c-5 -172 7 -289 49 -471 33 -146 35 -167 13 -116 -32 73 -65 229
                -73 337 -7 107 -13 134 -27 120 -4 -3 -10 -60 -15 -125 -10 -154 -15 -171 -41
                -164 -20 5 -20 2 -14 -49 3 -30 11 -108 17 -174 6 -66 17 -151 25 -190 8 -38
                17 -119 20 -180 3 -60 8 -117 11 -125 2 -8 5 -71 6 -140 2 -122 3 -127 38
                -200 20 -41 44 -102 53 -135 9 -33 19 -66 22 -74 3 -9 28 21 62 75 32 49 81
                109 109 134 85 76 90 94 98 365 4 150 2 227 -10 306 -8 58 -21 223 -30 365
                -20 345 -42 585 -70 781 -28 194 -40 222 -82 195z"/>
                <path class="muscle" d="M4966 5235 c7 -59 24 -129 29 -123 3 2 -1 35 -6 73 -13 83 -33 125
                -23 50z"/>
                <path class="muscle" d="M542 5200 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
                <path class="muscle" d="M1041 5133 c-30 -78 -47 -103 -73 -103 -20 0 -21 3 -15 68 7 78 -5
                66 -23 -23 -32 -156 -79 -247 -109 -211 -7 8 -16 36 -19 63 l-7 48 -17 -60
                c-31 -108 -73 -152 -98 -104 -14 25 -24 18 -15 -10 5 -16 15 -21 40 -21 29 0
                37 5 51 33 28 55 33 58 60 40 22 -14 26 -14 48 1 14 9 33 40 46 73 26 71 33
                79 69 86 22 5 34 18 55 63 26 55 44 114 34 114 -3 0 -15 -26 -27 -57z"/>
                <path class="muscle" d="M5121 5165 c0 -16 4 -50 8 -75 l8 -45 2 60 c0 33 -4 67 -9 75 -7 11
                -10 8 -9 -15z"/>
                <path class="muscle" d="M551 5072 c-1 -56 20 -105 58 -133 17 -12 34 -32 38 -43 6 -20 7 -20
                14 4 4 14 7 50 7 80 l0 55 -9 -40 c-11 -46 -20 -58 -40 -50 -23 9 -40 43 -54
                109 -13 60 -13 60 -14 18z"/>
                <path class="muscle" d="M3713 3398 c-69 -77 -77 -164 -70 -773 5 -461 10 -523 48 -611 21
                -50 121 -161 157 -175 15 -6 22 -17 22 -34 0 -62 19 -10 63 178 103 434 112
                622 41 911 -13 55 -27 141 -31 189 -6 80 -8 89 -32 103 -14 9 -45 43 -69 75
                -23 33 -62 74 -87 91 -42 30 -44 33 -28 50 9 10 14 18 11 18 -2 0 -14 -10 -25
                -22z"/>
                <path class="muscle" d="M2023 3368 c-18 -21 -53 -72 -108 -163 -11 -17 -20 -24 -23 -17 -11
                34 -20 1 -24 -95 -3 -60 -15 -144 -26 -188 -47 -183 -56 -441 -22 -641 19
                -111 98 -443 107 -454 3 -3 10 3 17 13 7 9 17 17 22 17 20 0 84 62 119 116 64
                96 75 149 85 409 15 383 13 712 -6 799 -19 86 -70 194 -100 213 -18 11 -23 10
                -41 -9z"/>
                <path class="muscle" d="M3570 3265 c-14 -18 -55 -70 -91 -115 -37 -45 -75 -98 -85 -118 -11
                -20 -24 -41 -30 -47 -7 -5 -15 -53 -18 -105 -4 -52 -20 -216 -37 -365 -43
                -386 -42 -429 9 -592 22 -72 44 -148 48 -167 3 -20 10 -39 15 -42 14 -9 123
                62 162 105 77 86 72 34 68 716 -3 467 -7 619 -17 647 -8 24 -9 38 -3 40 6 2 9
                20 7 39 l-3 36 -25 -32z"/>
                <path class="muscle" d="M2231 3213 c-17 -38 -30 -360 -33 -858 -3 -478 -9 -443 86 -543 42
                -45 143 -112 150 -100 1 2 17 55 35 118 19 63 40 138 49 165 23 77 17 257 -17
                558 -17 148 -31 311 -31 363 0 76 -3 94 -14 92 -8 -2 -32 24 -56 60 -44 65
                -136 172 -149 172 -4 0 -13 -12 -20 -27z"/>
                <path class="muscle" d="M1934 1753 c3 -21 20 -148 37 -283 22 -173 36 -254 49 -275 9 -16 33
                -70 51 -120 19 -49 35 -83 36 -75 7 33 -157 790 -171 790 -5 0 -6 -17 -2 -37z"/>
                <path class="muscle" d="M3827 1598 c-34 -149 -127 -604 -127 -617 1 -17 24 35 52 119 l33 95
                5 -90 c7 -114 14 -79 30 130 6 83 22 229 35 325 28 196 28 200 17 200 -5 0
                -25 -73 -45 -162z"/>
                <path class="muscle" d="M3383 1686 c15 -38 27 -147 36 -319 7 -127 15 -196 25 -216 8 -15 20
                -59 27 -97 18 -105 31 -113 24 -14 -17 223 -87 660 -107 660 -5 0 -8 -6 -5
                -14z"/>
                <path class="muscle" d="M2401 1628 c-7 -32 -11 -70 -9 -85 2 -19 8 -4 17 44 15 88 9 122 -8
                41z"/>
                <path class="muscle" d="M2357 1360 c-16 -104 -47 -374 -47 -409 0 -40 19 13 30 84 19 116 26
                129 32 59 3 -33 20 -100 38 -149 46 -129 50 -152 39 -212 -14 -73 -6 -102 9
                -35 18 80 15 115 -19 203 -37 97 -48 171 -50 344 0 72 -4 153 -8 180 -6 49 -7
                47 -24 -65z"/>
                <path class="muscle" d="M2013 1065 c0 -38 2 -53 4 -32 2 20 2 52 0 70 -2 17 -4 1 -4 -38z"/>
                <path class="muscle" d="M3420 1053 c0 -11 -16 -64 -35 -117 -19 -53 -35 -102 -35 -109 1 -21
                27 35 60 131 25 70 30 96 21 105 -8 8 -11 6 -11 -10z"/>
                <path class="muscle" d="M3804 915 c5 -53 34 -160 42 -152 2 2 -7 49 -21 103 -19 75 -24 87
                -21 49z"/>
                <path class="muscle" d="M1985 855 c-20 -66 -22 -118 -10 -240 7 -75 6 -84 -18 -130 -14 -28
                -57 -90 -96 -140 -39 -49 -78 -104 -86 -121 -27 -57 -13 -150 23 -162 11 -3
                10 3 -2 26 -9 17 -16 50 -16 74 0 47 8 61 118 208 104 138 120 184 97 275 -19
                71 -19 112 1 187 20 80 11 99 -11 23z"/>
                <path class="muscle" d="M3350 747 c0 -35 28 -165 40 -186 13 -23 13 -17 -11 100 -22 109 -29
                129 -29 86z"/>
                <path class="muscle" d="M3836 722 c-3 -5 -11 -43 -17 -84 -9 -61 -8 -80 4 -108 15 -35 15
                -35 10 26 -3 34 0 85 7 113 11 49 10 75 -4 53z"/>
                <path class="muscle" d="M2433 535 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
                <path class="muscle" d="M3392 450 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
                <path class="muscle" d="M3850 456 c0 -2 32 -44 71 -92 90 -114 111 -154 111 -215 -1 -27 -4
                -54 -7 -60 -4 -6 -29 -9 -56 -8 -57 3 -74 -6 -30 -16 76 -17 111 14 111 96 0
                52 -14 78 -110 198 -72 91 -90 110 -90 97z"/>
                <path class="muscle" d="M2412 410 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                <path class="muscle" d="M3401 355 c0 -16 4 -37 9 -45 8 -12 10 -11 9 5 -1 11 -5 31 -9 45 -8
                24 -8 24 -9 -5z"/>
                <path class="muscle" d="M2400 326 c0 -4 5 -50 11 -102 9 -83 8 -99 -6 -120 -16 -24 -18 -25
                -114 -18 -53 4 -178 3 -277 -2 l-179 -9 275 -5 c151 -3 280 -4 287 -2 6 2 18
                18 27 36 14 30 14 42 0 127 -8 51 -17 95 -19 98 -3 2 -5 1 -5 -3z"/>
                <path class="muscle" d="M3392 231 c-18 -57 -15 -115 6 -141 21 -26 16 -26 277 -21 177 3 185
                4 110 13 -44 5 -144 7 -223 4 l-142 -5 -16 31 c-14 26 -14 40 -4 90 13 69 9
                86 -8 29z"/>
                </g>
            </svg>
        </>
      )
});

export default MuscleMapBack;