import React, {useState, useEffect, useRef} from "react";
import { useLocation} from 'react-router-dom';
//styles
import { WorkoutMedia } from "../StyledComponents";
import {Column, Row, RowColumn} from "../../../components/style/Row.styled";
import {ColumnBox, RowBox, BottomFloatingContainer} from "../../../components/style/Container.styled";
import {Card, RepsContainer} from "../../../components/style/exerciceCard.styled";
import { TopRightFloatContainer} from "../../../components/style/Container.styled.jsx";
import { ExerciseDetailsInput } from "../../../components/style/CustomInput.styled.jsx";
import { CustomTextInput } from "../../../components/Components.jsx";
//API
import { UpdateExerciseDetails } from "../../../Utils/ApiRequests.jsx";


//icons
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { TbRotate360 } from "react-icons/tb";

//Paragraphs
import { H3, H4 } from "../../../components/style/paragraph.styled";
//svg
import MuscleMapFront from '../../../components/MuscleMapFront.jsx'
import MuscleMapBack from "../../../components/MuscleMapBack.jsx";
import ConvertSvgToPng from "../../../components/ConvertSvgToPng.jsx";
//translation
import WordTranslations from '../../Translations/WordTranslation.json'





const ExerciseCard = ({id, exercise, setExercises, onClose, theme, lang}) => {
    const [isMusclesOpen, setIsMuscleOpen] = useState(true);
    const [isMuscleFront, setIsMuscleFront] = useState(true);
    const svgRef = useRef(null);
    const muscleBackSvgRef = useRef(null);
    const [pngImage, setPngImage] = useState(null);
    const [muscleBackPngImage, setmuscleBackPngImage] = useState(null);
    let option = exercise.option;
    let unit = exercise.unit;
    const exercise_id = exercise.ExerciseId;
    const muscleFront = exercise?.muscles?.front.length > 0;
    const muscleBack = exercise?.muscles?.back.length > 0;

    useEffect(() => {
        setIsMuscleFront(muscleFront);
    },[exercise])

    useEffect(() => {
        if (svgRef.current) {
            ConvertSvgToPng(svgRef.current, setPngImage);
          }
        if (muscleBackSvgRef.current) {
            ConvertSvgToPng(muscleBackSvgRef.current, setmuscleBackPngImage);
          }
    },[muscleBack, muscleFront])

    const { minReps, maxReps } = (exercise?.sets?.length > 0 ? exercise?.sets?.reduce(
        (acc, curr) => ({
          minReps: Math.min(acc.minReps, curr.reps),
          maxReps: Math.max(acc.maxReps, curr.reps),
        }),
        { minReps: Infinity, maxReps: -Infinity }
      )
      :
      { minReps: '', maxReps: '' }
    );

    let { minWeight, maxWeight } = exercise?.sets?.reduce(
      (acc, curr) => {
        if (curr.weight === null) return acc; // Ohitetaan null-arvot
        return {
          minWeight: Math.min(acc.minWeight, curr.weight),
          maxWeight: Math.max(acc.maxWeight, curr.weight),
        };
      },
      { minWeight: Infinity, maxWeight: -Infinity } // Alustetaan null-arvoilla
    );

    const handleOnBlur = async (name, detailsValue, id, index) => {

        setExercises((prevState) => {
        const newData = [...prevState]
         if(newData[index] !== undefined){
           switch (name) {
             case "weight":
               newData[index].sets.weight = detailsValue;
               break;
             case "reps":
                newData[index].sets.reps = detailsValue;
               break;
             default:
               console.error("Something went wrong!")
               break;
           }
         }
        return newData;
       })
       
        await UpdateExerciseDetails(exercise_id, detailsValue, name, index, id)

    }

    if(exercise.option === 'rep'){
        option = 'x'
    }

    if(exercise.unit === 'No unit'){
        unit = ""
    }

  return (
    <Card isVisible={!!exercise}>
      <button onClick={onClose} 
              style={{ float: "right", 
              cursor: "pointer", 
              marginRight: 10, 
              background: 'transparent', 
              color: theme.colors.text ,
              borderRadius: 20,
              padding: 3,
              width: 25}}>X</button>
      <WorkoutMedia src={exercise?.media_url} width={'100%'} maxWidth={'100vw'} height={!exercise?.media_url && ('50px')}/>
      <H4 m_font={'32px'}>{exercise?.name_of_exercise}</H4>
      <Row padding={'30px 0px 0px 0px'} style={{borderBottom: `solid ${theme.colors.border} 1px`, marginBottom: 30}}>
      {exercise?.sets?.length > 0 ? ( // setti lista
        <Column width={'100%'}>
          <Row padding={'0px'}>
            <p>{WordTranslations.sets[lang]}: {exercise?.sets?.length} x {minReps}{maxReps !== minReps && ('-' + maxReps)}</p>
            <p>{WordTranslations.weight[lang]}: {minWeight}{maxWeight !== minWeight && ('-' + maxWeight)}</p>
          </Row>
            <RepsContainer>
                {exercise?.sets?.map((item, index) => (
                  <RowBox key={index} justify='space-around' align='start' style={{border: 'none'}}>
                    <p>{`${index + 1}.`}</p>
                    <Column style={{background: theme.colors.textBg ,borderRadius: '50%', width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <CustomTextInput
                            input = {ExerciseDetailsInput}
                            val={String(item?.reps)} 
                            labelName={'reps'} 
                            placeholder={'n/a'}
                            handleOnBlur={handleOnBlur} 
                            id={id}
                            index={index} 
                            style={{fontWeight: 700, height: 11}}
                        /> 
                        <p style={{marginBottom: 2}}>{option}</p>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <CustomTextInput
                            input = {ExerciseDetailsInput}
                            val={String(item?.weight)} 
                            labelName={'weight'} 
                            placeholder={'n/a'}
                            handleOnBlur={handleOnBlur} 
                            id={id}
                            index={index} 
                            style={{fontWeight: 700, height: 11}}
                        /> 
                        <p style={{marginBottom: 1}}>{unit}</p>
                      </div>
                    </Column>
                  </RowBox>
                ))}
            </RepsContainer>
        </Column>
        ):
        <p>{WordTranslations.no[lang]} {WordTranslations.sets[lang]}</p>}
      </Row>
      {exercise?.description && (
        <Column height={'fit-content'} >
            <h3 style={{borderBottom: `solid ${theme.colors.border} 1px`}}>{WordTranslations.description[lang]}</h3>
            <p style={{padding: '5px 0px', minHeight: 60}}>{exercise?.description}</p>
        </Column>
      )}

      {(muscleFront || muscleBack ) && (
        <div padding='10px 0px 10px 0px' style={{border: `solid ${theme.colors.border} 1px`, borderRadius: 6}}>
            <Row  style={{borderBottom: `solid ${theme.colors.border} 1px`}}>
                <p>{WordTranslations.muscle.plural[lang]}</p>
                <div onClick={() => setIsMuscleOpen(!isMusclesOpen)}>
                    {isMusclesOpen ?
                        <IoIosArrowDown size={20}/>
                        :
                        <IoIosArrowBack size={20}/>
                    }
                </div>
            </Row>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: !isMusclesOpen && (0), overflow: 'hidden' }}>
                <div style={{display: 'none'}}>
                    <MuscleMapFront ref={svgRef} width={200} height={375}  activeMuscles={exercise?.muscles?.front}/>
                    <MuscleMapBack ref ={muscleBackSvgRef} width={200} height={375}  activeMuscles={exercise?.muscles?.back}/>
                </div>
                {(muscleFront && muscleBack) && (
                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10, paddingRight: 15}}>
                        <TbRotate360 
                                size={25} 
                                color={theme.colors.text}
                                onClick={() => setIsMuscleFront(!isMuscleFront)}/>
                    </div>
                )}
                {(pngImage && isMuscleFront) && (<img src={pngImage} alt="Converted PNG" />)}
                {(muscleBackPngImage && !isMuscleFront) && (<img src={muscleBackPngImage} alt="Converted PNG" />)}
            </div>
        </div>
      )}
      
    </Card>

  );
};

export default ExerciseCard;