import React, {useState, useEffect} from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
//Components
import { ScrollView, CustomPopUp, Modal } from "../../../components/Components.jsx";
import { CustomButton, ExitButton, PublishButton } from "../../../components/Buttons.jsx";
import PublishDetails from "../Public/PublishDetails.jsx";
//API
import { PublishExercise, GetWorkoutVisibility, DeleteWorkout, UnpublishWorkout, fetchPersonalExercisesData, fetchPersonalData } from "../../../Utils/ApiRequests.jsx";
import { CheckStripeConnection } from "../../Stripe/StripeAPI.jsx";
//Style
import { PartContainer, WorkoutContent, WorkoutRow, Title } from "../StyledComponents.jsx";
import { StyledPublishButton } from "../../../components/style/Buttons.styled.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";
import { DescriptionContainer } from "../../../components/style/Container.styled.jsx";
//icon
import { MdEditSquare, MdOutlinePublish } from "react-icons/md";
import { IoTrash, IoCloudOffline } from "react-icons/io5";
// utils
import { SetToLocalStorage, getFromLocalstorage} from "../../../Utils/Utils.jsx";
import { useData, useLanguage } from "../../../Utils/UseContext.jsx";
import { HasRole } from "../../../Utils/Role.jsx";
//Translations
import WorkoutPartListTranslations from '../../Translations/WorkoutPartList.json'
import WordTranslations from '../../Translations/WordTranslation.json'


const WorkoutPartList = ({theme}) =>{
    const {language} = useLanguage();
    const { id } = useParams();
    const {personalData, setPersonalData} = useData();
    const [visibility, setVisibility] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {accessRight, role} = location.state || {};
    const values = personalData?.data?.find(item => item.workout_id === id);
    let dataList = values?.parts || [];

    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WorkoutPartListTranslations?.supported[lang] ? WorkoutPartListTranslations?.supported[lang] : WorkoutPartListTranslations?.supported['en-EN']

    useEffect(() => {

      if(dataList.length === 0 || dataList === undefined){
        fetchData(id, setPersonalData);
        const data = personalData?.data?.find(item => item.workout_id === id);
        dataList = data?.parts;
      }
    },[]);

    useEffect(() => {
      const getVisibility = async () => {
        const id = values?.id;
        const visible = await GetWorkoutVisibility(id);
        setVisibility(visible);
        setIsPublished(false);
      }
      getVisibility()
    },[isPublished])

    const fetchData = async (workout_id, setPersonalData) => {
      if(!personalData?.data){
        await fetchPersonalData(setPersonalData);
        await fetchPersonalExercisesData(workout_id, setPersonalData);
      }else{
        await fetchPersonalExercisesData(workout_id, setPersonalData);
      }
    }

    const closeModal = () => {
      setTimeout(() => {
        setIsOpen(false);
      }, 0);
      setIsPublished(true);
    };
  
    const handleOnOfflineSave = (value) => {
      const local = getFromLocalstorage("myWorkout");
      const obj = local ? local : {};

      const id = value?.id;
      const name = value?.name;
      const list = personalData?.data[id][name]
      for(let i = 0; i < list.length; i++){
        const lis = Object.values(list[i])[0]
        for(let j = 0; j < lis.length; j++){
          if(lis[j].media_url){
            lis[j].media_url = "media";
          }
        }
      }
      
      Object.assign(obj, {[id]: personalData?.data[id]})

      SetToLocalStorage("myWorkout", obj)

    }

    const handleData = (data, workout_name) => {
      const id = data?.part_of_workout_id;
      const values = data?.part_of_workout_exercises;
      const part_name = data?.part_of_workout_name;

      // name = workout name
      navigate(`/workout/my/${id}`, { state: { workout_name, part_name, values, id } });
    }

    const Unpublish = async (id) => {
      const unpublished = await UnpublishWorkout(id);

      setIsPublished(unpublished);
    }

    const handleEdit = (event, data, index) => {
      event.stopPropagation();
      const part_id = values?.parts[index]?.part_of_workout_id;
      //Object.keys(data)[0];
      const workoutId = values.workout_id;
      const workoutName = values.workout_name;

      navigate(`/workout/edit/${part_id}`, { state: { data, workoutName, workoutId, index } });
    }

    const handleOnDelete = async (id) => {


        const deleteItem = () => {
          const newItems = personalData?.data?.filter(item => item.workout_id !== id);
          return JSON.stringify(newItems);
        }

        await DeleteWorkout(id, deleteItem, setPersonalData)
        if(visibility){ // true means that is published
          Unpublish(id);
        }
        navigate('/workout/my');
    }

    const part = Object.values(dataList).map((data, index) => {

        const name = data?.part_of_workout_name;
        const exercisesCount = data?.part_of_workout_exercises?.length;

        return (
            <WorkoutRow onClick={() => handleData(data, values?.workout_name)} height={2.3} columns={'1fr 0.2fr 0.2fr'}>
                <WorkoutContent align={'flex-start'} >
                    <p style={{fontSize:20}}>{name}</p>
                </WorkoutContent>
                <WorkoutContent align={'center'} >
                    <p style={{fontSize:15}}>{exercisesCount} exercises</p>
                </WorkoutContent>
                <WorkoutContent align={'flex-end'}>
                  {accessRight === 'owner' && (
                    <MdEditSquare onClick={(event) => handleEdit(event, data, index)} color={'white'} size={20}/>
                  )}
                </WorkoutContent>
            </WorkoutRow>
        )
     })
     
     const HeaderButtons = () => {
      const [beginger, setBeginger] = useState(false);
      const [admin, setAdmin] = useState(false);
      const [isStripe, setIsStripe] = useState(false);
      const [isPopUpOpen, setIsPopUpOpen] = useState(false);
      const [content, setContent] = useState("");
      
     
      useEffect(() => {
        const checkRole = async (role, set) => {
            const result = await HasRole(role);
            set(result);
        };

        const checkStripe = async () => {
          const status = await CheckStripeConnection();
          setIsStripe(status)
        }
        
        checkRole('beginner', setBeginger);
        checkRole('admin', setAdmin);
        checkStripe();
    }, []);
    
    const ConfirmationPopUp = (id) => {

      const buttons = [
        // { label: 'Ei', onClick: handleCancel, color: Colors.red},
        { label: `${WordTranslations.yes[lang]}`, onClick:() =>  handleOnDelete(id)},
        { label: `${WordTranslations.no[lang]}`, onClick: () => setIsPopUpOpen(false)}
      ];

      const message =`${WorkoutPartListTranslations.delete_message[lang]} "${values?.workout_name}"? \n`
      setContent({message: message, buttons: buttons})
      setIsPopUpOpen(!isPopUpOpen);
    }

      return (
        <>
          {accessRight === 'owner' ? 
            <IoTrash onClick={() => ConfirmationPopUp(values?.workout_id)} size={20} style={{cursor: 'pointer'}}/> : <div></div>
          }
          {beginger && (
            <>
            {/* {(accessRight === 'owner' && isStripe) && ( */}
              <CustomButton
                icon={<MdOutlinePublish size={20}/>}
                label={visibility ? `${WorkoutPartListTranslations.unpublish[lang]}` : `${WorkoutPartListTranslations.publish[lang]}`}
                button={StyledPublishButton}
                onClick={visibility ? () => Unpublish(values?.workout_id) : () => setIsOpen(!isOpen)}
                />
            {/* )} */}
            {admin && (
              <CustomButton 
                button={StyledPublishButton}
                onClick={() => handleOnOfflineSave(values)}
                label={<span><IoCloudOffline />{" Save for offline use"}</span>}
              />
            )}
            </>
          )}
          <ExitButton onClick={() => navigate('/workout/my')} color={'white'} label={'X'}/>
          <CustomPopUp content = {content} isOpen={isPopUpOpen} setIsOpen={() => setIsPopUpOpen(!isPopUpOpen)} div={DescriptionContainer}/>
        </>
      )
     }

    return (
      <>
          <PartContainer justify={'none'}>
            <Row padding={'0px'}>
                <HeaderButtons />
            </Row>
            <Row padding={'0px 0px 10px 0px'}>
              <Title>{values?.name}</Title>
            </Row>
            <ScrollView items = {part} renderItem={(item) => <span>{item}</span>}/>
          </PartContainer>
          <Modal
            isOpen={isOpen}
            onClose={() => closeModal()}
            children = { <PublishDetails data={values} accessRole={accessRight} theme={theme} close={closeModal}/>
            }
          />
      </>
    )
  }

  export default WorkoutPartList;