import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth, useLanguage} from "../Utils/UseContext.jsx";
import { NavBar } from "./style/NavBar.styled.jsx";
import LanguageChanger from "./LanguageChanger.jsx";
//API
import { HandleLogout } from "../Utils/ApiRequests.jsx";
//icon
import { CgProfile } from "react-icons/cg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { LogoutIcon } from "./AuthIcon.jsx"; 
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

//style
import { TransparentButton, StyledSubmitButton } from "./style/Buttons.styled.jsx";
import { Row } from "./style/Row.styled.jsx";
import { BurgerMenu, NavItems } from "./style/nav.style.jsx";
import { NavContainer } from "./style/MainGrid.styled.jsx";
import { BottomFloatingContainer } from "./style/Container.styled.jsx";
//Translations
import NavTranslations from '../Main/Translations/Nav.json'
import WordTranslations from '../Main/Translations/WordTranslation.json'
import NewWorkoutTranslations from '../Main/Translations/NewWorkout.json'




import '../components/style/Styles.css'

export const ProfileIcon = () => {
    const navigate = useNavigate();
    const {state} = useAuth();
    const show = state.isAuthenticated;

    // if(!show) return;

    return(
        <>
            {show && (
                <CgProfile size={30} onClick={() => navigate('/profile')} style={{cursor:'pointer', margin:10}}/>
            )}
        </>
    )
}

export const BottomNavBar = () => {
    const {language} = useLanguage();
    const {state} = useAuth();
    const showNav = state.isAuthenticated;
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = NavTranslations?.supported[lang] ? NavTranslations?.supported[lang] : NavTranslations?.supported['en-EN']

    return(
        showNav && (
        <NavBar>
            <Link to="/home" className="nav-link">{NavTranslations.home[lang]}</Link>
            <Link to="/workout/my" className="nav-link">{NavTranslations.workout[lang]}</Link>
            <Link to="/calendar" className="nav-link">{NavTranslations.calendar[lang]}</Link>
            {/* <Link to="/profile" className="nav-link">Profile</Link> */}
            {/* <button onClick={() => navTo('profile')}>LINKKI</button> */}
        </NavBar>
        )
    )
}

export const WorkoutNav = ({lang}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = useAuth();
    const isAuthenticated = state.isAuthenticated;
    const isActive = location.pathname === '/workout/my' ? true : false;

    if(!isAuthenticated) return;

    return(
        <Row margin={'0 0 10px 0'} maxwidth={'400px'}>
            <TransparentButton width={'100px'} height={'32px'} isactive={isActive} onClick={() => navigate('/workout/my')}>
                {WordTranslations.my[lang]}
            </TransparentButton>
            <TransparentButton width={'100px'} height={'32px'} isactive={!isActive} onClick={() => navigate('/workout/public')}>
                {WordTranslations.public[lang]}
            </TransparentButton>
        </Row>
    )
}

export const SubWorkoutNav = ({activeData, setActiveData, lang}) => {
    const {state} = useAuth();
    const isAuthenticated = state.isAuthenticated;

    if(!isAuthenticated) return;

    if(!activeData){
        activeData = 'all';
    }

    const handleIsActive = (key) => {
        setActiveData(key)
    }

    return(
        <Row margin={'0 0 10px 0'} justify={'space-between'}>
            <Row padding={'0'} width={'fit-content'}>
                <TransparentButton height={'20px'} isactive={activeData === 'all'} style={{fontSize: 12}} onClick={() => handleIsActive('all')}>
                    {WordTranslations.all[lang]}
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={activeData === 'training_program'} style={{fontSize: 12}} onClick={() => handleIsActive('training_program')}>
                    {NewWorkoutTranslations.training_program[lang]}
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={activeData === 'training_plan'} style={{fontSize: 12}} onClick={() => handleIsActive('training_plan')}>
                    {NewWorkoutTranslations.training_plan[lang]}
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={activeData === 'video'} style={{fontSize: 12, display: 'none'}} onClick={() => handleIsActive('video')}>
                    Videos
                </TransparentButton>
            </Row>
            <Row padding={'0'} width={'fit-content'}>
                <TransparentButton height={'20px'} isactive={true} style={{fontSize: 12, cursor:'pointer', display: 'none'}} onClick={() => console.log("sort")}>
                    Sort
                </TransparentButton>
                <TransparentButton height={'20px'} isactive={true} style={{fontSize: 12, cursor:'pointer', display: 'none'}} onClick={() => console.log("filter")}>
                    Filters
                </TransparentButton>
            </Row>

        </Row>
    )
}

export const Nav = ({theme}) => {
    const {language} = useLanguage();
    const {state, dispatch} = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = NavTranslations?.supported[lang] ? NavTranslations?.supported[lang] : NavTranslations?.supported['en-EN']

    // if(!state.isAuthenticated) return;
    const handleLogout = async () => {
        await HandleLogout(dispatch)
    }

    return(
        <NavContainer isOpen={isOpen} theme={theme}>
        <BurgerMenu onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <AiOutlineClose size={30} color={theme.colors.text} /> : <AiOutlineMenu size={30} color={theme.colors.text} />}
        </BurgerMenu>
          <NavItems>
          {state.isAuthenticated ?
          <>
            <li onClick={() => setIsOpen(!isOpen)}>
                <Link to="/home">{NavTranslations.home[lang]}</Link>
            </li>
            <li onClick={() => setIsOpen(!isOpen)}>
                <Link to="/workout/my">{NavTranslations.workout[lang]}</Link>
            </li>
            <li onClick={() => setIsOpen(!isOpen)}>
                <Link to="/calendar">{NavTranslations.calendar[lang]}</Link>
            </li>
            <li onClick={() => setIsOpen(!isOpen)}>
                <Link to="/profile">{NavTranslations.profile[lang]}</Link> 
            </li>
            <li>
                <LogoutIcon onClick={handleLogout} text={NavTranslations.logout[lang]}/>
            </li>
            </>
            :
            <>
                <LanguageChanger />
                <li onClick={() => setIsOpen(!isOpen)}>
                    <Link to="/login">{NavTranslations.login[lang]}</Link> 
                </li>
                <li onClick={() => setIsOpen(!isOpen)}>
                    <Link to="/registeration">{NavTranslations.register[lang]}</Link> 
                </li>
            </>
            }
          </NavItems>
        </NavContainer>

    )
}

export const ExerciseNavigation = ({ currentIndex, totalExercises, onPrevious, onNext, onDone, isTimer, isCompleted }) => {
    const isAtStart = currentIndex === 0;
    const isAtEnd = currentIndex === totalExercises - 1;

  
    return (
      <BottomFloatingContainer bottom="15px">
        <Row padding="0px 10px 0px 10px">
          {/* Back Button */}
          <TransparentButton onClick={onPrevious} disabled={isAtStart} isactive={!isAtStart}>
            <IoIosArrowBack size={30} />
            <span style={{ marginLeft: 5 }}>Back</span>
          </TransparentButton>
  
          {/* Done Button */}
          <StyledSubmitButton margin="0px 10px 0px 10px" onClick={onDone} disabled={!isTimer} style={{textDecoration: isCompleted && ('line-through')}}>
            {isAtEnd ? 'Finish' : 'Done'}
          </StyledSubmitButton>
  
          {/* Forward Button */}
          <TransparentButton onClick={onNext} disabled={isAtEnd} isactive={!isAtEnd}>
            <span style={{ marginRight: 5 }}>Next</span>
            <IoIosArrowForward size={30} />
          </TransparentButton>
        </Row>
      </BottomFloatingContainer>
    );
  };
