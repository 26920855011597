import styled from 'styled-components';

// export const NavContainer = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 100%;
//   //background: ${({theme}) => theme.colors.text};
//   z-index: 1000;
//   display: flex;
//   justify-content: flext-start;
// //   align-items: start;
//   transition: transform 0.3s ease-in-out;
//   transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};

//   @media screen and (min-width: 600px) { /* Desktop-näkymä */
//     position: fixed;
//     top: 0;
//     left: auto;
//     right: 0;
//     width: 150px; /* Kiinteä leveys desktopille */
//     height: 100vh;
//     transform: none; /* Navigointi näkyy aina desktopilla */
//     border-left: 1px solid ${({theme}) => theme.colors.border};
//   }
// `

export const NavContainer = styled.nav`
  grid-area: nav;
  position: fixed; /* Navigaatio pysyy paikallaan */
  top: 0;
  left: 100px;
  height: 100vh;
  width: ${({ isOpen }) => (isOpen ? "200px" : "0")};
  background: ${({ theme }) => theme.colors.background};
  overflow: hidden;
  transition: width 0.3s ease;

  @media screen and (min-width: 600px) {
    position: static;
    width: 150px;
  }
`;

export const BurgerMenu = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1100;
  cursor: pointer;

  @media screen and (min-width: 600px) { 
    display: none; /* Hampurilaisvalikko piilotetaan desktop-näkymässä */
  }
`;

export const NavItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  li {
    text-decoration: none;
    margin: 20px 0;
    font-size: 1.2rem;

    &:hover {
      color: lightgray;
    }

    & > a{
        text-decoration: none;
        color: ${({theme}) => theme.colors.text};
        cursor: pointer;
    }
    a:hover {
        color: lightgray;
    }
  }
`;