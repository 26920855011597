import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


//style
import { StyledWeekContainer, StyledWeekCard  } from "../../components/style/Container.styled";
import { Column, Row } from "../../components/style/Row.styled";
import { CustomButton } from "../../components/Buttons";
import { StyledAnalyticsButton } from "../../components/style/Buttons.styled";
//utils
import { DateNow, MillisToMinutes } from "../../Utils/Utils";
import { HasRole } from "../../Utils/Role";

//Api
import { GetWorkoutEvents, GetCompletedWorkout } from "../../Utils/ApiRequests";
//icons
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { IoCalendarOutline } from "react-icons/io5";
// Translation
import WordTranslations from '../Translations/WordTranslation.json'
import { weekdays } from "moment";


const WeekExercise = ({theme, lang}) => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [completedWorkouts, setCompletedWorkouts] = useState([]);
    const [startOfWeek, setStartOfWeek] = useState(new Date());

    const today = DateNow();
    const days = [`${WordTranslations.weekdays.monday[lang]}`,`${WordTranslations.weekdays.tuesday[lang]}`,`${WordTranslations.weekdays.wednesday[lang]}`,`${WordTranslations.weekdays.thursday[lang]}`,`${WordTranslations.weekdays.friday[lang]}`,`${WordTranslations.weekdays.saturday[lang]}`,`${WordTranslations.weekdays.sunday[lang]}`];

     // Lasketaan viikon alku ja loppu
     const getWeekBoundaries = (startOfWeek) => {
        const start = new Date(startOfWeek);
        start.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1); // Viikon ensimmäinen päivä (maanantai)
        start.setHours(0, 0, 0, 0);

        const end = new Date(start);
        end.setDate(start.getDate() + 6); // Viikon viimeinen päivä (sunnuntai)
        end.setHours(23, 59, 59, 999); 

        return { start, end };
    };

    const { start: weekStart, end: weekEnd } = getWeekBoundaries(startOfWeek); 

    const fetchEvents = async (start, end) => {
        //const { start, end } = getWeekBoundaries(startOfWeek);

        // Hae eventit tietokannasta
         const fetchedEvents = await GetWorkoutEvents(start.toISOString(), end.toISOString()); // Oikea endpoint ja hakulogiikka
         
         setEvents(fetchedEvents);
    };

    const fetchCompletedWorkouts = async (start, end) => {
        const completedWorkouts = await GetCompletedWorkout(start.toISOString(), end.toISOString());
        setCompletedWorkouts(completedWorkouts);
    }

    useEffect(() => {

        fetchEvents(weekStart, weekEnd);
        fetchCompletedWorkouts(weekStart, weekEnd);
    },[startOfWeek])

    const checkIfCompleted = (date, completed) => {
        const checkDate = today >= date;
          return (completed && checkDate) ? true : false;
    }

    // Luo viikonpäivien päivämäärät
    const weekDays = days.map((day, index) => {
      const date = new Date(weekStart);
      date.setDate(weekStart.getDate() + index);


      const event = events?.find(
        (e) => new Date(e.date_time).toDateString() === date.toDateString()
      );

       const completedWorkout = completedWorkouts?.completed?.completed?.find(
         (e) => new Date(e.date).toDateString() === date.toDateString()
       );


      return {
        day,
        date,
        isToday: date.toDateString() === today.toDateString(),
        isPast: date < today && date.toDateString() !== today.toDateString(),
        event,
        completedWorkout
      };
    });

    const handleCardClick = async (event) => {
        const isRole = await HasRole('novice');

        // päivä tarkistus, jos mennyt ja sisältää completed mennään analytiikkaan.

        if (event) {
          const name = event?.name_of_exercise;
          const values = event?.workout_value;
          const id = event?.part_of_workout_id;
          navigate(`/workout/my/${id}`, { state: { name, values, id }}); // Navigointi eventin id:n avulla
        }else{
            if(!isRole){
                toast.info("To use this feature, the role must be novice.");
                return;
            }else{
                navigate(`/analytics`);
            }
        }
      };

          // Funktiot viikon vaihtamiseen
    const goToPreviousWeek = () => {
        const previousWeek = new Date(startOfWeek);
        previousWeek.setDate(startOfWeek.getDate() - 7); // Siirrä viikkoa taaksepäin
        setStartOfWeek(previousWeek);
    };

    const goToNextWeek = () => {
        const nextWeek = new Date(startOfWeek);
        nextWeek.setDate(startOfWeek.getDate() + 7); // Siirrä viikkoa eteenpäin
        setStartOfWeek(nextWeek);
    };

    // Formatoi viikon päivät
    const formatWeekDates = () => {
        const start = new Date(startOfWeek);
        const end = new Date(startOfWeek);
        end.setDate(start.getDate() + 6); // Lasketaan viikon viimeinen päivä
        return `${start.toLocaleDateString("fi-FI")} - ${end.toLocaleDateString("fi-FI")}`;
    };

    const navToAnalytics = async () =>{
      const isRole = await HasRole('novice');

      if(!isRole){
        toast.info("To use this feature, the role must be novice.");
        return;
      }else{
        navigate('/analytics')
      }
    }

    return(
        <>
        <Row>
            <span onClick={goToPreviousWeek}>{'<'}</span>
            <span>{formatWeekDates()}</span>
            <span onClick={goToNextWeek}>{'>'}</span>
        </Row>
        <StyledWeekContainer>
        {weekDays.map(({ day, date, isToday, isPast, event, completedWorkout }, index) => (
            <StyledWeekCard 
                key={index}
                isToday={isToday}
                isPast={isPast}
                hasEvent={!!event}
                theme={theme}
                onClick={() => handleCardClick(!checkIfCompleted(date, completedWorkout) && event)} 
                >
                <Row padding={'5px'}>
                    <span>{day}</span>
                    <span>{date.toLocaleDateString("fi-FI")}</span>
                </Row>
                <Row padding={'5px'} justify={'center'} justify_mobile={'center'}>
                    {completedWorkout ?
                        <Column>
                            <span>{completedWorkout?.part_of_workout_name}</span>
                            <span>{WordTranslations.duration[lang]}: {Math.round(MillisToMinutes(completedWorkout?.duration))} min</span>
                            <span>{WordTranslations.completed[lang]}: {completedWorkout?.completion_rate}%</span>
                        </Column>
                    :
                        <span>{event ? `${WordTranslations.training_day[lang]}`: `${WordTranslations.break_day[lang]}`}</span>
                    }
                </Row>
            </StyledWeekCard>
        ))}
            <StyledWeekCard border={'none'}>
                <Row>
                    <Column align={'center'}>
                    <CustomButton button={StyledAnalyticsButton} onClick = {() => navToAnalytics()} label={<TbBrandGoogleAnalytics size={20}/>}/>
                        <p>{WordTranslations.analytics[lang]}</p>
                    </Column>
                    <Column align={'center'}>
                    <CustomButton button={StyledAnalyticsButton} onClick = {() => navigate('/calendar')} label={<IoCalendarOutline size={20}/>}/>
                        <p>{WordTranslations.calendar[lang]}</p>
                    </Column>
                </Row>
            </StyledWeekCard>
      </StyledWeekContainer>
      </>
    )

}

export default WeekExercise;