import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

//components
import {useAuth, useLanguage} from "../Utils/UseContext.jsx";
import { Geolocation } from "../Utils/Geolocation.jsx";
import LanguageChanger from "../components/LanguageChanger.jsx";
import { Datepicker } from "../Utils/Utils.jsx";

//API
import { UpdateProfile, UpdateUsername, UpdateContactInfo, GetSettings, VerifyEmailUpdate, VerifyPasswordUpdate, UserAuthorizarion } from "../Utils/ApiRequests.jsx";
import { CreateStripeAccount, StripeAccountLink, UpdateStripeAccount } from "./Stripe/StripeAPI.jsx";
//Utils
import { GetdecodeToken, DateToString, CheckAge } from "../Utils/Utils.jsx";
import { encryptData, decryptData } from "../Utils/encryption.js";
import { checkPwdIsSame, checkPwdLen, isPwdStrong } from '../Auth/AuthUtils.jsx';
//Button

//style
import { CustomDatepicker } from "../components/style/Date.styled.jsx";
import { Column, Row } from "../components/style/Row.styled.jsx";
import { ProfileContent, ProfileContainer, RoundImgContainer, StyledUserDetails, ContactDetails, NativeSelect, OverflowTextContent } from "../components/style/Container.styled.jsx";
import '../components/style/Styles.css'
import 'react-datepicker/dist/react-datepicker.css';
//icon
import Logo from '../components/style/images/Logo.png'
import {MdOutlineLocationCity, MdImportContacts, MdArrowForward, MdOutlineSettings, MdOutlineEmail, MdPersonOutline, MdOutlineCake, MdOutlineKey, MdLocationOn, MdOutlineSmartphone, MdOutlineTransgender, MdAdd, MdEditSquare, MdDone } from "react-icons/md";
import { FaEarthEurope } from "react-icons/fa6";
import { FaRegAddressCard, FaCheckCircle, FaChevronRight } from "react-icons/fa";
//JSON
import countries from '../json/countries.json'
//Translation
import WordTranslations from './Translations/WordTranslation.json'

const override = {
    display: "block",
    margin: "25vh auto",
    // borderColor: "red",
  };


const AddUsername = ({theme, profile, setProfile}) => {
    const [username, setUsername] = useState("");

    const handleUsernameChange = async () => {
        const value = await UpdateUsername(username)
        const updatedProfile = { ...profile, username: value?.username };
        setProfile(updatedProfile);
        // sessionStorage.setItem('profile', JSON.stringify(updatedProfile));
    }

    return(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor:'pointer'}}>
            <input 
                placeholder={'username'}
                value={username}
                onChange={e => setUsername(e.target.value)}
                style={{border: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, width: '100%'}}/>
            <MdAdd size={20} onClick={handleUsernameChange}/>
        </div>
    )
}

const ChangeEmail = ({theme, profile, setProfile}) => {
    const [email, setEmail] = useState(profile?.email);

    const handleEmailChange = async () => {
        const value = await VerifyEmailUpdate(email)
        const updatedProfile = { ...profile, email: email };
        setProfile(updatedProfile);
        // sessionStorage.setItem('profile', JSON.stringify(updatedProfile));
    }

    return(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor:'pointer'}}>
            <input 
                placeholder={'email'}
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{border: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, width: '100%'}}/>
            <MdAdd size={20} onClick={handleEmailChange}/>
        </div>
    )
}

const ChangePassword = ({theme, lang}) => {
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [correctPWD, setCorrectPWD] = useState(false);

    const handlePasswordChange = async () => {
        // Jos salasana oikein avaa uudet inputit
        const success = await UserAuthorizarion(password);

        if(success?.status){
            setCorrectPWD(success?.status);
            toast.success(success?.message);
        }else{
            toast.warn(success?.message)
        }

    }

    const handleNewPassword = async () => {
        if(!checkPwdLen(newPassword) || !checkPwdIsSame(newPassword, newPassword2) || !isPwdStrong(newPassword)){
            toast.warn("The new password is not valid!");
            return;
        }

        const message = await VerifyPasswordUpdate(password, newPassword);
        if(message?.status){
            setCorrectPWD(false);
            setPassword("");
            setNewPassword("");
            setNewPassword2("");
            toast.info("Check your email and verify password changes.");
        }else{
            toast.warn(message?.message);
            setPassword("");
            setNewPassword("");
            setNewPassword2("");
            setCorrectPWD(false);
        }

    }

    return(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width:'100%'}}>
            <Column>
            <p style={{marginTop: 10}}>{WordTranslations.change[lang]} {WordTranslations.password[lang].toLowerCase()}</p>
            {!correctPWD ?
            <Row padding={'0'}>
                <input 
                    placeholder={'Current password...'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    style={{borderBottom: 'solid 1px ', borderTop: 'none', borderLeft: ' none', borderRight: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, margin: '5px 0px 5px 0px'}}/>
                <FaChevronRight size={20} onClick={() => handlePasswordChange()} style={{cursor: 'pointer'}}/>
            </Row>
            :
            <>
            <Column width={'80%'}>
                <input 
                    placeholder={'New password...'}
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    type="password"
                    style={{borderBottom: 'solid 1px ', borderTop: 'none', borderLeft: ' none', borderRight: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, margin: 10}}/>
                <input 
                    placeholder={'New password again...'}
                    value={newPassword2}
                    onChange={e => setNewPassword2(e.target.value)}
                    type="password"
                    style={{borderBottom: 'solid 1px ', borderTop: 'none', borderLeft: ' none', borderRight: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, margin: 10}}/>
            </Column>
            <FaCheckCircle size={20} onClick={() => handleNewPassword()} style={{cursor: 'pointer'}}/>
            </>
            }
            </Column>
        </div>
    )
}

const UserDetails = ({theme, profile, setProfile}) => {
    const [inputDisabled, setInputDisabled] = useState(true)
    const [openDropdown, setOpenDropdown] = useState(false)
    const [selectedOption, setSelectedOption] = useState();
    const [options, setOptions] = useState([
        {value: 'E', label: 'Empty'},
        { value: 'M', label: 'Men' },
        { value: 'W', label: 'Women' },
        { value: 'O', label: 'Other' },
        ]);
    
    useEffect(() => {
        setSelectedOption(profile?.gendre);
    },[profile])
   
    const dropdown = () => {
        return(
            <NativeSelect value={selectedOption} onChange={handleChange} onClick={() => setOpenDropdown(!openDropdown)}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </NativeSelect>
        )
    }

    const handleChange = async (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        const updatedProfile = {...profile, gendre: value}
        await UpdateProfile('gendre',value);
        setProfile(updatedProfile);
        sessionStorage.setItem('profile', JSON.stringify(updatedProfile))
        setOpenDropdown(false);
      };

    const handleDateChange = async (date) => {
        setProfile(prev => ({...prev, birthday: date})); 
        //Tallennetaan tieotkantaan
    }

    const handleEdit = async () => {
        if(inputDisabled){
            setInputDisabled(!inputDisabled);
        }else{
            await UpdateProfile('bday',profile?.birthday);
            const encrypt = encryptData(profile);
            sessionStorage.setItem('profile',encrypt);
            setInputDisabled(!inputDisabled);
        }
    }
  
    return(
        // <>
            <StyledUserDetails>
                {/* <OverflowTextContent>    */}
                <div style={{cursor: 'pointer', position: 'relative', top: '-2em', left: '7em'}} onClick={handleEdit}>{inputDisabled ? <MdEditSquare size={17} color={theme.colors.text} /> : <MdDone size={17} color={theme.colors.text} />}</div>
                <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{cursor: 'not-allowed'}}><MdPersonOutline size={20}/> {profile?.firstname} {profile?.lastname}</Row>
                <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{marginLeft: '2.26em', cursor: 'not-allowed'}}>{profile?.username ? `${profile?.username}` : <AddUsername profile={profile} setProfile={setProfile} theme={theme} />}</Row>
                <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{cursor: 'not-allowed'}}> <MdOutlineEmail size={20}/> {inputDisabled ? `${profile?.email}` : <ChangeEmail profile={profile} setProfile={setProfile} theme={theme} />}</Row>
                <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{cursor: 'not-allowed'}}><MdOutlineKey size={20}/> {profile?.role}</Row>
                <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{cursor: inputDisabled && ('not-allowed')}}><MdOutlineCake size={20}/> {profile?.birthday && inputDisabled ? DateToString(new Date(profile?.birthday), true) : <Datepicker handleDateChange={handleDateChange} selected={profile?.birthday} theme={theme} />}</Row>
                <Row justify={'flex-start'} justify_mobile={'flex-start'} style={{display: 'flex', alignItems: 'center'}}>
                    <MdOutlineTransgender size={24} />
                    {dropdown()}
                </Row>
                {/* </OverflowTextContent> */}
            </StyledUserDetails>
        // </>
    )
}

const ContactInfo = ({theme, contactInfo, setContactInfo}) => {
    const [inputDisabled, setInputDisabled] = useState(true)
    const [contactValue, setContactValue] = useState()

    useEffect(() => {
        setContactValue({
            address: contactInfo?.address ? contactInfo?.address :  "",
            postcode: contactInfo?.postcode || "",
            city: contactInfo?.city || "",
            country: contactInfo?.country || "",
            phone: contactInfo?.phone || ""
        })
    },[contactInfo])

    const getCountryName = (countryCode) => {
        const country = countries.find((c) => c.code === countryCode);
        return country ? country.name : ""; // Palautetaan maan nimi, jos löytyy, muuten koodi
    };

    const CountrySelector = ({ selectedCountry, setSelectedCountry }) => {
        // const [selectedCountry, setSelectedCountry] = useState('');
      
        const handleChange = (e) => {
            const selectedCode = e.target.value;
            setSelectedCountry(selectedCode);  // Tallennetaan valittu maa-koodi
        };
      
        return (
          <>
            <select id="country-select" disabled={inputDisabled} onChange={handleChange} value={selectedCountry} style={{background: 'transparent', border: 'none', height: '1.5em'}} >
              <option value="" >-- Choose a country --</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </>
        );
      };
    
    const Input = ({name, placeholder, val}) => {
        const [text, setText] = useState("")
        const [focused, setFocused] = useState({"": false})

        const handleOnFocus = (value, key) => {
            setFocused({[key]: true});
            setText(value);
          }

        const handleOnChange = (value) => {
            setText(value)
        }

        return (
            <input 
            disabled={inputDisabled}
            name={name}
            placeholder={placeholder}
            value={focused[name] ? text : val}
            onFocus={() => (handleOnFocus(val, name))}
            onChange={e => handleOnChange(e.target.value)}
            onBlur={() => handleOnBlur(name, text)}
            style={{border: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, width: '100%', height: '2em'}}/>
        )
    }

    const handleOnBlur = (name, value) => {
        const newContactInfo = {...contactValue, [name]: value}
        setContactValue(newContactInfo);
    }

    let address = contactInfo?.address && inputDisabled ? contactInfo?.address : <Input name={"address"} placeholder={"Address"} val={contactValue?.address}/>
    let postcode = contactInfo?.postcode && inputDisabled ? contactInfo?.postcode : <Input name={"postcode"} placeholder={"Postcode"} val={contactValue?.postcode}/>
    let city = contactInfo?.city && inputDisabled ? contactInfo?.city : <Input name={"city"} placeholder={"City"} val={contactValue?.city}/>
    let country = contactInfo?.country && inputDisabled ? getCountryName(contactInfo?.country) : <CountrySelector selectedCountry={contactValue?.country} setSelectedCountry={(country) => setContactValue({ ...contactValue, ['country']:country })}/> // <Input name={"country"} placeholder={"Country"} val={contactValue?.country}/>
    let phone = contactInfo?.phone && inputDisabled ? contactInfo?.phone : <Input name={"phone"} placeholder={"Phone"} val={contactValue?.phone}/>

    const handleEdit = async () => {
        if(inputDisabled){
            setInputDisabled(!inputDisabled);
        }else{
            const value = await UpdateContactInfo(contactValue)
            const encrypt = encryptData(contactValue);
            sessionStorage.setItem('contact',encrypt);
            setContactInfo(contactValue)
            setInputDisabled(!inputDisabled);
        }
    }

    return (
    <ContactDetails>
            <Row>
            <h4 style={{marginLeft: 5, marginRight: 5}}>Contact</h4>
            <div style={{cursor: 'pointer'}} onClick={handleEdit}>{inputDisabled ? <MdEditSquare size={17} color={theme.colors.text} /> : <MdDone size={17} color={theme.colors.text} />}</div>
            </Row>
            <Row justify={'flex-start'} justify_mobile={'flex-start'}><FaRegAddressCard size={20} />{address}</Row>
            <Row justify={'flex-start'} justify_mobile={'flex-start'}><MdLocationOn />{postcode}</Row>
            <Row justify={'flex-start'} justify_mobile={'flex-start'}><MdOutlineLocationCity />{city}</Row>
            <Row justify={'flex-start'} justify_mobile={'flex-start'}><FaEarthEurope />{country}</Row>
            <Row justify={'flex-start'} justify_mobile={'flex-start'}><MdOutlineSmartphone size={20}/>{phone}</Row>
    </ContactDetails>
    )
}

const SettingsContent = ({theme, lang}) => {
    
    return(
        <>
            <Row padding={'0'} justify={'flex-start'} justify_mobile={'flex-start'}>
                <p>{WordTranslations.language[lang]}: </p>
                <LanguageChanger />
            </Row>

            <Row padding={'0'} justify={'flex-start'} justify_mobile={'flex-start'}>
                <ChangePassword theme={theme} lang={lang}/>
            </Row>

        </>
    )
}

const Profile = ({theme}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {language} = useLanguage();
    const {geolocation, error} = Geolocation();
    const [profile, setProfile] = useState(null);
    const [contactInfo, setContactInfo] = useState(null);
    const [settings, setSettings] = useState({isStripe: undefined});
    // const [isStripe, setIsStripe] = useState(false);
    const [loading, setLoading] = useState(false);
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    useEffect(() => {
        setToProfile();
        setToContactInfo();
        setToSettings();
    },[])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const success = queryParams.get('success');
        // const id = queryParams.get('id');

        if(success === 'true' && profile?.role){
            toast.success("Payment account has been created!");
            updateStripe(profile?.role);
            // lisätään tiedot kantaan, ohjataan sen jälkeen uudelleen profiili sivulle.
            navigate(location.pathname, { replace: true });
        }else if(success === 'false'){
            toast.info("payment account may need additional information");
            navigate(location.pathname, { replace: true });
        }
      },[location.search, profile])

      useEffect(() => {
        if(profile){
            setToSessionStorage('profile', profile);
        }
      },[profile])


    const setToSettings = async () => {
        const settings = await GetSettings();

        setSettings(settings);
    }

    const getUser = async () => {
        const result = await GetdecodeToken();
        return result;
    }

    const setToProfile = async () => {
        const storedProfile = sessionStorage.getItem('profile');
        if (storedProfile) {
            const decrypt = decryptData(storedProfile);
            setProfile(JSON.parse(decrypt));
        } else {
        const user = await getUser();
            const newProfile = {
                firstname: user?.firstname,
                lastname: user?.lastname,
                email: user?.email,
                username: user?.username,
                role: user?.role,
                gendre: user?.profile?.gendre,
                birthday: user?.profile?.bday
            }
            setProfile(newProfile);
            // store crypted data to sessionStorage
            const encrypt = encryptData(newProfile);
            sessionStorage.setItem('profile',encrypt);
        }
    }

    const setToSessionStorage = (key, data) => {
        const encrypt = encryptData(data);
        sessionStorage.setItem(key,encrypt);
    }   

    const setToContactInfo= async () => {
        const storedContactInfo = sessionStorage.getItem('contact');
        const user = await getUser();
        if (storedContactInfo) {
            const decrypt = decryptData(storedContactInfo);
            setContactInfo(JSON.parse(decrypt));
        } else if(user?.address !== undefined) {
            const newObj = {
                address: user?.address?.address,
                postcode: user?.address?.postcode,
                city: user?.address?.city,
                country: user?.address?.country,
                phone: user?.address?.phone
            }

            setContactInfo(newObj);
            // store crypted data to sessionStorage
            const encrypt = encryptData(newObj);
            sessionStorage.setItem('contact',encrypt);
        }else{
            return;
        }
    }

    const activateStripe = async (country, bday) => {
        const checkAge = CheckAge(bday, 16);

        if(!checkAge){
            toast.info('You must be at least 16 years old to activate payment account.');
            return;
        }

        if(!country){
            toast.info('You must add your country of residence before you can activate a payment account');
            return;
        }

        setLoading(!loading)

        const response = await CreateStripeAccount(country);
        // setLoading(response?.loading);
        
    }

    const finalizeStripe = async () => {

        setLoading(!loading)

        const response = await StripeAccountLink();
        // setLoading(response?.loading);
    }

    const updateStripe = async (currentRole) => {
        setLoading(true)

        const role = await UpdateStripeAccount();

         if(role !== currentRole){
            setProfile(prevProfile => ({
                ...prevProfile,   // Kopioi aiemman tilan tiedot
                role: role        // Päivitä rooli
            }));

         }

        setToSettings();
        setLoading(false)
    }
    
    return(
        <>
        {!loading ?
            <ProfileContainer>
                <ProfileContent>
                    <RoundImgContainer src={Logo} />
                        <UserDetails profile={profile && (profile)} setProfile={setProfile} theme={theme}/>
                </ProfileContent>
                <ProfileContent>
                    <RoundImgContainer size={'3em'} top={'-2em'} left={'-2em'}>
                        <MdImportContacts />
                    </RoundImgContainer>
                    <ContactInfo contactInfo={contactInfo && (contactInfo)} setContactInfo={setContactInfo} theme={theme}/>
                </ProfileContent>
                <ProfileContent>
                    <RoundImgContainer size={'3em'} top={'-2em'} left={'-2em'}>
                        <MdOutlineSettings />
                    </RoundImgContainer>
                    {profile?.role === 'admin' && (
                    <Row padding={'0'} justify={'flex-start'} justify_mobile={'flex-start'}>
                        {settings?.isStripe !== undefined ?
                            <>
                                {settings?.isStripe === 'onboarding' ?
                                    <><span style={{marginRight: 5, width: 8, height: 8, borderRadius: '50px', background: 'green'}}></span><p>Payment account active</p></>
                                    :
                                    <><span style={{marginRight: 5, width: 8, height: 8, borderRadius: '50px', background: 'yellow'}}></span><p>Finalize a payment account</p><MdArrowForward onClick={() => finalizeStripe()}/></>
                                }
                            </>
                            :
                            <><span style={{marginRight: 5, width: 8, height: 8, borderRadius: '50px', background: 'red'}}></span><p>Activate a payment account</p><MdArrowForward onClick={() => activateStripe(contactInfo?.country, profile?.birthday)}/></>
                        }
                    </Row>
                    )}
                    <SettingsContent theme={theme} lang={lang}/>
                </ProfileContent>
            </ProfileContainer>
            :
            <ClipLoader
                color={theme.colors.button}
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        }
        </>
    )
}

export default Profile;