import React, {useState} from "react";
import { useLocation, useNavigate} from 'react-router-dom';
//Utils
import { useAuth, useLanguage } from "../../../Utils/UseContext.jsx";
import { returnCurrency} from "../../../Utils/Utils.jsx";
//COmponents
import { WorkoutMedia } from "../WorkoutMedia.jsx";
//Style
import { Row } from "../../../components/style/Row.styled.jsx";
import { WorkoutRow, ExerciseContainer, WorkoutBox} from '../StyledComponents.jsx'
import { NowrapH4 } from "../../../components/style/paragraph.styled.jsx";
//translate
import WordTranslations from '../../../Main/Translations/WordTranslation.json'
import PublicTranslations from '../../../Main/Translations/Public.json'



const PublicExerciseRow = ({theme,lang, nameofcreator, partnames, title, price, expiredIn, media, currency, onClick, type}) => {
  const testmode = true;

    let typeString = '';
    switch (type) {
      case 'training_plan':
        typeString = `${WordTranslations.training_plan[lang]}`;
        break;
      case 'training_program':
        typeString = `${WordTranslations.training_program[lang]}`;
        break;
      default:
        typeString = `${WordTranslations.training_program[lang]}`;
        break;
    }
  
     return(
       <WorkoutRow columns={'1fr 8fr'} onClick={onClick} >
        <WorkoutBox>
            <WorkoutMedia src={media} type="image"/>
        </WorkoutBox>
        {/* <WorkoutBox column={'2 / 2'} row={'1 / 3'}> */}
          <NowrapH4>{title}</NowrapH4>
        {/* </WorkoutBox> */}
         <WorkoutBox column={'2 / 2'} row={'2 / 3'}> 
              <p style={{fontSize: 10}}>{partnames?.length} {typeString}</p>
              <Row padding={'0px 30px 0px 0px'}>
                <p>{WordTranslations.expires[lang]} {expiredIn} {WordTranslations.day.ss[lang].toLowerCase()}</p>
                <div style={{padding: '2px 10px 2px 10px', borderRadius: 50, border: `solid 1px ${theme.colors.border}`}}>
                  <p style={{fontSize:25}}>{price}{returnCurrency(currency)}</p>
                </div>
              </Row>
         </WorkoutBox>
        <WorkoutBox column={'1 / 3'} row={'3 / 3'} font={10}>
          <p>{PublicTranslations.createdBy[lang]} {nameofcreator}</p>
       </WorkoutBox>
     </WorkoutRow>
     )
   }
  
export const PublicExerciseSection = ({data, theme, activeData}) =>{
      const navigate = useNavigate();
      const {language} = useLanguage();

      let lang = language;
      // if language is not supported change to default (en-EN)
      lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

      const publicExercise = Object.values(data).map(item => {
        const workout_name = item?.workout_name;
        const username = item?.username;
        const workout_id = item?.workout_id;
        const description = item?.description;
        const expired_time = item?.expired_time;
        const price = item?.price;
        const media = item?.media_url;
        const display_parts = item?.display_parts;
        const part_names = item?.part_names;
        const currency = item?.currency;
        const type = item?.type;

        return (
          <PublicExerciseRow key={Math.random()} 
            onClick={() => navigate(`/workout/public/${workout_id}`)} 
            theme={theme} 
            lang={lang}
            id = {workout_id} 
            nameofcreator = {username} 
            title = {workout_name} 
            partnames={part_names} 
            price = {price} 
            expiredIn = {expired_time} 
            description = {description} 
            media={media} 
            currency={currency} 
            display_parts={display_parts}
            type={type}/>
        )
      })

  
      return (
        <ExerciseContainer justify={'start'} direction={'column'}>
          {publicExercise}
        </ExerciseContainer>
        );
   }