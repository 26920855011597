import styled from "styled-components";

export const Layout = styled.div`
  display: grid;
  grid-template-areas: 
    "header header"
    "sidenav content"
    "footer footer";
  grid-template-columns: ${({ isOpen }) => (isOpen ? "150px auto" : "0 auto")};
  grid-template-rows: 1fr;
  height: 100%;
  max-height: 100vh;

  @media screen and (min-width: 600px) {
    grid-template-columns: auto 1fr; /* Navigaatio aina näkyvissä desktop-näkymässä */
    grid-template-rows: 1fr;

  }
`;

export const HeaderStyle = styled.header`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  height: 55px;
`;

export const FooterStyle = styled.footer`
  grid-area: footer;
  //background: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
`;

export const NavContainer = styled.nav`
  grid-area: sidenav;
  position: fixed; /* Navigaatio pysyy paikallaan */
  top: 0;
  left: 0;
  width: ${({ isOpen }) => isOpen ? '100%' : '0px'};
  background: ${({ theme }) => theme.colors.body};
  overflow: hidden;
  transition: width 0.3s ease;
  z-index: 1000;
  min-height: 100vh;

  @media screen and (min-width: 600px) {
    position: static;
    width: 150px;
    border-right: 1px solid ${({ theme }) => theme.colors.border};
    min-height: calc(100vh - 55px);
  }
`;

export const Content = styled.main.attrs(() => ({ id: "content" }))`
  grid-area: content;
  position: relative;
  min-height: 0; /* Estää sisällön ylivuodon */
  min-width: 0; /* Estää sisällön ylivuodon */
  overflow-y: auto; /* Sisältö skrollattavaksi tarvittaessa */
  height: calc(100vh - 55px);
  padding: 1rem;
`;
