import React, { useState, useEffect, useCallback } from 'react';
import { FadeInText, TextWrapper } from './style/Container.styled';

const MultiTextSection = ({ texts, theme }) => {
  const [opacityLevels, setOpacityLevels] = useState([]);

   useEffect(() => {
    const contentElement = document.getElementById("content"); // Lisää ID Content-komponenttiin
     const handleScroll = () => {
       const centerOfViewport = window.innerHeight / 2;

      
       const newOpacityLevels = texts.map((_, index) => {
         const element = document.getElementById(`text-${index}`);
         const elementRect = element.getBoundingClientRect();
         const elementCenter = elementRect.top + elementRect.height / 2;

         const distanceFromCenter = Math.abs(centerOfViewport - elementCenter);
         const maxDistance = window.innerHeight / 2;

         // Läpinäkyvyyden määritys
         return Math.max(0.2, 1 - distanceFromCenter / maxDistance)
         // Math.max(0.2, 1 - distanceFromCenter / maxDistance);
       });

       setOpacityLevels(newOpacityLevels);
     };

     if(contentElement){
     contentElement.addEventListener('scroll', handleScroll);

     handleScroll(); // Alustetaan läpinäkyvyys
     }

     return () => contentElement.removeEventListener('scroll', handleScroll);
   }, [texts]);
  
  return (
    <TextWrapper>
      {texts.map((text, index) => (
        <FadeInText key={index} id={`text-${index}`} opacity={opacityLevels[index]}>
          {text}
        </FadeInText>
      ))}
    </TextWrapper>
  );
};

export default MultiTextSection;
