import React, {useEffect, useState} from "react";
// Style
import {Container} from "../../../components/style/Container.styled.jsx";
import { CustomButtonGroup } from "../../../components/Buttons.jsx";
import { StyledBorderSquareButton, TransparentButton } from "../../../components/style/Buttons.styled.jsx";
import { Column } from "../../../components/style/Row.styled.jsx";
//Utils
import { HasRole } from "../../../Utils/Role.jsx";

// icon
import { GoVideo } from "react-icons/go";
import { ReactComponent as TrainingPlan } from "../../../components/style/images/training_plan.svg"
import { ReactComponent as TrainingProgram } from "../../../components/style/images/training_program.svg"
import { ReactComponent as Exercise } from "../../../components/style/images/exercise.svg"
import { toast } from "react-toastify";
//Translations
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'

const iconStyle = {
    width: '20px',
    height: '20px'
}

const customButtonStyle = {
    width: '200px',
    display: 'flex',
    justifyContent: 'flex-start',
    labelStyle: { // Labelin omat tyylit
        marginLeft: '10px',
    },
    iconStyle: {
        display: 'flex',
        alignItems: 'center'
    }
}

const NewWorkoutSelection = ({setCreationMode, language}) => {
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN'];

    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        const checkRole = async () => {
            const isRole = await HasRole('advanced');
            setFilteredOptions(isRole ? options : options.filter(option => option.value === 'workout'));
        };
    
        checkRole();
    }, []);

    const handleChange = (selectedValue) => {
        //pidä tieto ylhäällä.
        if(selectedValue === 'video'){
            toast.info("Upcoming feature");
        }else{
            setCreationMode(selectedValue)

        }
      };

    const options = [ //Button (styled-component)*, label, value, onClick, disabled, icon
        //{ button: TransparentButton, icon: <TrainingPlan style={iconStyle}/>, label: `${NewWorkoutTranslations.training_plan[lang]}`, value: 'training_plan', onClick: () => handleChange('training_plan'), style:customButtonStyle},
        //{ button: TransparentButton, icon: <TrainingProgram style={iconStyle} />, label: `${NewWorkoutTranslations.training_program[lang]}`, value: 'workout', onClick:() => handleChange('workout'), style:customButtonStyle },
        { button: TransparentButton, icon: <Exercise style={iconStyle}/>, label: `${NewWorkoutTranslations.exercise[lang]}`, value: 'exercise', onClick: () => handleChange('exercise'), style:customButtonStyle },
        //{ button: TransparentButton, icon: <GoVideo size={20}/>, label: 'Video', value: 'video', onClick: () => handleChange('video'), style:customButtonStyle }
      ];

    return(
        <Container>
            <Column height={'70vh'} justify={'space-around'}>
                <CustomButtonGroup
                    options={filteredOptions}
                />
            </Column>
        </Container>
    )
}

export default NewWorkoutSelection;