import React, {createContext, useContext, useReducer, useState, useEffect} from 'react';
import {jwtDecode} from 'jwt-decode';
import { GetCookieData} from './Utils';
import { fetchPersonalData, refreshAccessToken, CheckServer, fetchExercisesData, GetPersonalTrainingPlan } from './ApiRequests';
import { encryptData, decryptData } from './encryption';
import { useNavigate } from 'react-router-dom';


const ws_host = process.env.REACT_APP_WS_API_KEY;

let initialState = {
    isAuthenticated: false,
    token: null,
    user: null
  };

 let initialDataState = {
  data: null
 }

 let initialPublicDataState = {

 }

 let initialNotifications = {
    notifications: null
 }
  
  // Reducer-funktio käsittelemään tilan muutoksia
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          isAuthenticated: true,
          token: action.payload,
          user: action.user
        };
      case 'LOGOUT':
        return {
          ...state,
          isAuthenticated: false,
          token: null,
          user: null,
        };
      default:
        return state;
    }
  };

   const dataReducer = (state, action) => {
     switch (action.type) {
       case 'LOAD':
         return {
           ...state,
           data: action.payload
         };
        case 'ADD_PART_TO_WORKOUT':
          return {
              ...state,
              data: state.data.map(workout =>
                  workout.workout_id === action.payload.workout_id
                      ? { ...workout, parts: action.payload.parts }
                      : workout
              )
          };
        case 'NOTIFICATION':
          return {
            ...state,
            notifications: action.payload
          }
        case 'LOADVIDEO':
          return {
            ...state,
            videos: action.payload
          }
        case 'LOADTRAININGPLAN':
            return {
              ...state,
              trainingplan: action.payload
          }
        case 'UPDATE_TRAININGPLAN_FIELD':
            return {
                ...state,
                trainingplan: state.trainingplan.map(plan =>
                    plan.workout_id === action.payload.workout_id
                        ? { ...plan, ...action.payload.updates } // Yhdistetään päivitykset
                        : plan
                ),
            };
       default:
         return state;
     }
   };

export const AuthContext = createContext();
export const DataContext = createContext();
export const TimerContext = createContext();
export const WebSocketContext = createContext(null);
export const LanguageContext = createContext();
export const ConnectionContext = createContext({
  isConnected: true, // Oletuksena yhteys on kunnossa
});


  const AuthProvider = ({ children }) => {
    initialState = GetCookieData() ? GetCookieData() : initialState;
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
      if(GetCookieData() === null){
        dispatch({type: 'LOGOUT'})
      }
    },[GetCookieData()])
    
    return (
      <AuthContext.Provider value={{ state, dispatch }}>
        {children}
      </AuthContext.Provider>
    );
  };

  const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
        const sessionLanguage = sessionStorage.getItem("language");
        return sessionLanguage ? sessionLanguage : navigator.language;
    });

    useEffect(() => {
        // Päivitä sessionStorage aina kun kieli muuttuu
        sessionStorage.setItem("language", language);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

  const DataProvider = ({ children }) => {
    const navigate = useNavigate();
    const {state, dispatch} = useAuth();
    let initialNotifications = null;
    let initialVideoState = null;
    let initialExercisesState = null;
    let initialTrainingPlanState = null;
    const getNotification = localStorage.getItem('notifications');

    if(getNotification){
      const decryptNotification = decryptData(getNotification);
      initialNotifications = JSON.parse(decryptNotification);
    }

    const [personalData, setPersonalData] = useReducer(dataReducer, initialDataState);
    const [exercisesData, setExercisesData] = useReducer(dataReducer, initialExercisesState);
    const [personalVideo, setPersonalVideo] = useReducer(dataReducer, initialVideoState);
    const [personalTrainingPlan, setPersonalTrainingPlan] = useReducer(dataReducer, initialTrainingPlanState);
    const [publicData, setPublicData] = useReducer(dataReducer, initialPublicDataState);
    const [notifications, setNotifications] = useReducer(dataReducer,JSON.parse(initialNotifications));

     useEffect(() => {
      if (notifications) {
        const encryptNotification = encryptData(JSON.stringify(notifications)); 
        localStorage.setItem('notifications', encryptNotification);
      }
    }, [notifications]);

    useEffect(() => {
      if(state?.isAuthenticated){

        fetchPersonalData(setPersonalData);
        fetchExercisesData(setExercisesData);
        GetPersonalTrainingPlan(setPersonalTrainingPlan)
        // GetPersonalVideo(setPersonalVideo);
      }
    }, []);



     return (
       <DataContext.Provider value={{ publicData, personalData, exercisesData, personalVideo, personalTrainingPlan, notifications, setPublicData, setPersonalData, setExercisesData, setPersonalVideo, setPersonalTrainingPlan, setNotifications }}>
         {children}
       </DataContext.Provider>
     );
  };

  const TimerProvider = ({ children }) => {
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    
    return (
      <TimerContext.Provider value={{ isTimerRunning, setIsTimerRunning }}>
        {children}
      </TimerContext.Provider>
    );
  };

  const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const {state} = useAuth();


    const createWebSocketConnection = async () => {
      const wsConnection = new WebSocket(ws_host);
  
      // Luo WebSocket-yhteys
      wsConnection.onopen = async () => {
        console.info("WebSocket connection opened");

        let token = state?.token;
  
        // Tarkista, onko token olemassa ja onko se voimassa
        if (token) {
          const decoded = jwtDecode(token);
  
          if (decoded && decoded.exp * 1000 <= Date.now()) {
  
            // Token on vanhentunut, yritetään uusia se
            await refreshAccessToken();
            const getCookie = GetCookieData();
  
            token = getCookie?.token; // Hae uusi token tallennuksesta
  
          }
        }
  
        // Lähetä käyttäjätunnus, jos token on saatavilla
        if (token) {
          wsConnection.send(JSON.stringify({ type: 'SET_USER_ID', token }));
        }
        
        // Lähetä REQUEST_PUBLIC_DATA -viesti aina, riippumatta tokenista
        wsConnection.send(JSON.stringify({ type: 'REQUEST_PUBLIC_DATA' }));
  
        // Lähetä REQUEST_NOTIFICATION -viesti vain, jos token on saatavilla
        if (token) {
          wsConnection.send(JSON.stringify({ type: 'REQUEST_NOTIFICATION', token }));
        }
      };
  
      wsConnection.onclose = () => {
        console.info("WebSocket connection closed");
      };
  
      setWs(wsConnection);
    };
  
    useEffect(() => {
      createWebSocketConnection();
  
      return () => {
        if (ws) {
          ws.close();
        }
      };
    }, [state.token]);
  
    return (
      <WebSocketContext.Provider value={ws}>
        {children}
      </WebSocketContext.Provider>
    );
  }

 const ConnectionProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
  
    useEffect(() => {
      const checkConnection = async () => {
        try {
          // Korvaa tämä palvelimesi terveyspisteellä
          const isUp = await CheckServer();

          setIsConnected(isUp);
        } catch (error) {
          setIsConnected(false);
        }
      };
  
      checkConnection();
      const interval = setInterval(checkConnection, 5000); // Tarkista 5 sekunnin välein
  
      return () => clearInterval(interval); // Siivoa interval unmountissa
    }, []);
  
    return (
      <ConnectionContext.Provider value={{ isConnected }}>
        {children}
      </ConnectionContext.Provider>
    );
  };

  const useAuth = () => {
    const context = useContext(AuthContext);
    
    if (!context) {
      throw new Error('useAuth hook voidaan käyttää vain AuthProviderin sisällä');
    }
    return context;
  };

  const useLanguage = () => {
    const context = useContext(LanguageContext);
    
    if (!context) {
      throw new Error('useLanguage hook voidaan käyttää vain LanguageProviderin sisällä');
    }
    return context;
  }

  const useData = () => {
     const context = useContext(DataContext);

     if (!context) {
       throw new Error('useData hook voidaan käyttää vain DataProviderin sisällä');
     }
     return context;
  };

  const useTimer = () => {
    const context = useContext(TimerContext);
    if(!context){
      throw new Error('useTimer hook voidaan käyttää vain TimerProviderin sisällä')
    }
    return context;
  }

  const useWebSocket = () => {
    return useContext(WebSocketContext);
  };

  const useConnection = () => {
    const context = useContext(ConnectionProvider);
    
    if (!context) {
      throw new Error('useAuth hook voidaan käyttää vain connectionProvider sisällä');
    }
    return context;
  }


  export {AuthProvider, useAuth, useLanguage, DataProvider, WebSocketProvider,LanguageProvider,ConnectionProvider, useData, TimerProvider, useTimer, useWebSocket, useConnection}