import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 1rem;
  max-width: 64rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Card = styled.div`
  flex: 1;
  background: ${({theme}) => theme.colors.body};
  box-shadow: 0 4px 10px ${({theme}) => theme.colors.shadow};
  border-radius: 12px;
  height: 90%;
  max-width: 400px;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  margin-top: 4rem;
  margin-left: 1rem;
  padding: 1rem;
  // padding-bottom: 1rem;
  overflow: auto;

  
  @media screen and (max-width: 940px) { // mobile
    width: 100%;
    max-width: unset;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    left: 0;
    top: 0;
    padding: 1rem;
    height: calc(100vh - 135px);
    overflow-y: auto;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const ExerciseImage = styled.img`
  width: 16rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 0.5rem;
`;

export const InfoContainer = styled.div`
  margin-top: 1rem;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 0;
    margin-left: 1.5rem;
    text-align: left;
  }
`;

export const ExerciseName = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const RepsInfo = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const RepsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({direction}) => direction || 'row'};
  max-height: 200px;
  max-width: 100%;
  gap: 10px;
  margin: ${({margin}) => margin || '10px 0'};
  padding: ${({padding}) => padding || '10px 10px 10px 0px'};
  overflow: auto;
`;

export const RepBox = styled.div`
  width: 4rem;
  height: 4rem;
  background: #f3f4f6;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
`;

export const RightSection = styled.div`
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 0.5rem;
`;

export const SectionTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const Description = styled.p`
  color: #4b5563;
`;